import {
	DownloadOutlined,
	HomeOutlined,
	RedoOutlined,
	SearchOutlined,
	PlusOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Modal, Form, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import DatatableMerchantProduct from "../../Datatable/Dt-merchantProduct";
import {
	fetchSysGeneralData,
	fetchProductList,
	deleteProduct,
} from "../../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import MerchantProductFormModal from "../../Modals/MerchantProductFormModal";

const breadcrumbItem = [
	{
		label: "",
		link: "/admin/dashboard",
		icon: <HomeOutlined />,
	},
	{
		label: "Merchant",
		icon: <FontAwesomeIcon icon="fa-solid fa-shop" />,
	},
	{
		label: "Merchant Service listing",
		link: "/admin/merchant-service-list",
	},
];

const MerchantProductList = () => {
	const [form] = Form.useForm();
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const [statusSource, setStatusSource] = useState([]);
	const [dataSource, setDataSource] = useState([]);
	const [formValue, setFormValue] = useState({});
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 50,
		total: 0,
	});
	const [loading, setLoading] = useState(false);
	const [sorting, setSorting] = useState({
		sortField: "id",
		sortOrder: "DESC",
	});
	const [merchantProductModalVisible, setMerchantProductModalVisible] =
		useState(false);
	let values = form.getFieldsValue();

	const searchField = [
		{
			input: (
				<TextField name="search_shop_name" placeholder="Shop Name" />
			),
		},
		{
			input: <TextField name="search_name" placeholder="Service Name" />,
		},
		{
			input: (
				<SelectField
					placeholder="Status"
					name="search_status"
					options={statusSource}
				/>
			),
		},
	];

	const searchFieldButton = [
		{
			button: (
				<Button
					type="secondary"
					icon={<PlusOutlined />}
					style={{ width: "100%" }}
					onClick={() => {
						showMerchantProductFormModal();
					}}
				>
					Add New
				</Button>
			),
		},
		{
			button: (
				<Button
					type="primary"
					htmlType="submit"
					icon={<SearchOutlined />}
					style={{ width: "100%" }}
				>
					Search
				</Button>
			),
		},
		{
			button: (
				<Button
					type="danger"
					style={{ width: "100%" }}
					icon={<RedoOutlined />}
					onClick={() => {
						form.resetFields();
						fetchProductListData(values);
					}}
				>
					Reset
				</Button>
			),
		},
		{
			button: (
				<Button
					type="dashed"
					icon={<DownloadOutlined />}
					style={{ width: "100%" }}
				>
					Export
				</Button>
			),
		},
	];

	useEffect(() => {
		fetchProductListData(values);
		fetchGeneralData();
	}, [pagination.current]);

	const fetchGeneralData = async () => {
		const param = {};
		param["type"] = "general_status";
		param["status"] = "A";

		const response = await fetchSysGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));

			setStatusSource(transformed);
		}
	};

	const fetchProductListData = async (values) => {
		const param = {};
		param["page"] = pagination.current;
		param["page_length"] = pagination.pageSize;
		param["company_name"] = values.search_shop_name;
		param["status"] = values.search_status;
		param["name"] = values.search_name;

		setLoading(true);
		const response = await fetchProductList({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			setDataSource(response.page_items);
			setPagination({
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: response.total_items,
			});
		}
	};

	const onFinish = (values) => {
		fetchProductListData(values);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		let order = "ASC";

		if (sorter.order === "descend") {
			order = "DESC";
		}

		setSorting({
			sortField: sorter.column ? sorter.field : "id",
			sortOrder: sorter.order ? order : "DESC",
		});

		setPagination({
			current: pagination.current,
			pageSize: pagination.pageSize,
			total: pagination.total,
		});
	};

	const onMerchantProductModalCancel = () => {
		setMerchantProductModalVisible(false);
	};

	const showMerchantProductFormModal = (record) => {
		setMerchantProductModalVisible(true);
		if (record) {
			setFormValue(record);
		} else {
			setFormValue({});
		}
	};

	const handleRemoveRecord = (record) => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to remove ${record.name}?`,
			okText: "Yes",
			cancelText: "No",

			onOk() {
				postDeleteProductData(record.id);
			},
		});
	};

	const postDeleteProductData = async (id) => {
		const param = {};
		param["id"] = id;

		setLoading(true);
		const response = await deleteProduct({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			if (response.code === 200) {
				message
					.success(response.message, 1)
					.then(fetchProductListData(values));
			}
		}
	};

	return (
		<>
			<BreadcrumbLayout items={breadcrumbItem} />
			<Divider />
			<Form
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinish}
			>
				<AdvSearchComponent
					field={searchField}
					buttonField={searchFieldButton}
				/>
			</Form>
			<Divider dashed />
			<DatatableMerchantProduct
				dataSource={dataSource}
				pagination={pagination}
				loading={loading}
				handleTableChange={handleTableChange}
				showMerchantProductFormModal={showMerchantProductFormModal}
				handleRemoveRecord={handleRemoveRecord}
			/>
			<MerchantProductFormModal
				visible={merchantProductModalVisible}
				onCancel={onMerchantProductModalCancel}
				fetchProductListData={fetchProductListData}
				formValue={formValue}
				destroyOnClose={true}
			/>
		</>
	);
};
export default MerchantProductList;
