import React from "react";
import { Form, Input } from "antd";

export default function TextAreaField(props) {
	const { TextArea } = Input;

	return (
		<Form.Item
			name={props.name}
			label={props.label}
			dependencies={props.dependencies}
			rules={props.rules}
			hasFeedback={props.hasFeedback}
			extra={props.extra}
			style={props.style}
		>
			<TextArea rows={props.rows} placeholder={props.placeholder} />
		</Form.Item>
	);
}
