import React from "react";
import routes from "./routes";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MainRoutes = () => {
  const temp = useSelector((state) => ({
    isLoggedIn: state.AdminLoginReducer.isLoggedIn,
  }));
  console.log("main routes", temp);
  return (
    <Routes>
      {routes.map((route, i) => {
        if (
          (route.auth && temp.isLoggedIn) ||
          (!route.auth && !temp.isLoggedIn)
        ) {
          return (
            <Route
              key={i}
              {...route}
              path={route.path}
              element={route.element}
              exact={route.exact}
            />
          );
        }
      })}
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
    </Routes>
  );
};

export default MainRoutes;
