import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  HomeOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Button,
  Popover,
  Image,
  Radio,
  message,
} from "antd";
import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import BreadcrumbLayout from "../../../layouts/Admin/breadcrumb";
import SelectField from "../Global/FormInputComponent/SelectField";
import SelectGroupField from "../Global/FormInputComponent/SelectGroupField";
import TextField from "../Global/FormInputComponent/TextField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import UploadField from "../Global/FormInputComponent/UploadField";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import {
  fetchAllBookingMasterData,
  createMerchantData,
  fetchMainMerchantCategory,
  fetchTerritoryData,
  fetchMerchantDetail,
  updateMerchantDetail,
} from "../../../actions/Admin/actionCreators";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
  validator: "Duplicate Data",
};

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const MerchantForm = () => {
  const [form] = Form.useForm();
  const [createMerchant, setCreateMerchant] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOptionsData, setCategoryOptionsData] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState({});
  const [selectedBookingMasterCode, setSelectedBookingMasterCode] =
    useState("");
  const [addrId, setAddrId] = useState();
  const [companyId, setCompanyId] = useState();
  const [bookingMasterOptions, setBookingMasterOptions] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [switchStatus, setSwitchStatus] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const initialValues = [{}];
  const [importData, setImportData] = useState({
    dataList: [],
  });
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();

  const breadcrumbItem = [
    {
      icon: <HomeOutlined />,
      label: "",
      link: "/dashboard",
    },
    {
      label: "Merchant Listing",
      link: "/merchant-list",
    },
    {
      icon: <UserAddOutlined />,
      label: createMerchant ? "Merchant New Form" : "Merchant Update Form",
      link: "",
    },
  ];

  const createDataTree = (dataset) => {
    const hashTable = Object.create(null);
    dataset.forEach(
      (aData) => (hashTable[aData.name] = { ...aData, childNodes: [] })
    );
    const dataTree = [];
    dataset.forEach((aData) => {
      if (aData.parent_name)
        hashTable[aData.parent_name].childNodes.push(hashTable[aData.name]);
      else dataTree.push(hashTable[aData.name]);
    });

    return dataTree;
  };

  useEffect(() => {
    if (id) {
      setCreateMerchant(false);
      fetchMerchantData();
    }
    fetchCategoryData();
    fetchCountryData();
  }, []);

  const fetchCategoryData = async () => {
    var param = {};
    const response = await fetchMainMerchantCategory({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      setCategoryOptionsData(response);
      var treeData = createDataTree(response);
      setCategoryOptions(treeData);
    }
  };

  useEffect(() => {
    categoryOptionsData.forEach((aData) => {
      if (aData?.code == selectedCategoryValue) {
        form.setFieldValue("merchant_category_id", aData?.id);
      }
    });
  }, [categoryOptionsData]);

  const fetchCountryData = async () => {
    var param = {};
    param["territory_type"] = "country";

    const response = await fetchTerritoryData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const country_transformed = response.map(({ id, name, image_path }) => ({
        value: id,
        desc: (
          <div>
            <Image width={25} src={image_path} /> {name}
          </div>
        ),
      }));

      const prefix_transformed = response.map(
        ({ image_path, calling_no_prefix }) => ({
          value: calling_no_prefix,
          desc: (
            <div>
              <Image width={25} src={image_path} /> {calling_no_prefix}
            </div>
          ),
        })
      );
      setCountryData(response);
      setCountryOptions(country_transformed);
      setPrefixOptions(prefix_transformed);
    }
  };

  const fetchStateData = async (country_id) => {
    var param = {};
    param["territory_type"] = "state";
    param["parent_id"] = country_id;

    const response = await fetchTerritoryData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ id, name }) => ({
        value: id,
        desc: name,
      }));

      setStateOptions(transformed);
    }
  };

  const fetchCityData = async (state_id) => {
    var param = {};
    param["territory_type"] = "city";
    param["parent_id"] = state_id;

    const response = await fetchTerritoryData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ id, name }) => ({
        value: id,
        desc: name,
      }));

      setCityOptions(transformed);
    }
  };

  const fetchBookingMasterData = async (value) => {
    var param = {};
    param["merchant_category_code"] = value;

    const response = await fetchAllBookingMasterData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      setBookingMasterOptions(response);
      // bookingMasterButtons();
    }
  };

  const fetchMerchantData = async () => {
    var param = {};
    param["id"] = id;

    const response = await fetchMerchantDetail({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      setSelectedCategoryValue(response.merchant_category_code);
      //use category code or id???
      // booking steps api hit error

      fetchBookingMasterData(response.merchant_category_code);

      Object.keys(form.getFieldsValue()).forEach((key) => {
        const obj = {};
        obj[key] = response[key] || null;
        // console.log(obj);
        if (key == "mobile_number") {
          form.setFieldsValue({
            mobile_number: response["mobile_prefix"] + response["mobile_no"],
          });
        } else {
          form.setFieldsValue(obj);
        }
      });
      // var selected_option = stateOptions.filter(
      // 	(val) => val.value === response.state_id
      // );
      setAddrId(response.addr_id);
      setCompanyId(response.company_id);
      setSelectedBookingMasterCode(response.booking_master_code);
      // form.setFieldsValue({
      // 	booking_master_id: "normal_step",
      // });
    }
  };

  const postNewMerchantData = async (param) => {
    const response = await createMerchantData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      if (response.code === 200) {
        message
          .success(response.message, 5)
          .then(navigate("/admin/merchant-list"));
      }
    }
  };

  const postUpdateMerchantData = async (param) => {
    const response = await updateMerchantDetail({
      id: id,
      api,
      data: param,
      dispatch,
    });

    if (response) {
      if (response.code === 200) {
        message
          .success(response.message, 5)
          .then(navigate("/admin/merchant-list"));
      }
    }
  };

  const handlePrefixChange = (value) => {
    var loginID =
      form.getFieldValue("mobile_prefix") + form.getFieldValue("mobile_no");

    form.setFieldsValue({
      mobile_number: loginID,
    });

    var filtered_country = countryData.filter((item) => {
      return item.calling_no_prefix === value;
    });

    form.setFieldsValue({
      country_id: filtered_country[0]["id"],
    });

    fetchStateData(filtered_country[0]["id"]);
  };

  const handleMobileChange = (e) => {
    var loginID =
      form.getFieldValue("mobile_prefix") + form.getFieldValue("mobile_no");

    form.setFieldsValue({
      mobile_number: loginID,
    });
  };

  const handleCategoryChange = (value) => {
    fetchBookingMasterData(value);
  };

  const switchOnChange = (checked) => {
    setSwitchStatus(checked);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = async (values) => {
    const formData = new FormData();

    Object.entries(values).map(([key, value]) => {
      if (value) {
        formData.append(key, value ?? "");
      }
    });

    if (importData.dataList.length > 0) {
      importData.dataList.forEach((file) => {
        formData.append("logo", file);
      });
    }

    if (switchStatus) {
      formData.append("status", "A");
    } else {
      formData.append("status", "I");
    }

    if (createMerchant) {
      postNewMerchantData(formData);
    } else {
      formData.append("addr_id", addrId);
      formData.append("company_id", companyId);
      formData.append("_method", "PUT");
      postUpdateMerchantData(formData);
    }
  };

  const bookingMasterButtons = () => {
    let step_content = "";
    return (
      <Radio.Group buttonStyle="solid">
        {bookingMasterOptions.map((objects, key) => {
          step_content = (
            <ul key={objects?.id + key}>
              {objects.steps.map((element) => (
                <li key={element.booking_entity_id}>
                  {element.booking_entity_name}
                </li>
              ))}
            </ul>
          );
          if (objects.code == selectedBookingMasterCode) {
            form.setFieldValue("booking_master_id", objects.id);
          }

          return (
            <Popover
              style={{
                width: 500,
              }}
              content={step_content}
              title={objects.name}
              trigger="hover"
              key={key}
            >
              <Radio.Button value={objects.id} key={objects.id}>
                {objects.name}
              </Radio.Button>
            </Popover>
          );
        })}
      </Radio.Group>
    );
  };

  return (
    <>
      <BreadcrumbLayout items={breadcrumbItem} />
      <Divider />
      <Form
        {...layout}
        form={form}
        name="merchant_form"
        onFinish={onFinish}
        validateMessages={validateMessages}
        scrollToFirstError
        initialValues={initialValues}
        validateTrigger="onBlur"
      >
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col span={12}>
            <Card title="Company Info" className="h-100">
              <div style={{ textAlign: "center" }}>
                <UploadField
                  setImportData={setImportData}
                  importData={importData}
                />
              </div>
              <TextField
                label="Code"
                name="shop_code"
                placeholder="Example: M0001"
                rules={[{ required: true, whitespace: true }]}
                hasFeedback
              />
              <TextField
                label="Shop Name"
                name="shop_name"
                placeholder="Example: PP Mart"
                rules={[{ required: true, whitespace: true }]}
                hasFeedback
              />
              <TextField
                label="Display Shop Name"
                name="display_shop_name"
                placeholder="Example: PPM"
                rules={[{ required: true, whitespace: true }]}
                hasFeedback
              />
              <SelectGroupField
                label="Category"
                name="merchant_category_id"
                placeholder="Category"
                options={categoryOptions}
                type="parent"
                onChange={handleCategoryChange}
                rules={[{ type: "", required: true }]}
              />
              <TextField
                label="Booking Link"
                name="booking_url"
                addonBefore="https://www.smartbooking.com/"
                placeholder="Example: ppmart"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              />
              <Form.Item
                label="Booking Steps"
                name="booking_master_id"
                rules={[{ type: "", required: true }]}
              >
                {bookingMasterButtons()}
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <SwitchField
                    label="Status"
                    name="status"
                    switchStatus={switchStatus}
                    switchChange={switchOnChange}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Contact Info">
              <TextField
                label="Email"
                name="email"
                placeholder="Example: hello@ppmart.com.my"
                type="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    email: true,
                  },
                ]}
                hasFeedback
              />
              <div className="ant-col ant-col-24 ant-form-item-label">
                <label className="ant-form-item-required" title="Mobile No.">
                  Mobile No.
                </label>
              </div>
              <Input.Group compact>
                <SelectField
                  name="mobile_prefix"
                  placeholder="Mobile Prefix"
                  options={prefixOptions}
                  style={{ width: "30%" }}
                  onChange={handlePrefixChange}
                />
                <TextField
                  name="mobile_no"
                  placeholder="Example: 123456789"
                  onChange={handleMobileChange}
                  rules={[{ whitespace: true }]}
                  hasFeedback
                  style={{ width: "70%" }}
                />
              </Input.Group>

              <TextField
                label="Address"
                name="addr"
                placeholder="Address"
                hasFeedback
              />
              <Row gutter={16}>
                <Col span={12}>
                  <TextField
                    label="Postal Code"
                    name="postcode"
                    placeholder="Postal Code"
                    rules={[{ required: true }]}
                    hasFeedback
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    label="Country"
                    name="country_id"
                    placeholder="Country"
                    options={countryOptions}
                    onChange={(value, options) => fetchStateData(value)}
                    rules={[{ required: true }]}
                    hasFeedback
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <SelectField
                    label="State"
                    name="state_id"
                    placeholder="State"
                    options={stateOptions}
                    onChange={(value, options) => fetchCityData(value)}
                    rules={[{ required: true }]}
                    hasFeedback
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    label="City"
                    name="city_id"
                    placeholder="City"
                    options={cityOptions}
                    rules={[{ required: true }]}
                    hasFeedback
                  />
                </Col>
              </Row>
            </Card>
            <Card title="Login Info">
              <Row gutter={16}>
                <Col span={24}>
                  <TextField
                    label="Mobile No"
                    name="mobile_number"
                    type="text"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <TextField
                    label="Password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    rules={[{ min: 6 }]}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    hasFeedback
                  />
                </Col>
                <Col span={12}>
                  <TextField
                    label="Confirm Password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    type="password"
                    dependencies={["password"]}
                    autoComplete="new-password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    hasFeedback
                    rules={[
                      { min: 6 },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  />
                </Col>
              </Row>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2rem",
                }}
              >
                <Button
                  key="reset"
                  onClick={onReset}
                  style={{ marginRight: "10px" }}
                >
                  Reset
                </Button>
                <Button key="submit" type="primary" htmlType="submit">
                  {createMerchant ? "Submit" : "Update"}
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default MerchantForm;
