import {
  HomeOutlined,
  PlusOutlined,
  RedoOutlined,
  SearchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form } from "antd";
import React, { useState, useEffect, useContext } from "react";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faGear } from "@fortawesome/free-solid-svg-icons";
import DatatableMerchantStaff from "../../Datatable/Dt-merchantStaff";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import { Link } from "react-router-dom";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import MerchantStaffFormModal from "../../Modals/MerchantStaffFormModal";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";
import { fetchSysGeneralData,
        getMerchantDetailsList,
       } from "../../../../actions/Admin/actionCreators";
library.add(fas, faGear);

const breadcrumbItem = [
  {
    label: "",
    link: "/admin/dashboard",
    icon: <HomeOutlined />,
  },
  {
    label: "Merchant",
    icon: <FontAwesomeIcon icon="fa-solid fa-shop" />,
  },
  {
    label: "Merchant Staff Listing",
    link: "/admin/merchant-staff-list",
  },
];

const data = [
  {
    id: "1",
    merchant_name: "Admin A",
    name: "Staff 1",
    email: "staffone@email.com",
    dob: "1997-12-15",
    mobile_no: "0125672987",
    status: "Active",
    created_at: "2022-08-08",
    updated_at: "2022-08-10",
  },
  {
    id: "2",
    merchant_name: "Suchi",
    name: "Staff 2",
    email: "stafftwo@email.com",
    dob: "1990-06-27",
    mobile_no: "0135623987",
    status: "Active",
    created_at: "2022-08-08",
    updated_at: "2022-08-10",
  },
  {
    id: "3",
    merchant_name: "Weimin",
    name: "Staff 3",
    email: "staffthree@email.com",
    dob: "1992-03-11",
    mobile_no: "0104528763",
    status: "Inactive",
    created_at: "2022-08-08",
    updated_at: "2022-08-10",
  },
  {
    id: "4",
    merchant_name: "Weimin",
    name: "Staff 3",
    email: "staffthree@email.com",
    dob: "2002-03-11",
    mobile_no: "0104528763",
    status: "Inactive",
    created_at: "2022-08-08",
    updated_at: "2022-08-10",
  },
];

const MerchantStaffList = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(data);
	const [statusSource, setStatusSource] = useState([]);
  const [merchantDetailsSource, setMerchantDetailsSource] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const api = useContext(AxiosContext);
	const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [sorting, setSorting] = useState({
    sortField: "id",
    sortOrder: "DESC",
  });
  const [merchantstaffModalVisible, setMerchantStaffModalVisible] =
    useState(false);

  useEffect(() => {
    fetchGeneralData();
    fetchMerchantDetails();
  }, [pagination]);

  const fetchGeneralData = async () => {
    const param = {};
    param["type"] = "general_status";
    param["status"] = "A";

    const response = await fetchSysGeneralData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ code, name }) => ({
        value: code,
        desc: name,
      }));

      setStatusSource(transformed);
    }
  };

  const fetchMerchantDetails = async () => {
    const param = {};
    param["status"] = "A";

    const response = await getMerchantDetailsList({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      console.log(response)
      const transformed = response.map(({ code, name, shop_name }) => ({
        value: shop_name,
        desc: shop_name,
      }));

      setMerchantDetailsSource(transformed);
    }
  };

  const showMerchantStaffFormModal = (record) => {
    setMerchantStaffModalVisible(true);
    if (record) {
      setFormValue(record);
    } else {
      setFormValue({});
    }
  };

  const onMerchantStaffModalCancel = () => {
    setMerchantStaffModalVisible(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let order = "ASC";

    if (sorter.order == "descend") {
      order = "DESC";
    }

    setSorting({
      sortField: sorter.column ? sorter.field : "id",
      sortOrder: sorter.order ? order : "DESC",
    });

    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const onFinish = (values) => {
    // getLanguageListData(values);
  };

  const searchField = [
    {
      input: (
        <SelectField 
        name="search_merchant_name" 
        placeholder="Merchant Name"
        options={ merchantDetailsSource } />
      ),
    },
    {
      input: <TextField name="search_name" placeholder="Full Name" />,
    },
    {
      input: <TextField name="search_email" placeholder="Email" />,
    },
    {
      input: (
        <SelectField
          placeholder="Status"
          name="search_status"
          options={statusSource}
        />
      ),
    },
  ];

  const searchFieldButton = [
    {
      button: (
        // <Link to="/language/language-create">
        <Button
          type="secondary"
          icon={<PlusOutlined />}
          style={{ width: "100%" }}
          onClick={() => {
            showMerchantStaffFormModal();
          }}
        >
          Add New
        </Button>
        // </Link>
      ),
    },
    {
      button: (
        <Button
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          style={{ width: "100%" }}
        >
          Search
        </Button>
      ),
    },
    {
      button: (
        <Button
          type="danger"
          style={{ width: "100%" }}
          icon={<RedoOutlined />}
          onClick={() => {
            form.resetFields();
            // getLanguageListData({});
          }}
        >
          Reset
        </Button>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbLayout items={breadcrumbItem} />
      <Divider />
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <AdvSearchComponent
          field={searchField}
          buttonField={searchFieldButton}
        />
      </Form>
      <Divider dashed />
      <DatatableMerchantStaff
        dataSource={data}
        pagination={pagination}
        loading={loading}
        handleTableChange={handleTableChange}
        showMerchantStaffFormModal={showMerchantStaffFormModal}
      />
      <MerchantStaffFormModal
        visible={merchantstaffModalVisible}
        onCancel={onMerchantStaffModalCancel}
        formValue={formValue}
      />
    </>
  );
};

export default MerchantStaffList;
