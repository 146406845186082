import MerchantCompanySetting from "../../components/Merchant/Company/CompanySetting";
import MerchantCompanySettingForm from "../../components/Merchant/Form/Company/CompanySettingForm";
import SetHoliday from "../../components/Merchant/Company/SetHoliday";
import MerchantDashboard from "../../components/Merchant/Dashboard/Dashboard";
import MerchantLogin from "../../components/Merchant/Login/Login";
import MerchantSignup from "../../components/Merchant/Signup/Signup";
import MerchantSignupCompleted from "../../components/Merchant/Signup/SignupCompleted";
import MerchantSignupStep1 from "../../components/Merchant/Signup/SignupStep1";
import MerchantSignupStep2 from "../../components/Merchant/Signup/SignupStep2";
import MerchantSignupStep2_1 from "../../components/Merchant/Signup/SignupStep2_1";
import MerchantSignupStep2_2 from "../../components/Merchant/Signup/SignupStep2_2";
import MerchantSignupStep2_3 from "../../components/Merchant/Signup/SignupStep2_3";
import MerchantStylistSetting from "../../components/Merchant/Stylist/StylistSetting";
import MerchantServiceSetting from "../../components/Merchant/Service/ServiceSetting";
import MerchantPriceSetting from "../../components/Merchant/Price/PriceSetting";
import PriceSettingForm from "../../components/Merchant/Form/Price/PriceSettingForm";
import MerchantClientContact from "../../components/Merchant/Contact/ClientContact";

const routes = [
	{
		path: "/merchant",
		exact: true,
		auth: false,
		element: <MerchantLogin />,
	},
	{
		path: "/merchant/signup",
		exact: true,
		auth: false,
		element: <MerchantSignup />,
	},
	{
		path: "/merchant/signup-step1",
		exact: true,
		auth: true,
		element: <MerchantSignupStep1 />,
	},
	{
		path: "/merchant/signup-step2",
		exact: true,
		auth: true,
		element: <MerchantSignupStep2 />,
	},
	{
		path: "/merchant/signup-step2_1",
		exact: true,
		auth: true,
		element: <MerchantSignupStep2_1 />,
	},
	{
		path: "/merchant/signup-step2_2",
		exact: true,
		auth: true,
		element: <MerchantSignupStep2_2 />,
	},
	{
		path: "/merchant/signup-step2_3",
		exact: true,
		auth: true,
		element: <MerchantSignupStep2_3 />,
	},
	{
		path: "/merchant/signup-completed",
		exact: true,
		auth: true,
		element: <MerchantSignupCompleted />,
	},
	{
		path: "/merchant/dashboard",
		exact: true,
		auth: true,
		element: <MerchantDashboard />,
	},
	{
		path: "/merchant/company-setting",
		exact: true,
		auth: true,
		element: <MerchantCompanySetting />,
	},
	{
		path: "/merchant/company-setting-form",
		exact: true,
		auth: true,
		element: <MerchantCompanySettingForm />,
	},
	{
		path: "/merchant/company-set-holiday",
		exact: true,
		auth: true,
		element: <SetHoliday />,
	},
	{
		path: "/merchant/stylist-setting",
		exact: true,
		auth: true,
		element: <MerchantStylistSetting />,
	},
	{
		path: "/merchant/service-setting",
		exact: true,
		auth: true,
		element: <MerchantServiceSetting />,
	},
	{
		path: "/merchant/price-setting",
		exact: true,
		auth: true,
		element: <MerchantPriceSetting />,
	},
	{
		path: "/merchant/price-setting-form",
		exact: true,
		auth: true,
		element: <PriceSettingForm />,
	},
	{
		path: "/merchant/client-contact",
		exact: true,
		auth: true,
		element: <MerchantClientContact />,
	},
];

export default routes;
