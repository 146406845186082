import { HomeOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Col, Form, List, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchClient } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import ClientContactForm from "../Form/Contact/ClientContactForm";
import InputField from "../Global/FormInputComponent/InputField";
import SelectField from "../Global/FormInputComponent/SelectField";
import AppointmentForm from "../Form/Appointment/AppointmentForm";

export default function MerchantClientContact() {
  const { Title } = Typography;
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Client Contact",
      link: "/merchant/client-contact",
    },
  ];

  const sort_by_options = [
    {
      value: "asc",
      desc: "A-Z",
    },
    {
      value: "desc",
      desc: "Z-A",
    },
  ];

  const cardActionOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let index = event.target.getAttribute("data-ind");
    setData(list[index]);
  };

  const getClient = async () => {
    const response = await fetchClient({ api, dispatch });

    if (response) {
      setList(response);
    }
  };

  const closeAppointment = () => {
    setVisible(false);
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Client Contact</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Row gutter={20}>
            <Col md={6} xs={24} style={{ paddingBottom: "10px" }}>
              <div
                style={{
                  display: "flex",
                  border: "1px solid #E3E3E3",
                  borderRadius: "5px",
                }}
              >
                <span
                  style={{
                    padding: "5px 10px",
                    borderRight: "1px solid #E3E3E3",
                    width: "40%",
                  }}
                >
                  Sort By
                </span>
                <SelectField
                  name="sort_by"
                  style={{ marginBottom: "0px", width: "60%" }}
                  className="merchant-input-no-border"
                  options={sort_by_options}
                />
              </div>
            </Col>
            <Col md={6} xs={24}>
              <InputField
                className="merchant-input-no-border"
                name="search"
                prefix={<SearchOutlined />}
                placeholder="Search"
              />
            </Col>
            <Col md={12} xs={24}>
              <Row gutter={20} justify="end">
                <Col>
                  <button
                    htmltype="button"
                    className="btn-secondary w-100"
                    onClick={() => {
                      setData([]);
                    }}
                    style={{ marginBottom: "2rem" }}
                  >
                    Add new Client
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <List
                className="contact-list"
                itemLayout="horizontal"
                dataSource={list}
                renderItem={(item, index) => (
                  <List.Item
                    className="bg-white"
                    style={{
                      padding: "10px 15px",
                      borderRadius: "10px",
                      boxShadow: "0px 3px 10px #0000001A",
                      marginBottom: "1rem",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size={16}
                          style={{
                            backgroundColor: item.color,
                          }}
                        />
                      }
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            maxWidth: "70%",
                          }}
                        >
                          <span>{item.name}</span>
                          <span>{item.mobile_desc}</span>
                        </div>
                      }
                    />
                    <div>
                      <button className="btn-clear client-contact-button">
                        {item.appointment}
                        <img
                          src="/icons/icon-airplane.svg"
                          style={{ marginLeft: "7px" }}
                        ></img>
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn-clear client-contact-button"
                        onClick={() => {
                          setVisible(true);
                          setSelectedClient(item);
                        }}
                      >
                        Make appointment
                      </button>
                    </div>
                    <div
                      className="client-contact-button"
                      style={{ padding: "0px" }}
                    >
                      <img
                        src="/icons/whatsapp.png"
                        style={{
                          width: "26px",
                          height: "26px",
                        }}
                      ></img>
                    </div>
                    <div>
                      <button
                        className="btn-clear"
                        style={{
                          padding: "4px 7px",
                          minWidth: "auto",
                          color: "var(--font-color)",
                          boxShadow: "none",
                        }}
                        type="button"
                      >
                        <img
                          src="/icons/icon-edit.svg"
                          onClick={cardActionOnClick}
                          data-ind={index}
                        ></img>
                      </button>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3", minHeight: "95vh" }}
      >
        <ClientContactForm
          data={data}
          setData={setData}
          getClient={getClient}
        />
      </Col>
      <AppointmentForm
        visible={visible}
        action={closeAppointment}
        selectedClient={selectedClient}
      />
    </Row>
  );
}
