import { Col, Form, message, Row, Typography } from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import SelectField from "../../Global/FormInputComponent/SelectField";
import {
  editAppointment,
  fetchServices,
  updateAppointmentStatus,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";
import InputNumberField from "../../Global/FormInputComponent/InputNumber";

library.add(fas, faXmark);

export default function AppointmentEditForm(props) {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [items, setItems] = useState([]);
  const [remove, setRemove] = useState([]);

  const service_options = [];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };

  const getServices = async () => {
    const response = await fetchServices({ api, dispatch });

    if (response) {
      response.map((services, ind) => {
        service_options.push({
          value: services.id,
          desc: <div>{services.name}</div>,
        });
      });
      setServiceOptions(service_options);
    }
  };

  const postAppointmentEditForm = async (data) => {
    const response = await editAppointment({
      api,
      data: data,
      id: props.data.id,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      form.resetFields();
      setItems([]);
      setRemove([]);
      props.action();
    }
  };

  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("_method", "PUT");

    if (items.length > 0) {
      items.map((itm, key) => {
        if (itm.prd_master_id) {
          formData.set(`prd_master_ids[${key}]`, itm.prd_master_id);
        }
        if (itm.id) {
          formData.set(`item_ids[${key}]`, itm.id);
        }
      });
    }

    if (remove.length > 0) {
      remove.map((rm, key) => {
        formData.set(`remove_item_ids[${key}]`, rm);
      });
    }

    if (values["total"]) {
      formData.append("total_price", values["total"]);
    }

    postAppointmentEditForm(formData);
  };

  const removeService = (event) => {
    let index = event.target.getAttribute("data-ind");

    var array = [...items];
    if (index !== -1) {
      let remove_id = array[index]["id"];
      let removeArray = [...remove];
      if (remove_id) {
        removeArray.push(remove_id);
        setRemove(removeArray);
      }

      array.splice(index, 1);
      setItems(array);
    }
  };

  const addService = (event) => {
    var array = [...items];
    var title_input = form.getFieldValue("service");
    let name = "";

    serviceOptions.map((svs, key) => {
      if (svs.value == title_input) {
        name = svs.desc.props.children;
      }
    });

    if (title_input) {
      array.push({
        prd_master_id: title_input,
        name: name,
      });
      setItems(array);
      form.setFieldValue("service", "");
    } else {
      message.error("Please select service before add");
    }
  };

  const postUpdateApptStatus = async (data) => {
    const response = await updateAppointmentStatus({
      api,
      data: data,
      id: props.data.id,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      form.resetFields();
      setItems([]);
      setRemove([]);
      props.action();
    }
  };

  const updateStatus = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const status = event.target.getAttribute("data-status");
    const formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("status", status);

    postUpdateApptStatus(formData);
  };

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    if (props.data.items) {
      setItems(props.data.items);
    }
  }, [props.data]);

  return (
    <div
      className="appointment-form"
      style={{ display: props.visible ? "block" : "none" }}
    >
      <button
        className="btn-clear"
        onClick={props.action}
        style={{
          padding: "4px 10px",
          width: "36px",
          height: "36px",
          minWidth: "36px",
          margin: "1.5rem",
          marginBottom: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </div>
      </button>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Appointment Detail</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <Form
          {...layout}
          name="holiday"
          form={form}
          initialValues={{ date: moment() }}
          validateMessages={validateMessages}
          onFinish={onFinish}
        >
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={12}>Client Name</Col>
            <Col className="font-color" span={12} style={{ textAlign: "end" }}>
              {props.data.title_name} {props.data.name}
            </Col>
          </Row>
          <hr style={{ marginBottom: "1rem" }} />
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={12}>Mobile</Col>
            <Col className="font-color" span={12} style={{ textAlign: "end" }}>
              <a
                className="client-contact-button"
                style={{ padding: "0px" }}
                href={`https://wa.me/${props.data.mobile_prefix}${
                  props.data.mobile_no
                }?text=${encodeURI(
                  "Hi, we are from " +
                    localStorage.getItem("merchant_name") +
                    ". Reminder of your appointment on " +
                    props.data.booking_date_format
                )}`}
                target="_blank"
              >
                <img
                  src="/icons/whatsapp.png"
                  style={{
                    marginLeft: "7px",
                    width: "26px",
                    height: "26px",
                  }}
                  alt="Minus Whatsapp"
                />
              </a>
              {props.data.mobile_prefix} {props.data.mobile_no}
            </Col>
          </Row>
          <hr style={{ marginBottom: "1rem" }} />
          {props.data.staff_name ? (
            <>
              <Row
                justify="space-between"
                className="header-color"
                style={{ alignItems: "center" }}
              >
                <Col span={12}>Stylist</Col>
                <Col
                  className="font-color"
                  span={12}
                  style={{ textAlign: "end" }}
                >
                  {props.data.staff_name} ({props.data.staff_title})
                </Col>
              </Row>
              <hr style={{ marginBottom: "1rem" }} />
            </>
          ) : (
            ""
          )}

          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={12}>Date & Time</Col>
            <Col className="font-color" span={12} style={{ textAlign: "end" }}>
              {props.data.booking_date_format}
            </Col>
          </Row>
          <hr />
          <Row style={{ marginBottom: "1rem", padding: "6.5px 0" }}>
            <Col className="header-color" span={24}>
              Service Type
            </Col>
          </Row>
          {items
            ? items.map((itm, key) => {
                return (
                  <div key={key}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/icons/btn-minus.svg"
                        style={{ width: 27, height: 27 }}
                        onClick={removeService}
                        data-ind={key}
                      ></img>
                      <span style={{ marginLeft: "1rem" }}>{itm.name}</span>
                    </div>
                    <hr style={{ margin: "1rem 0" }} />
                  </div>
                );
              })
            : ""}

          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/icons/btn-add.svg"
              style={{ width: 27, height: 27 }}
              onClick={addService}
            ></img>
            <SelectField
              mainClassName="appointment-service"
              style={{
                marginBottom: "0px",
                textAlignLast: "left",
                width: "90%",
              }}
              className="merchant-input-no-border"
              name="service"
              options={serviceOptions}
              selectStyle={{ fontSize: "16px" }}
            />
          </div>
          <hr style={{ margin: "1rem 0" }} />
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={12}>Total</Col>
            <Col span={12}>
              <InputNumberField
                name="total"
                style={{ marginBottom: "0px", textAlign: "end" }}
                className="merchant-input-no-border"
                formatter={(value) =>
                  `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
                step="0.01"
                stringMode
              />
            </Col>
          </Row>
          <hr style={{ margin: "1rem 0" }} />
          <Row justify="space-between">
            <button
              htmltype="submit"
              className="btn-secondary"
              style={{ minWidth: "100px" }}
            >
              Save
            </button>
            <button
              htmltype="button"
              className="btn-secondary"
              data-status="COM"
              onClick={updateStatus}
            >
              Complete
            </button>
            <button
              htmltype="button"
              className="btn-clear client-contact-button"
              style={{
                color: "#FE6C20",
                borderColor: "#FE6C20",
              }}
              data-status="CNL"
              onClick={updateStatus}
            >
              Cancel appt.
            </button>
            <button
              htmltype="button"
              className="btn-clear client-contact-button"
              data-status="F"
              onClick={updateStatus}
            >
              <img
                src="/icons/icon-airplane.svg"
                alt="Icon Airplane"
                data-status="F"
              />
            </button>
          </Row>
        </Form>
      </div>
    </div>
  );
}
