import { Button, Form, message, Upload } from "antd";
import React from "react";

export default function UploadField(props) {
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.url = URL.createObjectURL(file);
    }
    window.open(file.url, "_blank");
  };

  const fileInput = {
    onRemove: (file) => {
      props.setImportData({
        dataList: [],
      });
    },

    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";

      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");

        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");

        return false;
      }
      // return isJpgOrPng && isLt2M;
      // if (!file.url && !file.preview) {
      file.url = URL.createObjectURL(file);

      // }
      props.setImportData({
        dataList: [file],
      });

      return false;
    },
  };
  return (
    <Form.Item
      className={`upload-avatar ${props.className ?? ""}`}
      label={props.label}
    >
      <Upload
        {...fileInput}
        listType="picture-card"
        fileList={props.importData.dataList}
        onPreview={handlePreview}
      >
        {/* {props.importData.dataList.length >= 1 ? null : ( */}
        <Button
          style={{ padding: "4px", border: "none" }}
          icon={
            <img src="/icons/add-logo.svg" style={{ width: 30, height: 30 }} />
          }
        />
        {/* )} */}
      </Upload>
    </Form.Item>
  );
}
