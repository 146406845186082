import { HomeOutlined } from "@ant-design/icons";
import { Avatar, Col, message, Row, Typography, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchPrice } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";

const MerchantPriceSetting = (props) => {
  const { Title } = Typography;
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [fileType, setFileType] = useState("img");
  const [imageUrl, setImageUrl] = useState();
  const [descJson, setDescJson] = useState({});
  const [descArray, setDescArray] = useState(0);

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
    },
    {
      label: "Price Setting",
      link: "/merchant/price-setting",
    },
  ];

  const getPrice = async () => {
    const response = await fetchPrice({ api, dispatch });

    if (response) {
      let length = 0;
      if (response[0]?.price_list_type_name == "HTML") {
        for (const [index, desc] of Object.entries(
          JSON.parse(response[0].desc)
        )) {
          if (index == "title") {
            length += Object.keys(desc).length;
          }

          if (index == "service_type") {
            length += Object.keys(desc).length;
          }
        }
        setDescArray(new Array(length));
        setDescJson(JSON.parse(response[0].desc));
      } else if (response[0]?.file_path) {
        setImageUrl(response[0].file_path);
      }
    }
  };

  const fileInput = {
    onRemove: (file) => {
      setImageUrl("");
    },
    beforeUpload: (file) => {
      if (file.type === "application/pdf") {
        setFileType("pdf");
      } else {
        setFileType("img");
      }

      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";

      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/PDF file!");

        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");

        return false;
      }

      // return false;
    },
    onChange: (info) => {
      let newFileList = [...info.fileList];

      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      newFileList = newFileList.slice(-1);

      // 2. Read from response and show file link
      newFileList = newFileList.map((file) => {
        if (file.response) {
          // Component will show file.url as link
          // file.url = file.response.url;
          if (!file.response.error) {
            getPrice();
          }
          message.success(file.response.message);
        }
        return file;
      });
    },
  };

  useEffect(() => {
    getPrice();
  }, []);
  return (
    <>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Price Setting</strong>
        </Title>
        <BreadcrumbLayout items={breadcrumbItem} />
      </div>
      <div style={{ margin: "0 25px" }}>
        <p>Create your price list</p>
        <div className="price-header">
          <span>Upload your price list</span>
          <small
            className="muted-color"
            style={{ lineHeight: 2, paddingLeft: "5px" }}
          >
            (File format: jpg, pdf)
          </small>
          <Upload
            {...fileInput}
            accept=".jpg, .pdf, .png, .jpeg"
            name="file_path"
            action={process.env.REACT_APP_SERVER_URL + "/merchant/price_list"}
            method="POST"
            headers={{
              Accept: "application/json",
              // "Content-Type":
              //   "application/json;multipart/form-data; charset=UTF-8",
              Authorization: localStorage.getItem("merchant_token"),
            }}
            data={{
              price_list_type_code: fileType,
            }}
            showUploadList={false}
          >
            <button
              htmltype="button"
              className="btn-secondary"
              style={{ padding: "5px 15px", margin: "0 1rem" }}
            >
              <strong>UPLOAD</strong>
            </button>
          </Upload>
          <span>Or</span>
          {Object.values(descJson).length != 0 ? (
            <Link
              to="/merchant/price-setting-form"
              state={{ json: descJson, desc: descArray }}
            >
              <button
                htmltype="button"
                className="btn-secondary"
                style={{ padding: "5px 15px", margin: "0 1rem" }}
              >
                <strong>EDIT PRICES LIST</strong>
              </button>
            </Link>
          ) : (
            <Link to="/merchant/price-setting-form">
              <button
                htmltype="button"
                className="btn-secondary"
                style={{ padding: "5px 15px", margin: "0 1rem" }}
              >
                <strong>CREATE PRICES LIST</strong>
              </button>
            </Link>
          )}
        </div>
        <Row style={{ margin: "3rem 0" }} justify="center" align="center">
          <Col span={24} style={{ textAlign: "center" }}>
            {Object.values(descJson).length != 0 ? (
              <div style={{ border: "1px solid var(--border-color)" }}>
                <div className="bg-white" style={{ padding: "2rem" }}>
                  <Row justify="center" style={{ marginBottom: "2rem" }}>
                    <Col span={24}>
                      <Avatar
                        size={100}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          border: "1px solid var(--border-color)",
                        }}
                        icon={
                          <div className="MctLogo" style={{ fontSize: "28px" }}>
                            Logo
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                  {descArray?.length > 0
                    ? [...descArray]?.map((element, index) => {
                        if (descJson?.title?.[index]) {
                          return (
                            <Row
                              justify="center"
                              style={{ marginBottom: "1rem" }}
                              key={index}
                            >
                              <Col span={24}>
                                <div className="cochin-font-title">
                                  <span>{descJson?.title?.[index]}</span>
                                </div>
                              </Col>
                            </Row>
                          );
                        } else if (descJson?.service_type?.[index]) {
                          return (
                            <Row
                              justify="center"
                              style={{ marginBottom: "1rem" }}
                              key={index}
                            >
                              <Col span={24}>
                                <Row
                                  justify="space-between"
                                  style={{ alignItems: "center" }}
                                >
                                  <Col>
                                    <span>
                                      {descJson?.service_type?.[index]}
                                    </span>
                                  </Col>
                                  <Col flex="auto">
                                    <div className="dotted-line" />
                                  </Col>
                                  <Col>
                                    <span>{descJson?.price?.[index]}</span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            ) : (
              <img src={imageUrl} className="w-100" />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MerchantPriceSetting;
