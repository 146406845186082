import { isLoadingAction } from "../../../actions/Admin/actionTypes";

const initialState = {
  isLoading: false,
};

export default function GeneralReducer(state = initialState, action) {
  switch (action.type) {
    case isLoadingAction:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
