import { Avatar, Card, Col, Row, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import {
	fetchBookingStepData,
	createBookingStep,
	LogoutAction,
} from "../../../actions/Merchant/actionCreators";
import "../../../assets/css/Merchant/login.css";

const MerchantSignupStep2 = () => {
	const dispatch = useDispatch();
	const [stepsObj, setStepsObj] = useState("");
	const api = useContext(AxiosContext);
	const navigate = useNavigate();

	useEffect(() => {
		fetchStepsData();
	}, []);

	const fetchStepsData = async () => {
		var param = {};
		param["category_code"] = localStorage.getItem("category");

		const response = await fetchBookingStepData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			var result = stepsLayout(response);
			setStepsObj(result);
		}
	};

	const postBookingStep = async (selectedStepId) => {
		var param = {};
		param["booking_step_id"] = selectedStepId;

		const response = await createBookingStep({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				localStorage.setItem("steps", response.results.signup_step);
				localStorage.setItem("signup_data", JSON.stringify(response.results.signup_data ?? []));
				message
					.success(response.message, 5)
					.then(navigate("/merchant/signupStep2"));
			}
		}
	};

	const handleSelectedValue = (e) => {
		postBookingStep(e.target.value);
	};

	const handleLogout = () => {
		dispatch(LogoutAction({ api }));
	};

	const stepsLayout = (data) => {
		if (data.length === 0) {
			return (
				<Col xs={24} md={24} xl={24} style={{ marginBottom: "1.5rem" }}>
					<Card className="merchant-card">
						<div>
							<Row
								style={{
									marginTop: "5rem",
									marginBottom: "5rem",
									justifyContent: "center",
								}}
							>
								<Col
									style={{
										alignSelf: "center",
									}}
								>
									<div className="LoginBtn">
										No Booking Step Available
									</div>
								</Col>
							</Row>
						</div>
					</Card>
				</Col>
			);
		} else {
			return Object.entries(data).map(([key, items]) => {
				return (
					<Col
						xs={24}
						md={12}
						xl={8}
						style={{ marginBottom: "1.5rem" }}
						key={key}
					>
						<Card className="merchant-card">
							{items.steps.length > 0 &&
								Object.entries(items.steps).map(
									([key, value]) => {
										return (
											<div key={key}>
												<Row
													style={
														items.steps.length -
															1 ==
														key
															? {
																	marginBottom:
																		"5rem",
															  }
															: {}
													}
												>
													<Col>
														<Avatar
															size={50}
															className="merchant-avatar"
															icon={
																<img
																	src={
																		value.img_path
																	}
																	style={{
																		width: 24,
																		height: 24,
																	}}
																></img>
															}
														/>
													</Col>
													<Col
														style={{
															alignSelf: "center",
														}}
													>
														{value.name}
													</Col>
												</Row>
												{items.steps.length - 1 !=
												key ? (
													<Row>
														<Col className="merchant-avatar-link">
															<span>|</span>
														</Col>
													</Row>
												) : (
													""
												)}
											</div>
										);
									}
								)}
							<Row
								justify="center"
								style={{
									position: "absolute",
									width: "calc(100% - 60px)",
									bottom: "24px",
								}}
							>
								<button
									htmltype="submit"
									className="btn-secondary w-100"
									value={items.id}
									onClick={(e) =>
										handleSelectedValue(e, "value")
									}
									disabled={
										items.steps.length === 0 ? true : false
									}
								>
									Select
								</button>
							</Row>
						</Card>
					</Col>
				);
			});
		}
	};

	return (
		<div className="container">
			<Row className="py-4" justify="space-between">
				<Col>
					<div className="MctLogoSmall">Logo</div>
				</Col>
				<Col>
					<div className="LoginBtn" onClick={handleLogout}>
						Logout
					</div>
				</Col>
			</Row>
			<Row justify="start" style={{ maxWidth: "1000px", margin: "auto" }}>
				<Col>
					<div className="text-header">Booking Step</div>
					<p style={{ marginBottom: "1.5rem", color: "#8FA07E" }}>
						Set the booking step
					</p>
				</Col>
			</Row>
			<Row
				justify="center"
				gutter={24}
				style={{
					maxWidth: "1000px",
					marginLeft: "auto",
					marginRight: "auto",
				}}
			>
				{stepsObj}
			</Row>
		</div>
	);
};

export default MerchantSignupStep2;
