import {
	FlagOutlined,
	LockOutlined,
	MobileOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Merchant/login.css";
import OtpModal from "../Modals/OtpModal";
import {
	SignupAction,
	LoginAction,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";

const { Option } = Select;

const MerchantSignup = () => {
	const dispatch = useDispatch();
	const api = useContext(AxiosContext);
	const [visible, setVisible] = useState(false);
	const [checkValue, setCheckValue] = useState(true);
	const [val, setVal] = useState("");
	const [formValue, setFormValue] = useState({});

	const onCancel = () => {
		setVisible(false);
	};

	const checkLogin = () => {
		dispatch(
			LoginAction({
				api,
				data: {
					mobile_no: formValue.mobile_no,
					password: formValue.password,
				},
			})
		);
	};

	const onFinish = (values) => {
		values.mobile_no = values.mobile_prefix + values.mobile_no;
		postSignup(values);
	};

	const onFinishFailed = () => {};
	const changeCheckbox = () => {
		setCheckValue(!checkValue);
	};

	const postSignup = async (values) => {
		const response = await SignupAction({
			api,
			data: values,
			dispatch,
		});

		if (response) {
			console.log("response", response);
			if (response.code === 200) {
				values["otp"] = response.results.otp_code;
				values["expiry_date"] = response.results.expired_date;
				values["otp_id"] = response.results.otp_id;
				setFormValue(values);
				setVisible(true);
			}
		}
	};

	document.body.style.backgroundImage = "url(/login-bg2.jpeg)";

	return (
		<div className="container">
			<Row className="py-4">
				<Col>
					<div className="MctLogo">Logo</div>
				</Col>
			</Row>
			<Row align="center" justify="center" id="signup-form">
				<Col>
					<div className="LoginCard">
						<div className="LoginHeader">Create Account</div>
						<p style={{ marginBottom: "2rem", color: "#E3E3E3" }}>
							Let’s get started
						</p>
						<Form
							name="signup"
							initialValues={{
								remember: true,
								mobile_prefix: "60",
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Form.Item
								className="merchant-form-item"
								name="name"
								rules={[
									{
										required: true,
										message: "Please input your name!",
									},
								]}
							>
								<Input
									className="merchant-input-password"
									placeholder="Name"
									prefix={
										<UserOutlined className="muted-color" />
									}
								/>
							</Form.Item>
							<Form.Item className="merchant-form-item">
								<Input.Group
									compact
									className="merchant-input-group merchant-input-prefix"
								>
									<Input
										className="merchant-input"
										style={{ width: "10%" }}
										prefix={
											<MobileOutlined className="muted-color" />
										}
									/>
									<Form.Item name="mobile_prefix" noStyle>
										<Select
											style={{ width: "35%" }}
											className="merchant-mobile-select"
										>
											<Option value="60">
												<FlagOutlined />{" "}
												<span> + 60</span>
											</Option>
											<Option value="65">
												<FlagOutlined />{" "}
												<span> + 65</span>
											</Option>
										</Select>
									</Form.Item>

									<Form.Item name="mobile_no" noStyle>
										<Input
											type="text"
											pattern="[0-9]*"
											style={{
												width: "50%",
											}}
											value={val}
											className="merchant-input-mobile"
											placeholder="Mobile number"
										/>
									</Form.Item>
								</Input.Group>
							</Form.Item>
							<Form.Item
								className="merchant-form-item"
								name="password"
								rules={[
									{
										required: true,
										message: "Please input your password!",
									},
								]}
							>
								<Input.Password
									className="merchant-input-password"
									placeholder="Set Password"
									autoComplete="new-password"
									prefix={
										<LockOutlined className="muted-color" />
									}
								/>
							</Form.Item>
							<Form.Item
								className="merchant-form-item"
								name="password_confirmation"
								rules={[
									{
										required: true,
										message:
											"Please input your confirm password!",
									},
								]}
							>
								<Input.Password
									className="merchant-input-password"
									placeholder="Password again"
									prefix={
										<LockOutlined className="muted-color" />
									}
								/>
							</Form.Item>
							<Checkbox
								checked={checkValue}
								name="tnc"
								className="merchant-checkbox"
								onChange={changeCheckbox}
								style={{ marginBottom: "3rem" }}
							>
								I have read and agreed{" "}
								<a href="#" style={{ color: "#D3DEC3" }}>
									Terms and conditions
								</a>
							</Checkbox>
							<Form.Item className="merchant-form-item">
								<button
									htmltype="submit"
									className="w-100 btn-primary"
								>
									Submit
								</button>
							</Form.Item>
							<div
								className="text-center"
								style={{ marginTop: "2.5rem" }}
							>
								<div>
									Already have an account?{" "}
									<Link
										to="/merchant"
										style={{ color: "#D3DEC3" }}
									>
										Login
									</Link>
								</div>
							</div>
						</Form>
					</div>
				</Col>
			</Row>
			<OtpModal
				visible={visible}
				onCancel={onCancel}
				formValue={formValue}
				checkLogin={checkLogin}
			/>
		</div>
	);
};

export default MerchantSignup;
