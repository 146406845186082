import { Col, Form, Input, Row, Select, Image, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { LogoutAction } from "../../../actions/Merchant/actionCreators";
import "../../../assets/css/Merchant/login.css";
import {
	fetchTerritoryData,
	fetchBusinessType,
	createShopInfo,
} from "../../../actions/Merchant/actionCreators";

const { Option, OptGroup } = Select;
const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

const MerchantSignupStep1 = () => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const api = useContext(AxiosContext);
	const [val, setVal] = useState("");
	const [countryOptions, setCountryOptions] = useState([]);
	const [stateOptions, setStateOptions] = useState([]);
	const [cityOptions, setCityOptions] = useState([]);
	const [prefixOptions, setPrefixOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		console.log("useeffect");
		fetchCountryData();
		fetchBusinessTypeData();
	}, []);

	useEffect(() => {
		if (form.getFieldValue("country_id") != null) {
			fetchStateData(form.getFieldValue("country_id"));
		}
	}, [form.getFieldValue("country_id")]);

	const fetchCountryData = async () => {
		var param = {};
		param["territory_type"] = "country";
		param["status"] = "A";

		const response = await fetchTerritoryData({
			data: param,
			api,
			dispatch,
		});

		if (response) {
			setCountryOptions(response);
			setPrefixOptions(response);

			form.setFieldsValue({
				country_id: response[0]["id"],
				mobile_prefix: response[0]["calling_no_prefix"],
			});
		}
	};

	const fetchStateData = async (id) => {
		var param = {};
		param["territory_type"] = "state";
		param["parent_id"] = id;

		const response = await fetchTerritoryData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setStateOptions(response);
		}
	};

	const fetchCityData = async (id) => {
		if (id != "") {
			var param = {};
			param["territory_type"] = "city";
			param["parent_id"] = id;

			const response = await fetchTerritoryData({
				api,
				data: param,
				dispatch,
			});

			if (response) {
				setCityOptions(response);
			}
		}
	};

	const fetchBusinessTypeData = async () => {
		var param = {};
		param["status"] = "A";

		const response = await fetchBusinessType({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			var treeData = createDataTree(response);
			setTypeOptions(treeData);
		}
	};

	const createDataTree = (dataset) => {
		const hashTable = Object.create(null);
		dataset.forEach(
			(aData) => (hashTable[aData.name] = { ...aData, childNodes: [] })
		);
		const dataTree = [];
		dataset.forEach((aData) => {
			if (aData.parent_name)
				hashTable[aData.parent_name].childNodes.push(
					hashTable[aData.name]
				);
			else dataTree.push(hashTable[aData.name]);
		});

		return dataTree;
	};

	const handlePrefixChange = () => {
		let mobile_prefix = form.getFieldValue("mobile_prefix");

		var filtered_country = countryOptions.filter((item) => {
			return item.calling_no_prefix === mobile_prefix;
		});

		form.setFieldsValue({
			country_id: filtered_country[0]["id"],
		});

		fetchStateData(filtered_country[0]["id"]);
	};

	const postNewShopData = async (param) => {
		const response = await createShopInfo({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				localStorage.setItem("steps", response.results.signup_step);
				localStorage.setItem(
					"category",
					response.results.signup_data.category_code
				);

				message
					.success(response.message, 5)
					.then(navigate("/merchant/signupStep2"));
			}
		}
	};

	const onFinish = (values) => {
		form.setFieldsValue({
			prefer_language_code: "en",
		});
		postNewShopData(values);
	};

	const handleLogout = () => {
		dispatch(LogoutAction({ api }));
	};

	const onFinishFailed = () => {};
	document.body.style.backgroundImage = "none";

	return (
		<div className="container">
			<Row className="py-4" justify="space-between">
				<Col>
					<div className="MctLogoSmall">Logo</div>
				</Col>
				<Col>
					<div className="LoginBtn" onClick={handleLogout}>
						Logout
					</div>
				</Col>
			</Row>
			<Row justify="start" style={{ maxWidth: "800px", margin: "auto" }}>
				<Col>
					<div className="text-header">Shop info</div>
					<p style={{ marginBottom: "1.5rem", color: "#8FA07E" }}>
						Enter your shop Profile
					</p>
				</Col>
			</Row>
			<Row justify="center" style={{ maxWidth: "800px", margin: "auto" }}>
				<Col xs={24}>
					<Form
						{...layout}
						form={form}
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Row gutter={24}>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="shop_name"
									rules={[
										{
											required: true,
											message:
												"Please input your shop name!",
										},
									]}
								>
									<Input
										className="merchant-form-input"
										size="large"
										placeholder="Shop Name *"
									/>
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="display_shop_name"
									rules={[
										{
											required: true,
											message:
												"Please input your display shop name!",
										},
									]}
								>
									<Input
										className="merchant-form-input"
										size="large"
										placeholder="Display Shop Name *"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col md={12} xs={24}>
								<Input.Group
									compact
									className="merchant-form-input"
									style={{
										height: "42px",
										paddingTop: "2px",
									}}
								>
									<Form.Item
										className="merchant-form-item"
										name="mobile_prefix"
									>
										<Select
											name="mobile_prefix"
											defaultValue="60"
											className="merchant-mobile-select"
											onChange={handlePrefixChange}
										>
											{prefixOptions.map(
												(
													{
														calling_no_prefix,
														code,
														image_path,
													},
													index
												) => (
													<Option
														key={code}
														value={
															calling_no_prefix
														}
													>
														<Image
															width={25}
															src={image_path}
														/>{" "}
														{calling_no_prefix}
													</Option>
												)
											)}
										</Select>
									</Form.Item>
									<Form.Item
										className="merchant-form-item"
										name="mobile_no"
									>
										<Input
											type="text"
											pattern="[0-9]*"
											style={{
												padding: "0px",
											}}
											className="merchant-input-mobile"
											placeholder="Mobile number"
											value={val}
											onChange={(e) =>
												setVal((v) =>
													e.target.validity.valid
														? e.target.value
														: v
												)
											}
										/>
									</Form.Item>
								</Input.Group>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="email"
								>
									<Input
										className="merchant-form-input"
										size="large"
										placeholder="Email Address"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="country_id"
								>
									<Select
										className="merchant-form-select"
										onChange={(value, options) =>
											fetchStateData(value)
										}
									>
										<Option value="">Select Country</Option>
										{countryOptions.map(
											(
												{ id, code, name, image_path },
												index
											) => (
												<Option key={code} value={id}>
													<Image
														width={25}
														src={image_path}
													/>{" "}
													{name}
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="state_id"
								>
									<Select
										defaultValue=""
										className="merchant-form-select"
										onChange={(value, options) =>
											fetchCityData(value)
										}
									>
										<Option value="">Select State</Option>
										{stateOptions.map(
											({ id, code, name }, index) => (
												<Option key={code} value={id}>
													{name}
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="city_id"
								>
									<Select
										defaultValue=""
										className="merchant-form-select"
									>
										<Option value="">Select City</Option>
										{cityOptions.map(
											({ id, code, name }, index) => (
												<Option key={code} value={id}>
													{name}
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
							<Col md={12} xs={24}>
								<Form.Item
									className="merchant-form-item"
									name="postcode"
								>
									<Input
										className="merchant-form-input"
										size="large"
										placeholder="Postal Code"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									className="merchant-form-item"
									name="addr"
									style={{ marginBottom: "10px" }}
								>
									<Input
										className="merchant-form-input"
										size="large"
										placeholder="Address"
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label="Business Type"
									name="merchant_category_id"
									className="merchant-form-item"
									style={{ marginBottom: "10px" }}
								>
									<Select
										className="merchant-form-select"
										placeholder="Choose Business Type"
									>
										{typeOptions.map((main, key) => {
											return main.childNodes.map(
												(childs, key) => {
													return (
														<OptGroup
															label={childs.name}
														>
															{childs.childNodes.map(
																(
																	nodes,
																	index
																) => {
																	return (
																		<Option
																			key={
																				nodes.code
																			}
																			value={
																				nodes.id
																			}
																		>
																			{
																				nodes.name
																			}
																		</Option>
																	);
																}
															)}
														</OptGroup>
													);
												}
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<div
									style={{
										display: "flex",
										position: "relative",
									}}
								>
									<Form.Item
										label="Set Booking Link"
										className="merchant-form-item"
										style={{ width: "40%" }}
									>
										<Input
											className="merchant-form-input"
											size="large"
											disabled
											placeholder="www.smartbooking.com/"
										/>
									</Form.Item>
									<Form.Item
										name="booking_url"
										label="&nbsp;"
										className="merchant-form-item"
										style={{
											width: "70%",
											position: "absolute",
											right: 0,
											bottom: 0,
										}}
									>
										<Input
											className="merchant-form-input"
											size="large"
											placeholder="Create Name"
										/>
									</Form.Item>
								</div>
							</Col>
						</Row>
						<Row justify="center" style={{ marginBottom: "2rem" }}>
							<button
								htmltype="submit"
								className="btn-secondary w-50"
							>
								SAVE
							</button>
						</Row>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default MerchantSignupStep1;
