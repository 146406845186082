import React from "react";
import { Form, Checkbox } from "antd";

export default function CheckboxField(props) {
  return (
    <Form.Item name={props.name} label={props.label} rules={props.rules}>
      <Checkbox.Group>
        {props.options.map((option, key) => {
          return (
            <Checkbox value={option.value} key={key}>
              {option.desc}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
    </Form.Item>
  );
}
