import { Avatar, Card } from "antd";
import React from "react";

export default function CardSetting(props) {
  return (
    <Card
      style={{
        boxShadow: "var(--secondary-shadow)",
        borderLeft: "5px solid" + props.color,
        borderRadius: "5px",
        height: "100%",
        background: props.background,
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          {props.avatar ? (
            <Avatar size={34} src={props.avatar} />
          ) : (
            <Avatar size={34} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "1rem",
            }}
          >
            <strong style={{ marginBottom: "0px" }}>{props.name}</strong>
            <small className="muted-color">{props.detail}</small>
          </div>
        </div>
        {props.action ? props.action : ""}
      </div>
      <div>{props.desc}</div>
      <div>{props.priceDesc}</div>
    </Card>
  );
}
