import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag, message } from "antd";

export default function DatatableAdmin(props) {
	const columns = [
		{
			title: "No",
			key: "id",
			render: (value, item, index) =>
				(props.pagination.current - 1) * props.pagination.pageSize +
				index +
				1,
		},
		{
			title: "Username",
			dataIndex: "username",
			key: "username",
			sorter: (a, b) => a.username.localeCompare(b.username),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => a.email.localeCompare(b.email),
		},
		{
			title: "User Type",
			key: "role",
			dataIndex: "role",
			sorter: (a, b) => a.role.localeCompare(b.role),
			render: (_, { role }) => (
				<>
					<Tag
						color={role == "developer" ? "blue" : "green"}
						key={role}
					>
						{role.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (_, { status }) => (
				<>
					<Tag
						color={status == "Active" ? "green" : "red"}
						key={status}
					>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			sorter: (a, b) => a.created_at.localeCompare(b.created_at),
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							props.showAdminFormModal(record);
						}}
					></Button>
					<Button
						icon={<DeleteOutlined />}
						onClick={() => {
							props.handleRemoveRecord(record);
						}}
					></Button>
				</Space>
			),
		},
	];
	return (
		<>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={props.dataSource}
				pagination={props.pagination}
				loading={props.loading}
				onChange={props.handleTableChange}
			/>
		</>
	);
}
