import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Modal, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import UploadField from "../Global/FormInputComponent/UploadField";
import {
        createNewLanguage,
        updateLanguage
        } from '../../../actions/Admin/actionCreators';
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const LanguageFormModal = (props) => {
    const api = useContext(AxiosContext);
	const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [createLanguage, setCreateLanguage] = useState(true);
    // const [initValue, setInitValue] = useState({});
    const [switchStatus, setSwitchStatus] = useState(true);  
    const [importData, setImportData] = useState({
        dataList:[]
    })
    const initialValues = "A";
    let id = 0;

    const validateMessages = {
        required: "${label} is required!",
        types: {
          email: "${label} is not a valid email!",
        },
        validator: "Duplicate Data",
    };

    useEffect(() => {
        if (Object.keys(props.formValue).length != 0) {
            setCreateLanguage(0);
            console.log(props.formValue);
            form.setFieldsValue({
                locale: props.formValue.locale,
                code: props.formValue.code,
                name: props.formValue.name,
                seq_no: props.formValue.seq_no,
                avatar: props.formValue.avatar
            });

            if (props.formValue.avatar) {
				setImportData({ dataList: [{ url: props.formValue.avatar }] });
			}

            if (props.formValue.status == "Active") {
                setSwitchStatus(true);
            } else {
                setSwitchStatus(false);
            }

        }else{
            setCreateLanguage(1);
            form.resetFields();
        }
    }, [props.formValue]);

    const postNewLanguageData = async (param) => {
		const response = await createNewLanguage({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				message.success(response.message, 3);
				props.onCancel();
				props.fetchLanguageListData({});
			}
		}
	};

    const postUpdateLanguageData = async (param) => {
		const response = await updateLanguage({
			api,
			data: param,
            id: props.formValue.id,
			dispatch,
		});
        
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 10);
				props.onCancel();
                props.fetchLanguageListData({});
			}
		}
	};


    const onFinish = async (values) => {
		const formData = new FormData();

        formData.append("locale", props.formValue.locale);
        formData.append("code", values["code"]);
        formData.append("locale", values["locale"]);
        formData.append("name", values["name"]);
        formData.append("desc", values["desc"]);
        formData.append("seq_no", values["seq_no"]);

		if (switchStatus) {
			formData.append("status", "A");
		} else {
			formData.append("status", "I");
		}
        
        if (importData.dataList[0].name) {
            importData.dataList.forEach((file) => {
                formData.append("avatar", file);
            });
        }
		

		if (props.formValue.id) {
			formData.append("id", props.formValue.id);
			formData.append("_method", "PUT");
		}

		if (createLanguage) {
			postNewLanguageData(formData);
		} else {
			postUpdateLanguageData(formData);
		}
	};

    const switchOnChange = (checked) => {
        setSwitchStatus(checked);
    };

    return(
        <Modal title={createLanguage ? 'Language Form' : 'Language Edit Form'} visible={props.visible} centered onCancel={props.onCancel} footer={null} forceRender>
            <Form
                {...layout}
                form={form}
                name="general_data_form"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                validateMessages={validateMessages}
                scrollToFirstError
                initialValues={initialValues}
                validateTrigger="onBlur"
            >
                <TextField
                label="Locale"
                name="locale"
                placeholder="Locale"
                rules={[
                    { required: true, whitespace: true },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                label="Language Code"
                name="code"
                placeholder="Country Code"
                rules={[
                    { required: true, whitespace: true, length:3 },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                label="Name"
                name="name"
                placeholder="Name"
                rules={[
                    { required: true, whitespace: true, length:3 },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
					label="Seq. No."
					name="seq_no"
					placeholder="Example: 1"
					rules={[{ required: true }]}
					hasFeedback
				/>
                <UploadField 
                    setImportData={setImportData}
                    importData={importData}
                    label="Flag"
                    name="avatar"
                />
                {createLanguage ? (
					<>
					</>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={switchStatus}
						switchChange={switchOnChange}
						initialValue
					/>
				)}
                <div style={{textAlign: 'right', paddingTop: '2rem'}}>
                    <Button key="back" onClick={props.onCancel} style={{marginRight: '10px'}}>
                    Cancel
                    </Button>
                    <Button key="submit" type="primary" htmlType="submit" >
                        {createLanguage ? 'Submit' : 'Update' }
                    </Button>`
                </div>
            </Form>
        </Modal>
    )
}

export default LanguageFormModal;