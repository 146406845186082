import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag } from "antd";
import { Link } from "react-router-dom";

export default function DatatableMerchant(props) {
	const columns = [
		{
			title: "No",
			key: "id",
			render: (value, item, index) => index + 1,
		},
		{
			title: "Shop Name",
			dataIndex: "shop_name",
			key: "shop_name",
		},
		{
			title: "Mobile No.",
			dataIndex: "mobile_contact",
			key: "mobile_contact",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Type",
			key: "merchant_category_name",
			dataIndex: "merchant_category_name",
			// render: (_, { business_type }) => (
			// 	<>
			// 		<Tag
			// 			color={
			// 				business_type == "Hair Saloon" ? "blue" : "green"
			// 			}
			// 			key={business_type}
			// 		>
			// 			{business_type}
			// 		</Tag>
			// 	</>
			// ),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (_, { status }) => (
				<>
					<Tag
						color={status == "Active" ? "green" : "red"}
						key={status}
					>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Link to={`/admin/merchant-edit/${record.user_id}`}>
						<Button type="secondary" icon={<EditOutlined />} />
					</Link>
					<Button icon={<DeleteOutlined />} onClick={() => {}} />
				</Space>
			),
		},
	];
	return (
		<>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={props.dataSource}
				pagination={props.pagination}
				loading={props.loading}
				onChange={props.handleTableChange}
			/>
		</>
	);
}
