import * as action_types from "./actionTypes";
import * as action from "./action";

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const fetchTerritoryData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/member/territory`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const getStepDetails = async ({ data, booking_url, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/member/appointment/steps/${booking_url}`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const postClientInfos = async ({ data, param, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/member/appointment/${param.step_code}/${param.booking_url}`,
    method: `POST`,
    data,
  });

  return response.data;
};

// export const postClientServices = async ({ data, param, api }) => {
//   const response = await api({
//     url: `${API_BASE_URL}/member/appointment/${param.step_code}/${param.booking_url}`,
//     method: `POST`,
//     data,
//   });

//   return response.data;
// };

// export const postClientStaffs = async ({ data, param, api }) => {
//   const response = await api({
//     url: `${API_BASE_URL}/member/appointment/${param.step_code}/${param.booking_url}`,
//     method: `POST`,
//     data,
//   });

//   return response.data;
// };

export const getAvailableDates = async ({ data, booking_url, api }) => {
  console.log(data);
  const response = await api({
    url: `${API_BASE_URL}/member/appointment/dates/${booking_url}`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};
