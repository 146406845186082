import { HomeOutlined } from "@ant-design/icons";
import { Avatar, Col, Form, List, message, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import "../../../assets/css/Merchant/calendar.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faSliders } from "@fortawesome/free-solid-svg-icons";
import SelectField from "../Global/FormInputComponent/SelectField";
import interactionPlugin from "@fullcalendar/interaction";
import AppointmentForm from "../Form/Appointment/AppointmentForm";
import CalendarWeekModal from "../Modals/CalendarWeekModal";
import {
  fetchStaff,
  getAppointment,
  getAptDetails,
  updateAppointmentStatus,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";
import AppointmentEditForm from "../Form/Appointment/AppointmentEditForm";
import CalendarMonthModal from "../Modals/CalendarMonthModal";

library.add(fas, faSliders);

const MerchantDashboard = () => {
  const calendarRef = React.createRef();
  const date_today =
    new Date().getFullYear() +
    "-" +
    String(new Date().getMonth() + 1).padStart(2, "0") +
    "-" +
    String(new Date().getDate()).padStart(2, "0");
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [weekModalVisible, setWeekModalVisible] = useState(false);
  const [monthModalVisible, setMonthModalVisible] = useState(false);
  const [staff, setStaff] = useState([]);
  const [appoint, setAppoint] = useState([]);
  const [fnlAppoint, setFinalAppoint] = useState([]);
  const [date, setDate] = useState(date_today);
  const [aptDetails, setAptDetails] = useState([]);
  const [list, setList] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();

  const closeAppointment = () => {
    setVisible(false);
  };

  const closeEditAppointment = () => {
    setEditVisible(false);
  };

  const closeWeekModal = () => {
    setWeekModalVisible(false);
  };

  const closeMonthModal = () => {
    setMonthModalVisible(false);
  };

  let staff_options = [];
  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
    },
    {
      label: "Dashboard",
      link: "/merchant/dashboard",
    },
  ];

  const getStaff = async () => {
    const response = await fetchStaff({ api, dispatch });

    if (response) {
      response.map((stf, key) => {
        staff_options.push({
          merchant_staff_title_name: stf.merchant_staff_title_name,
          img_path: stf.img_path,
          desc: stf.name,
          value: stf.id,
        });
      });
      setStaff(staff_options);
    }
  };

  const handleFilterStaff = async (e) => {
    if (e.length > 0) {
      let array = [];
      e.map(async (stf_id, key) => {
        staff.map((stf, ind) => {
          if (stf.value == stf_id) {
            array[key] = staff[ind];
          }
        });

        setFilteredStaff(array);
        const response = await assignAppointment(stf_id, key, array);
        return response;
      });
    }
  };

  const assignAppointment = async (stf_id, key, array) => {
    let data = {};
    data["staff_id"] = stf_id;
    let response = await getAppointment({ data, api, dispatch });
    let appointment_array = [];
    if (response) {
      response.map(async (res, res_key) => {
        appointment_array.push({
          id: res.id,
          title: res.name,
          start: moment(res.booking_date).toISOString(),
          end: moment(res.booking_date)
            .add(res.total_duration, "minutes")
            .toISOString(),
        });
      });
    }
    array[key] = { ...array[key], appointment: appointment_array };
    setAppoint(array);
  };

  const getAppointmentDetails = async (id) => {
    let data = [];
    data["id"] = id;

    const response = await getAptDetails({ data, api, dispatch });

    if (response) {
      setAptDetails(response);
      setEditVisible(true);
    }
  };

  const getUpcomingAppointment = async () => {
    let data = {};
    data["future_limit"] = 10;
    // data["booking_date"] = moment().format("YYYY-MM-DD HH:mm:ss");
    let response = await getAppointment({ data, api, dispatch });

    if (response) {
      setList(response);
    }
  };

  const postUpdateApptStatus = async (data, id) => {
    const response = await updateAppointmentStatus({
      api,
      data: data,
      id: id,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      getUpcomingAppointment();
    }
  };

  const updateStatus = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const status = event.target.getAttribute("data-status");
    const formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("status", status);

    let key = event.target.getAttribute("data-ind");
    let id = list[key]["id"];

    postUpdateApptStatus(formData, id);
  };

  const editAppointment = (event) => {
    event.preventDefault();
    event.stopPropagation();

    let key = event.target.getAttribute("data-ind");
    let id = list[key]["id"];

    getAppointmentDetails(id);
  };

  const clickCalendar = function (evt) {
    evt.preventDefault();
    evt.stopPropagation();
    var selected_date =
      evt.target.parentElement.parentElement.getAttribute("data-date"); //selected date grab here
    var element = document.getElementsByClassName("fc-col-header-cell");
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("fc-day-today");
    }
    evt.target.parentElement.parentElement.classList.add("fc-day-today");
    setDate(selected_date);

    setAppoint([]);
    // let filter = form.getFieldValue("filter");
    form.setFieldValue("filter", []);
    // form.setFieldValue("filter", filter);
  };

  const calendarInit = () => {
    var calendarClick = document.getElementsByClassName(
      "fc-col-header-cell-cushion"
    );

    for (var i = 0; i < calendarClick.length; i++) {
      calendarClick[i].addEventListener("click", clickCalendar, false);
    }
  };

  useEffect(() => {
    getStaff();
    getUpcomingAppointment();
    calendarInit();
  }, []);

  useEffect(() => {
    setFinalAppoint(appoint);
  }, [appoint]);

  return (
    <>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Dashboard</strong>
        </Title>
        <BreadcrumbLayout items={breadcrumbItem} />
      </div>
      <div style={{ margin: "0 25px" }}>
        <div
          className="custom-calendar"
          style={{ marginBottom: "5rem", position: "relative" }}
        >
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="myCustomDayButton"
            slotMinTime="00:00:00"
            slotMaxTime="00:00:00"
            allDaySlot={false}
            contentHeight="100%"
            timeZone="GMT"
            dayHeaderFormat={{
              weekday: "short",
              day: "numeric",
              omitCommas: true,
            }}
            views={{
              myCustomDayButton: {
                type: "timeGrid",
                duration: { days: window.innerWidth < 500 ? 3 : 14 },
                buttonText: "Days",
              },
            }}
            headerToolbar={{
              left: "prev title next",
              center: "",
              right: "myCustomDayButton myCustomWeekButton myCustomMonthButton",
            }}
            titleFormat={{
              year: "numeric",
              month: "long",
            }}
            customButtons={{
              myCustomWeekButton: {
                text: "Week",
                click: function () {
                  setWeekModalVisible(true);
                  // setTimeView("timeGridWeek");
                },
              },
              myCustomMonthButton: {
                text: "Month",
                click: function () {
                  setMonthModalVisible(true);
                  // setTimeView("dayGridMonth");
                },
              },
            }}
          />
        </div>
        <hr style={{ marginBottom: "1rem" }} />
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <div className="filter-container">
              <FontAwesomeIcon icon="fa-solid fa-sliders" />
              <Form form={form} style={{ width: "100%" }}>
                <SelectField
                  mode="multiple"
                  name="filter"
                  className="merchant-input-no-border bg-transparent"
                  options={staff}
                  placeholder="Filter Stylist"
                  multiple
                  onChange={handleFilterStaff}
                  id="filter-staff"
                />
              </Form>
            </div>
          </Col>
        </Row>
        <div
          className="custom-calendar-timeline"
          style={{
            marginBottom: "5rem",
            position: "relative",
            display: "flex",
            flex: "1 1 auto",
            overflow: "auto",
          }}
        >
          {fnlAppoint
            ? fnlAppoint.map((apt, key) => {
                return (
                  <div
                    key={key}
                    className={key != 0 ? "without-slot" : ""}
                    style={{ marginTop: "1rem" }}
                  >
                    <Row
                      style={{
                        border: "1px solid #D3DEC3",
                        background: "#fff",
                        borderTopLeftRadius: key == 0 ? "10px" : "none",
                      }}
                    >
                      {key == 0 ? <div style={{ width: "80px" }}></div> : ""}
                      <div
                        style={{
                          width: key == 0 ? "calc(100% - 80px)" : "100%",
                          borderLeft: "1px solid #D3DEC3",
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: "15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            size={34}
                            src={apt.img_path ? apt.img_path : ""}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: "1rem",
                            }}
                          >
                            <strong style={{ marginBottom: "0px" }}>
                              {apt.desc}
                            </strong>
                            <small className="muted-color">
                              {apt.merchant_staff_title_name}
                            </small>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <FullCalendar
                      ref={calendarRef}
                      slotMinWidth="100"
                      height={575}
                      windowResizeDelay={100}
                      slotMinTime="09:00:00"
                      slotMaxTime="21:00:00"
                      allDaySlot={false}
                      dayHeaders={false}
                      plugins={[timeGridPlugin, interactionPlugin]}
                      initialView="timeGridDay"
                      // editable={true}
                      // droppable={true}
                      selectable={true}
                      initialDate={date}
                      select={function (info) {
                        setVisible(true);
                        setSelectedStaff(filteredStaff[key]?.value);
                      }}
                      slotLabelFormat={{
                        hour: "numeric",
                      }}
                      headerToolbar={{
                        left: "",
                        center: "",
                        right: "",
                      }}
                      events={apt.appointment}
                      eventClick={(info) => {
                        info.jsEvent.preventDefault();
                        getAppointmentDetails(info.event.id);
                      }}
                    />
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Upcoming Appointment</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <List
          className="contact-list"
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item, key) => (
            <>
              {key == 0 ? (
                <div className="time-interval">
                  <p>{moment(item.booking_date).format("YYYY-MM-DD")}</p>
                </div>
              ) : moment(item.booking_date).format("YYYY-MM-DD") !=
                moment(list[key - 1]["booking_date"]).format("YYYY-MM-DD") ? (
                <div className="time-interval">
                  <p>{moment(item.booking_date).format("YYYY-MM-DD")}</p>
                </div>
              ) : (
                ""
              )}

              <List.Item
                className="bg-white appointment-list"
                style={{
                  padding: "10px 15px",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 10px #0000001A",
                  marginBottom: "1rem",
                }}
              >
                <List.Item.Meta
                  title={
                    <div className="appointment-title">
                      <div className="appointment-contact">
                        <span>{item.name}</span>
                        <span style={{ marginRight: "2rem" }}>
                          {item.mobile_prefix}
                          {item.mobile_no}
                        </span>
                      </div>
                      {item.staff_name ? (
                        <div>
                          <Avatar size={28} style={{ marginRight: "5px" }} />
                          <strong>{item.staff_name}</strong>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                />
                <a
                  className="client-contact-button"
                  style={{ padding: "0px" }}
                  href={`https://wa.me/${item.mobile_prefix}${
                    item.mobile_no
                  }?text=${encodeURI(
                    "Hi, we are from " + localStorage.getItem("merchant_name")
                  )}`}
                  target="_blank"
                >
                  <img
                    src="/icons/whatsapp.png"
                    style={{
                      marginLeft: "7px",
                      width: "26px",
                      height: "26px",
                    }}
                    alt="Minus Whatsapp"
                  />
                </a>
                {item.status == "COM" ? (
                  <div>
                    <button
                      className="btn-clear client-contact-button"
                      disabled
                      data-ind={key}
                    >
                      Completed
                    </button>
                  </div>
                ) : (
                  <>
                    <div>
                      <button
                        className="btn-clear client-contact-button"
                        onClick={editAppointment}
                        data-ind={key}
                      >
                        <img
                          src="/icons/icon-edit.svg"
                          alt="Edit Icon"
                          data-ind={key}
                        />
                      </button>
                    </div>
                    {item?.status != "F" && item?.status != "CNL" ? (
                      <div>
                        <button
                          className="btn-clear client-contact-button"
                          data-status="F"
                          onClick={updateStatus}
                          data-ind={key}
                        >
                          <img
                            src="/icons/icon-airplane.svg"
                            alt="Icon Airplane"
                            data-status="F"
                            data-ind={key}
                          />
                        </button>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {item?.status == "CNL" ? (
                      <div>
                        <button
                          className="btn-clear client-contact-button"
                          disabled
                          style={{
                            color: "#ffffff",
                            background: "#FE6C20",
                            borderColor: "#FE6C20",
                          }}
                          data-ind={key}
                        >
                          Cancelled
                        </button>
                      </div>
                    ) : item?.status == "F" ? (
                      <div>
                        <button
                          className="btn-clear client-contact-button"
                          disabled
                          style={{
                            color: "#ffffff",
                            background: "#FE6C20",
                            borderColor: "#FE6C20",
                          }}
                          data-ind={key}
                        >
                          Failed
                        </button>
                      </div>
                    ) : (
                      <>
                        <div>
                          <button
                            className="btn-clear client-contact-button"
                            style={{
                              color: "#FE6C20",
                              borderColor: "#FE6C20",
                            }}
                            data-status="CNL"
                            onClick={updateStatus}
                            data-ind={key}
                          >
                            Cancel appt.
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn-secondary client-contact-button"
                            data-status="COM"
                            onClick={updateStatus}
                            data-ind={key}
                          >
                            complete
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </List.Item>
            </>
          )}
        />
      </div>
      <AppointmentForm
        visible={visible}
        action={closeAppointment}
        filteredStaff={filteredStaff}
        selectedStaff={selectedStaff}
        staff={staff}
        date={date}
        handleFilterStaff={handleFilterStaff}
      />
      <AppointmentEditForm
        visible={editVisible}
        action={closeEditAppointment}
        data={aptDetails}
      />
      <CalendarWeekModal
        visible={weekModalVisible}
        onCancel={closeWeekModal}
        setWeekModalVisible={setWeekModalVisible}
        setMonthModalVisible={setMonthModalVisible}
      />
      <CalendarMonthModal
        visible={monthModalVisible}
        onCancel={closeMonthModal}
        setWeekModalVisible={setWeekModalVisible}
        setMonthModalVisible={setMonthModalVisible}
      />
    </>
  );
};

export default MerchantDashboard;
