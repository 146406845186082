import { DownOutlined } from "@ant-design/icons";
import { Col, message, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchHolidayList,
  fetchStaffTitle,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import AddHolidayModal from "../Modals/AddHolidayModal";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";
import moment from "moment";

const { Title } = Typography;

const StylistLevel = (props) => {
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [fpwvisible, setFpwVisible] = useState(false);
  const [addHolidayvisible, setAddHolidayVisible] = useState(false);
  const [stylistLvl, setStylistLvl] = useState(1);
  const [staffTitle, setStaffTitle] = useState([]);
  const [holiday, setHoliday] = useState([]);

  const fpwOnCancel = () => {
    setFpwVisible(false);
  };
  const fpwModalOnClick = () => {
    setFpwVisible(true);
  };
  const holidayOnCancel = () => {
    setAddHolidayVisible(false);
  };
  const holidayOnClick = () => {
    setAddHolidayVisible(true);
  };
  let stylistLvlEle = [];
  const addStylistLvl = () => {
    setStylistLvl(stylistLvl + 1);
  };

  const appendStylistLvl = () => {
    for (let index = 0; index < stylistLvl; index++) {
      stylistLvlEle.push(
        <div key={index}>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={addStylistLvl}
          >
            <img
              src="/icons/btn-add.svg"
              style={{ width: 27, height: 27 }}
            ></img>
            <input
              className="add-input"
              style={{ marginLeft: "1rem" }}
              size="large"
              placeholder="Add"
            />
          </div>
          <hr style={{ margin: "1rem 0" }} />
        </div>
      );
    }
  };

  const getStaffTitle = async () => {
    const response = await fetchStaffTitle({ api, dispatch });

    if (response) {
      setStaffTitle(response);
    }
  };

  const removeTitle = (event) => {
    let index = event.target.getAttribute("data-ind");

    var array = [...staffTitle];
    if (index !== -1) {
      array.splice(index, 1);
      setStaffTitle(array);
    }
  };

  const addTitle = (event) => {
    var array = [...staffTitle];
    var title_input = document.getElementById("title-input").value;

    if (title_input) {
      array.push({
        name: title_input,
      });
      setStaffTitle(array);
      document.getElementById("title-input").value = "";
    } else {
      message.error("Please fill in stylist level before add");
    }
  };

  const getHolidayList = async () => {
    const response = await fetchHolidayList({ api, dispatch });
    if (response) {
      setHoliday(response);
    }
  };

  appendStylistLvl();
  useEffect(() => {
    appendStylistLvl();
  }, [stylistLvl]);

  useEffect(() => {
    getStaffTitle();
    getHolidayList();
  }, []);

  return (
    <>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Create Stylist Level</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        {staffTitle.length > 0
          ? staffTitle.map((title, index) => {
              return (
                <div key={index}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/icons/btn-minus.svg"
                      style={{ width: 27, height: 27 }}
                      onClick={removeTitle}
                      data-ind={index}
                    ></img>
                    <span style={{ marginLeft: "1rem" }}>{title.name}</span>
                  </div>
                  <hr style={{ margin: "1rem 0" }} />
                </div>
              );
            })
          : ""}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/icons/btn-add.svg"
            style={{ width: 27, height: 27 }}
            onClick={addTitle}
          ></img>
          <input
            className="add-input"
            style={{ marginLeft: "1rem" }}
            size="large"
            placeholder="Add"
            id="title-input"
          />
        </div>
        <hr style={{ margin: "1rem 0" }} />
      </div>
      <div className="merchant-content-header" style={{ marginTop: "2rem" }}>
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Login Setting</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <Row justify="space-between" className="header-color">
          <Col span={12}>Login ID</Col>
          <Col className="font-color" span={12} style={{ textAlign: "end" }}>
            {props.mobile_no}
          </Col>
        </Row>
        <hr style={{ margin: "1rem 0" }} />
        <Row justify="space-between" className="header-color">
          <Col span={12}>PASSWORD</Col>
          <Col
            span={12}
            style={{ textAlign: "end", cursor: "pointer" }}
            onClick={fpwModalOnClick}
          >
            CHANGE
          </Col>
        </Row>
        <hr style={{ margin: "1rem 0" }} />
      </div>
      <div className="merchant-content-header" style={{ marginTop: "2rem" }}>
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Holiday Setting</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <Row gutter={20}>
          <Col xs={24} md={12} style={{ marginBottom: "5px" }}>
            <Link to="/merchant/company-set-holiday">
              <button
                htmltype="button"
                className="btn-secondary w-100"
                style={{ padding: "6px" }}
              >
                Set Holiday
              </button>
            </Link>
          </Col>
          <Col xs={24} md={12}>
            <button
              htmltype="button"
              className="btn-clear w-100"
              style={{ padding: "6px" }}
            >
              <div
                onClick={holidayOnClick}
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <img
                  src="/icons/btn-add.svg"
                  style={{ width: 20, height: 20 }}
                ></img>
                Add Holiday
              </div>
            </button>
          </Col>
        </Row>
        <hr style={{ margin: "1rem 0" }} />

        {holiday.length > 0
          ? holiday.map((hld, key) => {
              return (
                <div key={key}>
                  <Row justify="space-between" className="font-color">
                    <Col span={12}>
                      {moment(hld.date_start, "YYYY-MM-DD").format("DD MMM")}
                    </Col>
                    <Col span={12} style={{ textAlign: "end" }}>
                      {hld.description}
                    </Col>
                  </Row>
                  <hr style={{ margin: "1rem 0" }} />
                </div>
              );
            })
          : ""}
        <Link to="/merchant/company-set-holiday">
          <Row className="header-color" style={{ alignItems: "center" }}>
            <DownOutlined style={{ paddingRight: "1rem" }} />
            More
          </Row>
        </Link>
      </div>
      <ForgotPasswordModal visible={fpwvisible} onCancel={fpwOnCancel} />
      <AddHolidayModal visible={addHolidayvisible} onCancel={holidayOnCancel} />
    </>
  );
};

export default StylistLevel;
