import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Modal, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import {
	fetchUserRoleData,
	fetchAdminUserDetail,
	createAdminUser,
	updateAdminUser,
} from "../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

const AdminFormModal = (props) => {
	const [form] = Form.useForm();
	const [createAdmin, setCreateAdmin] = useState(true);
	const [rolesOption, setRolesOption] = useState([]);
	const [switchStatus, setSwitchStatus] = useState(true);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const initialValues = {};
	let id = 0;

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		string: {
			min: "${label} must be at least ${min} characters",
		},
		validator: "Duplicate Data",
	};

	const fetchUserRoleListData = async () => {
		const param = {};
		const response = await fetchUserRoleData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));
			setRolesOption(transformed);
		}
	};

	const fetchUserDetailData = async (id) => {
		const param = {};
		param["id"] = id;

		const response = await fetchAdminUserDetail({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			var selected_option = rolesOption.filter(
				(val) => val.desc === props.formValue.role
			);

			form.setFieldsValue({
				username: response.username,
				role: selected_option[0].value,
				email: response.email,
				name: response.name,
				mobile_no: response.mobile_no,
			});
			if (response.status == "Active") {
				setSwitchStatus(true);
			} else {
				setSwitchStatus(false);
			}
		}
	};

	const postNewUserData = async (param) => {
		const response = await createAdminUser({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				props.fetchAdminUserListData(props.formValue);
				message.success(response.message, 5).then(props.onCancel());
			}
		}
	};

	const postUpdateUserData = async (param) => {
		const response = await updateAdminUser({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				props.fetchAdminUserListData(props.formValue);
				message.success(response.message, 5).then(props.onCancel());
			}
		}
	};

	const onFinish = async (values) => {
		if (props.formValue.id) {
			values["id"] = props.formValue.id;
			if (switchStatus) {
				values["status"] = "A";
			} else {
				values["status"] = "I";
			}
		}

		if (createAdmin) {
			postNewUserData(values);
		} else {
			postUpdateUserData(values);
		}
	};

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	const handleChangeRole = (selected) => {
		console.log(selected);
	};

	useEffect(() => {
		fetchUserRoleListData();
		if (Object.keys(props.formValue).length != 0) {
			setCreateAdmin(0);
			id = props.formValue.id;
			fetchUserDetailData(id);
		} else {
			setCreateAdmin(1);
			form.resetFields();
		}
	}, [props.formValue]);

	return (
		<Modal
			title={createAdmin ? "User Form" : "User Edit Form"}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			forceRender
		>
			<Form
				{...layout}
				form={form}
				name="user_form"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				validateMessages={validateMessages}
				scrollToFirstError
				initialValues={initialValues}
				validateTrigger="onBlur"
			>
				<SelectField
					label="User Role"
					name="role"
					placeholder="Select User Role"
					onChange={handleChangeRole}
					options={rolesOption}
					rules={[{ type: "", required: true }]}
					hasFeedback
				/>
				<TextField
					label="Username"
					name="username"
					placeholder="Username"
					rules={[
						{ required: true, whitespace: true, min: 6 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Name"
					name="name"
					placeholder="Name"
					rules={[{ required: true, whitespace: true, min: 6 }]}
					hasFeedback
				/>
				<TextField
					label="Email"
					name="email"
					placeholder="Email"
					type="email"
					rules={[
						{ type: "email", required: true },
						{
							validator: async (rule, value) => {
								if (value) {
									const param = { id: id, email: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Mobile No."
					name="mobile_no"
					placeholder="Example: 60123456789"
					rules={[
						{ required: true },
						{
							validator: async (rule, value) => {
								if (value) {
									const param = { id: id, email: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				{createAdmin ? (
					<>
						<TextField
							label="Password"
							name="password"
							placeholder="Password"
							type="password"
							autoComplete="new-password"
							rules={[{ required: true }, { min: 6 }]}
							iconRender={(visible) =>
								visible ? (
									<EyeTwoTone />
								) : (
									<EyeInvisibleOutlined />
								)
							}
							hasFeedback
						/>
						<TextField
							label="Confirm Password"
							name="password_confirmation"
							placeholder="Confirm Password"
							type="password"
							dependencies={["password"]}
							autoComplete="new-password"
							iconRender={(visible) =>
								visible ? (
									<EyeTwoTone />
								) : (
									<EyeInvisibleOutlined />
								)
							}
							hasFeedback
							rules={[
								{ required: true },
								{ min: 6 },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (
											!value ||
											getFieldValue("password") === value
										) {
											return Promise.resolve();
										}
										return Promise.reject(
											new Error(
												"The two passwords that you entered do not match!"
											)
										);
									},
								}),
							]}
						/>
					</>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={switchStatus}
						switchChange={switchOnChange}
						initialValue
					/>
				)}
				<div style={{ textAlign: "right", paddingTop: "2rem" }}>
					<Button
						key="back"
						onClick={props.onCancel}
						style={{ marginRight: "10px" }}
					>
						Cancel
					</Button>
					<Button key="submit" type="primary" htmlType="submit">
						{createAdmin ? "Submit" : "Update"}
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default AdminFormModal;
