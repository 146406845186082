import {
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createHoliday } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import TextAreaField from "../Global/FormInputComponent/TextAreaField";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const AddHolidayModal = (props) => {
  const [descLength, setDescLength] = useState(0);
  const [descValue, setDescVal] = useState("");
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();

  function triggerInput(enteredName, enteredValue) {
    const input = document.getElementsByClassName(enteredName);
    const lastValue = input[0].value;
    input[0].value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = input[0]._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input[0].dispatchEvent(event);
  }

  const onFinish = (values) => {
    if (values["date"][0]) {
      values["date_start"] = moment(values["date"][0]).format("YYYY-MM-DD");
    }

    if (values["date"][1]) {
      values["date_end"] = moment(values["date"][1]).format("YYYY-MM-DD");
    }

    postHolidayForm(values);
  };

  const postHolidayForm = async (data) => {
    const response = await createHoliday({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      props.onCancel();
      form.resetFields();
      setDescVal("");
      triggerInput("description", "");
    }
  };

  const onFinishFailed = () => {};
  const [form] = Form.useForm();
  const dateFormat = "YYYY/MM/DD";
  const descOnChange = (event) => {
    setDescLength(event.target.value.length);
    setDescVal(event.target.value);
  };

  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };

  useEffect(() => {
    form.setFieldValue("description", descValue);
  }, [descValue]);

  return (
    <Modal
      title={null}
      open={props.visible}
      centered
      onCancel={props.onCancel}
      footer={null}
      forceRender
      className="fpw-modal text-center"
      width={427}
      bodyStyle={{ padding: "30px" }}
    >
      <Title level={4} style={{ marginBottom: "2rem" }}>
        <strong>Add Holiday</strong>
      </Title>
      <Form
        name="addHolidayForm"
        initialValues={{}}
        validateMessages={validateMessages}
        scrollToFirstError
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              className="merchant-form-item"
              name="date"
              rules={[{ required: true }]}
              hasFeedback
            >
              <RangePicker
                format={dateFormat}
                allowEmpty={[false, true]}
                status
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextAreaField
              mainClassName="merchant-form-item desc-textarea"
              name="description"
              placeholder="Enter Description"
              maxLength={60}
              onChange={descOnChange}
              autoSize={{
                minRows: 3,
                maxRows: 3,
              }}
              extra_desc={<div className="desc-length">{descLength}/60</div>}
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
              value={descValue}
            />
          </Col>
        </Row>

        <button htmltype="submit" className="w-100 btn-secondary">
          Submit
        </button>
      </Form>
    </Modal>
  );
};

export default AddHolidayModal;
