import {
    HomeOutlined,
    PlusOutlined,
    RedoOutlined,
    SearchOutlined,
    UserAddOutlined,
	  ExclamationCircleOutlined,
  } from "@ant-design/icons";
  import { Button, Divider, Form, Modal, message } from "antd";
  import React, { useState, useEffect, useContext } from "react";
  import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
  import { library } from "@fortawesome/fontawesome-svg-core";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { fas, faGear } from "@fortawesome/free-solid-svg-icons";

// import DatatableLanguage from '../../Datatable/Dt-language';
// import DatatableGeneralData from '../../Datatable/Dt-generalData';
import DatatableCountry from '../../Datatable/Dt-country';
import AdvSearchComponent from '../../Global/SearchComponent/Dt-adv-search';
import { Link } from 'react-router-dom';
import TextField from '../../Global/FormInputComponent/TextField';
import SelectField from '../../Global/FormInputComponent/SelectField';
import CountryFormModal from '../../Modals/CountryFormModal';
import { getLanguageList,
         fetchSysGeneralData,
         fetchTerritoryList,
         deleteTerritory,
      } from '../../../../actions/Admin/actionCreators';
import { AxiosContext } from '../../../../actions/Admin/Axios/axios';
import { useDispatch } from "react-redux";

library.add(fas, faGear);

const breadcrumbItem = [
  {
    label: "",
    link: "/admin/dashboard",
    icon: <HomeOutlined />,
  },
  {
    label: "General Settings",
    icon: <FontAwesomeIcon icon="fa-solid fa-gear" />,
  },
  {
    label: "Country Listing",
    link: "/admin/country-list",
  },
];


const CountryList = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
	const [statusSource, setStatusSource] = useState([]);
  const [languageOption, setLanguageOption] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ 
    current: 1, 
    pageSize: 50, });
  const api = useContext(AxiosContext);
	const dispatch = useDispatch();
  const [sorting, setSorting] = useState({
    sortField: "id",
    sortOrder: "DESC",
  });
  const [countryModalVisible, setCountryModalVisible] = useState(false);

  useEffect(() => {
    let values = form.getFieldsValue();
    fetchLanguageListData();
    fetchGeneralData();
    fetchTerritoryListData(values);
  }, [pagination.current]);
  
  const fetchTerritoryListData = async (values) => {
		const param = {};
		param["page"] = pagination.current;
		param["per_page"] = pagination.pageSize;
		param["page_length"] = pagination.pageSize;
    param["territory_type"] = "country";
		param["name"] = values.search_name;
		param["prefer_language_code"] = values.search_prefer_language_code;
		param["status"] = values.search_status;
		param["calling_no_prefix"] = values.search_calling_no_prefix;

		setLoading(true);

		const response = await fetchTerritoryList({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			setDataSource(response.page_items);
      console.log(response)
		}

    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: response.total_items,
    });
	};

  const fetchGeneralData = async () => {
		const param = {};
		param["type"] = "general_status";
		param["status"] = "A";

		const response = await fetchSysGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));

			setStatusSource(transformed);
		}
	};

  const fetchLanguageListData = async () => {
    const param = {};
    param["status"] = "A";

    const response = await getLanguageList({
      api,
      data: param,
      dispatch,
    });

  if (response) {
    const transformed = response.map(({ id, name, code }) => ({
      value: code,
      desc: code,
    }));
    setLanguageOption(transformed);
  }
  };
    

  const postDeleteTerritoryData = async (id) => {
		const param = {};
		param["id"] = id;

		setLoading(true);
		const response = await deleteTerritory({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			if (response.code === 200) {
				message
					.success(response.message, 1)
					.then(fetchTerritoryListData({}));
			}
		}
	};

  const showCountryFormModal = (record) => {
    setCountryModalVisible(true);
    if (record) {
      setFormValue(record);
    } else {
      setFormValue({});
    }
  };

  const onCountryModalCancel = () => {
    setCountryModalVisible(false);
  };
  
	const handleRemoveRecord = (record) => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to remove ${record.name}?`,
			okText: "Yes",
			cancelText: "No",

			onOk() {
				postDeleteTerritoryData(record.id);
			},
		});
	};

  const handleTableChange = (newPagination, filters, sorter) => {
    let order = "ASC";

    if (sorter.order == "descend") {
      order = "DESC";
    }

    setSorting({
      sortField: sorter.column ? sorter.field : "id",
      sortOrder: sorter.order ? order : "DESC",
    });

		setPagination({
			current: newPagination.current,
			pageSize: newPagination.pageSize,
			total: newPagination.total,
		});
  };

  const onFinish = (values) => {
    fetchTerritoryListData(values);
  };




  const searchField = [
    {
      input: <TextField name="search_name" placeholder="Country Name" />,
    },
    {
      input: <TextField name="search_calling_no_prefix" placeholder="Prefix" />,
    },
    {
      input: (
        <SelectField placeholder="Preferred Language" name="search_prefer_language_code" options={languageOption} />
      ),
    },
    {
      input: (
        <SelectField placeholder="Status" name="search_status" options={statusSource} />
      ),
    },
  ];

  const searchFieldButton = [
    {
      button: (
        <Button
          type="secondary"
          icon={<PlusOutlined />}
          style={{ width: "100%" }}
          onClick={() => {
            showCountryFormModal();
          }}
        >
          Add New
        </Button>
      ),
    },
    {
      button: (
        <Button
        type="primary"
        htmlType="submit"
        icon={<SearchOutlined />}
        style={{ width: "100%" }}
        >
        Search
        </Button>
      ),
    },
    {
      button: (
        <Button
        type="danger"
        style={{ width: "100%" }}
        icon={<RedoOutlined />}
        onClick={() => {
          form.resetFields();
						fetchTerritoryListData({});
        }}
        >
        Reset
        </Button>
      ),
    }
  ];
    
  return(
    <>
      <BreadcrumbLayout items={breadcrumbItem} />
      <Divider />
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
      <AdvSearchComponent
        field={searchField}
        buttonField={searchFieldButton}
      />
      </Form>
      <Divider dashed />
      <DatatableCountry
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
        handleTableChange={handleTableChange}
				handleRemoveRecord={handleRemoveRecord}
        showCountryFormModal={showCountryFormModal} 
      />
      <CountryFormModal 
        visible={countryModalVisible} 
        onCancel={onCountryModalCancel} 
				handleRemoveRecord={handleRemoveRecord}
        formValue={formValue}
        fetchTerritoryListData={fetchTerritoryListData}
      />
    </>
  )
};

export default CountryList;
