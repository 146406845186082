import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tag } from "antd";

export default function DatatableMerchantProduct(props) {
	const columns = [
		{
			title: "No",
			key: "id",
			render: (value, item, index) =>
				(props.pagination.current - 1) * props.pagination.pageSize +
				index +
				1,
		},
		{
			title: "Shop Name",
			dataIndex: "company_name",
			key: "company_name",
			sorter: (a, b) => a.company_name.localeCompare(b.company_name),
		},
		{
			title: "Service Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Service Time",
			dataIndex: "duration",
			key: "duration",
			sorter: (a, b) => a.duration.localeCompare(b.duration),
			render: (_, record) => (
				<Space align="center">
					<span>
						{record.duration} {record.duration_type}
					</span>
				</Space>
			),
		},
		{
			title: "Type",
			dataIndex: "business_category",
			key: "business_category",
			sorter: (a, b) =>
				a.business_category.localeCompare(b.business_category),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: (_, { status }) => (
				<>
					<Tag
						color={status == "Active" ? "green" : "red"}
						key={status}
					>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			sorter: (a, b) => a.created_at.localeCompare(b.created_at),
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							props.showMerchantProductFormModal(record);
						}}
					></Button>
					<Button
						icon={<DeleteOutlined />}
						onClick={() => {
							props.handleRemoveRecord(record);
						}}
					></Button>
				</Space>
			),
		},
	];
	return (
		<>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={props.dataSource}
				pagination={props.pagination}
				loading={props.loading}
				onChange={props.handleTableChange}
			/>
		</>
	);
}
