import Dashboard from "../../components/Admin/Dashboard/Dashboard";
import AdminList from "../../components/Admin/Listings/Admin/AdminList";
import LanguageList from "../../components/Admin/Listings/General/LanguageList";
import GeneralDataList from "../../components/Admin/Listings/General/GeneralDataList";
import Login from "../../components/Admin/Login/Login";
import MerchantList from "../../components/Admin/Listings/Merchant/MerchantList";
import MerchantCategoryList from "../../components/Admin/Listings/Merchant/MerchantCategoryList";
import MerchantProductList from "../../components/Admin/Listings/Product/MerchantProductList";
import MerchantForm from "../../components/Admin/Form/MerchantForm";
import MenuSetup from "../../components/Admin/Listings/Menu/MenuSetup";
import CountryList from "../../components/Admin/Listings/General/CountryList";
import MerchantStaffList from "../../components/Admin/Listings/Merchant/MerchantStaffList";
import RoleSetup from "../../components/Admin/Listings/Role/RoleSetup";

const routes = [
	{
		path: "/admin",
		exact: true,
		auth: false,
		element: <Login />,
	},
	{
		path: "/admin/dashboard",
		exact: true,
		auth: true,
		element: <Dashboard />,
	},
	//Admin Module
	{
		path: "/admin/admin-list",
		exact: true,
		auth: true,
		element: <AdminList />,
	},
	//User Role Module
	{
		path: "/admin/user-role-setup",
		exact: true,
		auth: true,
		element: <RoleSetup />,
	},
	{
		path: "/admin/language-list",
		exact: true,
		auth: true,
		element: <LanguageList />,
	},
	{
		path: "/admin/general-data-list",
		exact: true,
		auth: true,
		element: <GeneralDataList />,
	},
	{
		path: "/admin/menu-setup",
		exact: true,
		auth: true,
		element: <MenuSetup />,
	},
	{
		path: "/admin/country-list",
		exact: true,
		auth: true,
		element: <CountryList />,
	},
	//merchant module
	{
		path: "/admin/merchant-list",
		exact: true,
		auth: true,
		element: <MerchantList />,
	},
	{
		path: "/admin/merchant-service-list",
		exact: true,
		auth: true,
		element: <MerchantProductList />,
	},
	{
		path: "/admin/merchant-category-list",
		exact: true,
		auth: true,
		element: <MerchantCategoryList />,
	},
	{
		path: "/admin/merchant-create",
		exact: true,
		auth: true,
		element: <MerchantForm />,
	},
	{
		path: "/admin/merchant-edit/:id",
		exact: true,
		auth: true,
		element: <MerchantForm />,
	},
	{
		path: "/admin/merchant-staff-list",
		exact: true,
		auth: true,
		element: <MerchantStaffList />,
	},
];

export default routes;
