import { Button, Card, Col, Form, Row, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SwitchField from "../../Global/FormInputComponent/SwitchField";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import {
	fetchUserRoleData,
	createRoleUser,
	updateRoleUser,
} from "../../../../actions/Admin/actionCreators";
import DragTree from "./RoleTree";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const RoleSetup = () => {
	const [form] = Form.useForm();
	const [switchStatus, setSwitchStatus] = useState(true);
	const [selectedKey, setSelectedKey] = useState();
	const [parentId, setParentId] = useState("");
	const [rolesOption, setRolesOption] = useState([]);
	const [reloadTree, setReloadTree] = useState(false);
	const [formValue, setFormValue] = useState({});
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const initialValues = {};
	const encrypted_parent_id = "developer";

	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		validator: "Duplicate Data",
	};

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	const handleChange = (selectedValue, event) => {
		setParentId(selectedValue);
	};

	useEffect(() => {
		fetchUserRoleListData();
		form.setFieldsValue({
			name: formValue.name,
			parent_id: formValue.parent_id,
			seq_no: formValue.seq_no,
			parent_name: formValue.parent_name,
			guard_name: formValue.guard_name,
		});
		setSelectedKey(formValue.id);
		if (formValue.status === "A") {
			setSwitchStatus(true);
		} else {
			setSwitchStatus(false);
		}
	}, [formValue]);

	const onFinish = async (values) => {
		if (switchStatus) {
			values["status"] = "A";
		} else {
			values["status"] = "I";
		}

		if (parentId) {
			values["parent_id"] = parentId;
		} else {
			values["parent_id"] = formValue.parent_id;
		}

		if (selectedKey != 0) {
			values["id"] = selectedKey;
			postUpdateRoleData(values);
		} else {
			postNewRoleData(values);
		}
	};

	const fetchUserRoleListData = async () => {
		const param = {};
		const response = await fetchUserRoleData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ id, name }) => ({
				value: id,
				desc: name,
			}));
			setRolesOption(transformed);
		}
	};

	const postNewRoleData = async (param) => {
		const response = await createRoleUser({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 5);
				setReloadTree(true);
				setParentId("");
				form.resetFields();
			}
		}
	};

	const postUpdateRoleData = async (param) => {
		const response = await updateRoleUser({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 5);
				setReloadTree(true);
				setParentId("");
				form.resetFields();
			}
		}
	};

	return (
		<Row gutter={16}>
			<Col span={12}>
				<Card title="User Role List" className="h-100">
					<DragTree
						setFormValue={setFormValue}
						form={form}
						encrypted_parent_id={encrypted_parent_id}
						reloadTree={reloadTree}
						setReloadTree={setReloadTree}
					/>
				</Card>
			</Col>
			<Col span={12}>
				<Card title="User Role Form" className="h-100">
					<Form
						{...layout}
						form={form}
						name="advanced_search"
						className="ant-advanced-search-form"
						onFinish={onFinish}
						validateMessages={validateMessages}
						scrollToFirstError
						initialValues={initialValues}
						validateTrigger="onBlur"
					>
						<TextField
							label="Login Session"
							name="guard_name"
							placeholder="Login Session"
							hasFeedback
							disabled={true}
						/>
						<SelectField
							label="Parent Name"
							name="parent_name"
							placeholder="Select User Role"
							options={rolesOption}
							onChange={handleChange}
							hasFeedback
						/>
						<TextField
							label="Name"
							name="name"
							placeholder="Role Name"
							rules={[{ required: true, whitespace: true }]}
							hasFeedback
						/>
						<TextField
							label="Seq. No."
							name="seq_no"
							placeholder="Seq. No."
							rules={[{ required: true }]}
							hasFeedback
							type="number"
						/>
						<SwitchField
							label="Status"
							name="status"
							switchStatus={switchStatus}
							switchChange={switchOnChange}
						/>
						<div style={{ textAlign: "right", paddingTop: "2rem" }}>
							<Button key="back" style={{ marginRight: "10px" }}>
								Cancel
							</Button>
							<Button
								key="submit"
								type="primary"
								htmlType="submit"
							>
								Submit
							</Button>
						</div>
					</Form>
				</Card>
			</Col>
		</Row>
	);
};

export default RoleSetup;
