import * as action_types from "../../../actions/Admin/actionTypes";
import { message } from "antd";

const initialLoginState = {
  token_expired: false,
};

export default function AdminErrorReducer(state = initialLoginState, action) {
  switch (action.type) {
    case action_types.userSession:
      return userSession(state, action.payload, action);
    case action_types.userSessionAuthenticate:
      return userSessionAuthenticate(state);
    default:
      return state;
  }
}

const userSessionAuthenticate = (state) => {
  state = {
    token_expired: false,
  };
  return state;
};

const userSession = (state, payload) => {
  if (payload) {
    if (payload.data.code === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      return {
        token_expired: true,
      };
    } else {
      message.destroy();
      message.error(payload.data.message);
      return state;
    }
  }
};
