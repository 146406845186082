import React from "react";
import { Form, Input } from "antd";

export default function TextField(props) {
	return props.type === "password" ? (
		<Form.Item
			name={props.name}
			label={props.label}
			dependencies={props.dependencies}
			rules={props.rules}
			hasFeedback={props.hasFeedback}
		>
			<Input.Password
				type={props.type}
				placeholder={props.placeholder}
				iconRender={props.iconRender}
				autoComplete="new-password"
			/>
		</Form.Item>
	) : (
		<Form.Item
			name={props.name}
			label={props.label}
			dependencies={props.dependencies}
			rules={props.rules}
			hasFeedback={props.hasFeedback}
			extra={props.extra}
			style={props.style}
		>
			<Input
				type={props.type}
				placeholder={props.placeholder}
				autoComplete={props.autocomplete}
				addonBefore={props.addonBefore}
				disabled={props.disabled}
				onChange={props.onChange}
			/>
		</Form.Item>
	);
}
