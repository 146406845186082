import {
  HomeOutlined,
  PlusOutlined,
  RedoOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form, Modal, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faGear } from "@fortawesome/free-solid-svg-icons";
import DatatableMerchantCategory from "../../Datatable/Dt-merchantCategory";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import MerchantCategoryFormModal from "../../Modals/MerchantCategoryFormModal";

import {
  fetchMerchantCategoryList,
  fetchMainMerchantCategory,
  fetchSysGeneralData,
  deleteMerchantCategory,
} from "../../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";

library.add(fas, faGear);

const breadcrumbItem = [
  {
    label: "",
    link: "/admin/dashboard",
    icon: <HomeOutlined />,
  },
  {
    label: "Merchant",
    icon: <FontAwesomeIcon icon="fa-solid fa-shop" />,
  },
  {
    label: "Merchant Category Listing",
    link: "/admin/merchant-category-list",
  },
];

const MerchantCategoryList = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  const [statusSource, setStatusSource] = useState([]);
  const [mainCategorySource, setMainCategorySource] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ 
    current: 1, 
    pageSize: 50, 
  });
  const [sorting, setSorting] = useState({
    sortField: "id",
    sortOrder: "DESC",
  });
  const [merchantcategoryModalVisible, setMerchantCategoryModalVisible] =
    useState(false);

    useEffect(() => {
    let values = form.getFieldsValue();
    fetchGeneralData();
    fetchMainMerchantCategoryData();
    fetchMerchantCategoryListData(values);
  }, [pagination.current]);

  const fetchGeneralData = async () => {
    const param = {};
    param["type"] = "general_status";
    param["status"] = "A";

    const response = await fetchSysGeneralData({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ code, name }) => ({
        value: code,
        desc: name,
      }));
      setStatusSource(transformed);
    }
  };

  const fetchMainMerchantCategoryData = async () => {
    const param = {};
    param["status"] = "A";
    param["parent_code"] = "MC";

    const response = await fetchMainMerchantCategory({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ id, name }) => ({
        value: id,
        desc: name,
      }));
      setMainCategorySource(transformed);
    }
  };
  
  const fetchMerchantCategoryListData = async (values) => {
    const param = {};
    param["page"] = pagination.current;
    param["per_page"] = pagination.pageSize;
		param["page_length"] = pagination.pageSize;
    param["code"] = values.search_code;
    param["parent_id"] = values.search_parent_name;
    param["name"] = values.search_name;
    param["status"] = values.search_status;

    setLoading(true);
    const response = await fetchMerchantCategoryList({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      setLoading(false);
      setDataSource(response.page_items);
    }

    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: response.total_items,
    });
  };

  const postDeleteCategoryData = async (id) => {
    const param = {};
    param["id"] = id;

    setLoading(true);
    const response = await deleteMerchantCategory({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      setLoading(false);
      if (response.code === 200) {
        message
          .success(response.message, 1)
          .then(fetchMerchantCategoryListData({}));
      }
    }
  };

  const showMerchantCategoryFormModal = (record) => {
    setMerchantCategoryModalVisible(true);
    if (record) {
      setFormValue(record);
    } else {
      setFormValue({});
    }
  };

  const onMerchantCategoryModalCancel = () => {
    setMerchantCategoryModalVisible(false);
  };

  const handleRemoveRecord = (record) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to remove ${record.name}?`,
      okText: "Yes",
      cancelText: "No",

      onOk() {
        postDeleteCategoryData(record.id);
      },
    });
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    let order = "ASC";

    if (sorter.order == "descend") {
      order = "DESC";
    }

    setSorting({
      sortField: sorter.column ? sorter.field : "id",
      sortOrder: sorter.order ? order : "DESC",
    });

    setPagination({
			current: newPagination.current,
			pageSize: newPagination.pageSize,
			total: newPagination.total,
		});
  };

  const onFinish = (values) => {
    fetchMerchantCategoryListData(values);
  };

  const searchField = [
    {
      input: (
        <SelectField
          placeholder="Parent"
          name="search_parent_name"
          options={mainCategorySource}
        />
      ),
    },
    {
      input: <TextField name="search_code" placeholder="Code" />,
    },
    {
      input: <TextField name="search_name" placeholder="Name" />,
    },
    {
      input: (
        <SelectField
          placeholder="Status"
          name="search_status"
          options={statusSource}
        />
      ),
    },
  ];

  const searchFieldButton = [
    {
      button: (
        <Button
          type="secondary"
          icon={<PlusOutlined />}
          style={{ width: "100%" }}
          onClick={() => {
            showMerchantCategoryFormModal();
          }}
        >
          Add New
        </Button>
      ),
    },
    {
      button: (
        <Button
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
          style={{ width: "100%" }}
        >
          Search
        </Button>
      ),
    },
    {
      button: (
        <Button
          type="danger"
          style={{ width: "100%" }}
          icon={<RedoOutlined />}
          onClick={() => {
            form.resetFields();
            fetchMerchantCategoryListData({});
          }}
        >
          Reset
        </Button>
      ),
    },
  ];

  return (
    <>
      <BreadcrumbLayout items={breadcrumbItem} />
      <Divider />
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
      >
        <AdvSearchComponent
          field={searchField}
          buttonField={searchFieldButton}
        />
      </Form>
      <Divider dashed />
      <DatatableMerchantCategory
        dataSource={dataSource}
        pagination={pagination}
        loading={loading}
        handleTableChange={handleTableChange}
        handleRemoveRecord={handleRemoveRecord}
        showMerchantCategoryFormModal={showMerchantCategoryFormModal}
      />
      <MerchantCategoryFormModal
        visible={merchantcategoryModalVisible}
        onCancel={onMerchantCategoryModalCancel}
        handleRemoveRecord={handleRemoveRecord}
        formValue={formValue}
        fetchMerchantCategoryListData={fetchMerchantCategoryListData}
      />
    </>
  );
};

export default MerchantCategoryList;
