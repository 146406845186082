import { HomeOutlined } from "@ant-design/icons";
import { Col, Form, message, Popconfirm, Row, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchStaff,
  updateStaffStatus,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import StylistForm from "../Form/Stylist/StylistForm";
import CardSetting from "../Global/SettingComponent/CardSetting";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faUserMinus,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

library.add(fas, faUserMinus, faUserPlus);

const MerchantStylistSetting = () => {
  const { Title } = Typography;
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [staff, setStaff] = useState({});
  const color = ["#4086F4", "#A7B397", "#F440E6", "#5D9F05"];
  let key = 0;

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Stylist Setting",
      link: "/merchant/stylist-setting",
    },
  ];

  const cardActionOnClick = (event) => {
    let index = event.target.getAttribute("data-ind");

    setData(staff[index]);
  };

  const handleStatus = async (e) => {
    e.stopPropagation();
    let data = {};
    data["status"] = e.currentTarget.getAttribute("data-status");
    data["id"] = e.currentTarget.getAttribute("data-id");

    const response = await updateStaffStatus({ api, data, dispatch });

    if (response) {
      message.success(response.message);
      getStaff();
    }
  };
  const getStaff = async () => {
    const response = await fetchStaff({ api, dispatch });

    if (response) {
      setStaff(response);
    }
  };

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Stylist Setting</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Row gutter={20} justify="end">
            <Col>
              <button
                htmltype="button"
                className="btn-secondary w-100"
                onClick={() => {
                  setData([]);
                }}
                style={{ marginBottom: "2rem" }}
              >
                Add new Stylist
              </button>
            </Col>
          </Row>
          <Row gutter={20}>
            {staff.length > 0
              ? staff.map((stf, index) => {
                  if (!color[key]) {
                    key = 0;
                  }

                  let set_color = color[key];
                  let name = "";
                  key++;
                  return (
                    <Col
                      key={index}
                      xs={24}
                      md={12}
                      style={{ marginBottom: "2rem" }}
                    >
                      <CardSetting
                        avatar={stf.img_path}
                        color={stf.status == "Active" ? set_color : ""}
                        background={stf.status == "Inactive" ? "#bfbfbf" : ""}
                        name={stf.name}
                        detail={stf.merchant_staff_title_name}
                        desc={stf.desc}
                        action={
                          <div style={{ display: "flex" }}>
                            {stf.status == "Active" ? (
                              <Tooltip title="Inactive Staff">
                                <Popconfirm
                                  placement="top"
                                  title="Are you sure you want to inactivate this staff?"
                                  onConfirm={handleStatus}
                                  okButtonProps={{
                                    "data-id": stf.id,
                                    "data-status": "I",
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-user-minus"
                                    style={{
                                      color: "#90a17e",
                                      fontSize: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Activate Staff">
                                <Popconfirm
                                  placement="top"
                                  title="Are you sure you want to activate this staff?"
                                  onConfirm={handleStatus}
                                  okButtonProps={{
                                    "data-id": stf.id,
                                    "data-status": "A",
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-user-plus"
                                    style={{
                                      color: "#90a17e",
                                      fontSize: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            )}

                            <img
                              src="/icons/icon-edit.svg"
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                              onClick={cardActionOnClick}
                              data-ind={index}
                            />
                          </div>
                        }
                        priceDesc={
                          stf.prices.length > 0
                            ? stf.prices.map((price, key) => {
                                let price_html = "";

                                if (name == "" || name != price.product_name) {
                                  price_html += `
                                    <span
                                      style="color: #808080;padding-right: .5rem">
                                      ${price.product_name}:
                                    </span>`;

                                  let key_index = 0;
                                  stf.prices.map((prc, ky) => {
                                    if (
                                      prc.product_name == price.product_name
                                    ) {
                                      if (key_index == 1 && ky != 0) {
                                        //where should the "|" located
                                        price_html += " | ";
                                      }
                                      price_html += `${prc.currency_code} ${prc.unit_price} (${prc.price_name})`;
                                      key_index = 1;
                                    }
                                  });
                                }

                                name = price.product_name;

                                return (
                                  <div
                                    key={key}
                                    dangerouslySetInnerHTML={{
                                      __html: price_html,
                                    }}
                                  ></div>
                                );
                              })
                            : ""
                        }
                      />
                    </Col>
                  );
                })
              : ""}
          </Row>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3" }}
      >
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Add Stylist</strong>
          </Title>
        </div>
        <div style={{ margin: "0 25px" }}>
          <StylistForm data={data} getStaff={getStaff} />
        </div>
      </Col>
    </Row>
  );
};

export default MerchantStylistSetting;
