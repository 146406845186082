import React from "react";
import { Form, Select } from "antd";
var optGroup = "";

export default function SelectGroupField(props) {
  const { OptGroup, Option } = Select;

  if (props.type == "parent") {
    optGroup = props.options.map((main, key) => {
      return main.childNodes.map((childs, key) => {
        return (
          <OptGroup label={childs.name}>
            {childs.childNodes.map((nodes, index) => {
              return (
                <Option key={nodes.code} value={nodes.id}>
                  {nodes.name}
                </Option>
              );
            })}
          </OptGroup>
        );
      });
    });
  }

  if (props.type == "normal") {
    optGroup = props.options.map((items, key) => {
      return (
        <OptGroup key={items.id} label={items.name}>
          {items.steps.map((nodes, index) => {
            return (
              <Option
                key={nodes.booking_entity_id}
                value={nodes.booking_entity_id}
              >
                {nodes.booking_entity_name}
              </Option>
            );
          })}
        </OptGroup>
      );
    });
  }

  return (
    <Form.Item
      name={props.name}
      label={props.label}
      rules={props.rules}
      style={props.style}
    >
      <Select
        id={props.id}
        mode={props.mode}
        onDropdownVisibleChange={props.handleChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        // defaultValue={props.defaultValue}
      >
        {optGroup}
      </Select>
    </Form.Item>
  );
}
