import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import BookingStep1 from "../../components/Member/Booking/BookingStep1";
import BookingStep2 from "../../components/Member/Booking/BookingStep2";
import BookingStep3 from "../../components/Member/Booking/BookingStep3";
import "../../assets/css/Member/global.css";
import BookingStep4 from "../../components/Member/Booking/BookingStep4";
import BookingCompleted from "../../components/Member/Booking/BookingCompleted";

const BookingMasterLayout = () => {
  return (
    <Routes>
      <Route
        path="/appointment/:booking_url"
        exact={true}
        element={<BookingStep1 />}
        auth={false}
      />
      <Route
        path="/appointment/:booking_url/step1"
        exact={true}
        element={<BookingStep2 />}
        auth={false}
      />
      <Route
        path="/appointment/:booking_url/step2"
        exact={true}
        element={<BookingStep3 />}
        auth={false}
      />
      <Route
        path="/appointment/:booking_url/step3"
        exact={true}
        element={<BookingStep4 />}
        auth={false}
      />
      <Route
        path="/appointment/:booking_url/completed"
        exact={true}
        element={<BookingCompleted />}
        auth={false}
      />
    </Routes>
  );
};

export default BookingMasterLayout;
