import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "../Reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;
const middleware = [thunk];

const inititalState = {};

const store = createStore(
  reducer,
  inititalState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
