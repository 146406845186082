import axios from "axios";
import React, { useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as action_types from "../actionTypes";
import * as action from "../action";
import { message } from "antd";

export const AxiosContext = React.createContext(null);

export const useCustomAxios = () => {
  return useContext(AxiosContext);
};

let requestCount = 0;

function createAxiosInstance(auth, dispatch) {
  let AxiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: 0,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;multipart/form-data; charset=UTF-8",
      ...(auth && {
        Authorization: `${localStorage.getItem("merchant_token")}`,
      }),
    },
  });

  AxiosApi.interceptors.request.use(
    function (config) {
      requestCount++;

      if (requestCount > 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, true));
      }

      return config;
    },
    function (error) {
      // Do something with request error
      requestCount--;

      if (requestCount > 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      return Promise.reject(error);
    }
  );

  AxiosApi.interceptors.response.use(
    function (response) {
      requestCount--;

      if (requestCount === 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }

      return response;
    },
    function (error) {
      console.log(error);
      requestCount--;

      if (requestCount === 0) {
        dispatch(action.isLoading(action_types.isLoadingAction, false));
      }
      if (error.response.status === 401) {
        // alert("token expired test");
        dispatch(
          action.userSession(action_types.sessionExpired, error.response)
        );
      } else if (error.response.status === 500) {
        dispatch(action.userSession(action_types.userSession, error.response));
      } else {
        message.error(error.response.data.message);
        return Promise.reject(error);
      }
    }
  );
  return AxiosApi;
}

function AxiosComponent(props) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.MerchantLoginReducer.isLoggedIn);
  const AxiosApi = useMemo(() => {
    return createAxiosInstance(auth, dispatch);
  }, [auth, dispatch]);

  return (
    <AxiosContext.Provider value={AxiosApi}>
      {props.children}
    </AxiosContext.Provider>
  );
}

export default React.memo(AxiosComponent);
