import React from "react";
import { Form, Input } from "antd";

export default function InputField(props) {
  return (
    <Form.Item
      className={
        props.mainClassName ? props.mainClassName : "merchant-form-item"
      }
      name={props.name}
      label={props.label}
      dependencies={props.dependencies}
      rules={props.rules}
      hasFeedback={props.hasFeedback}
      extra={props.extra}
      style={props.style}
      noStyle={props.noStyle ? true : false}
      hidden={props.hidden}
      min={3}
    >
      <Input
        pattern={props.pattern}
        className={props.className ? props.className : "merchant-form-input"}
        size="large"
        type={props.type}
        placeholder={props.placeholder}
        autoComplete={props.autocomplete}
        addonBefore={props.addonBefore}
        disabled={props.disabled}
        style={props.inputStyle}
        prefix={props.prefix}
      />
    </Form.Item>
  );
}
