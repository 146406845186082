import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
  Image
} from "antd";
import React, { useEffect, useState } from "react";
import "../../../assets/css/Merchant/login.css";
import { Link, useNavigate } from "react-router-dom";
import QRPreviewModal from "../Modals/QRPreviewModal";

// const { Option } = Select;
const { Title } = Typography;

const MerchantSignupCompleted = () => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  // const layout = {
  //   labelCol: { span: 24 },
  //   wrapperCol: { span: 24 },
  // };

  const signup_data = JSON.parse(localStorage.getItem("signup_data") ?? '[]');
  
  const onCopy = () => {
    navigator.clipboard.writeText(signup_data.booking_link);
    message.success("copy successful", 3);
  }

  const onDashboard = (event) => {
    event.preventDefault();
    localStorage.setItem("steps", "");

    navigate("/merchant/dashboard");
  }

  const onCancel = () => {
    setModalVisible(false);
  };
  
  const previewOnClick = () => {
    setModalVisible(true);
  };

  return (
    <>
      <div className="container">
        <Row className="py-4" justify="space-between">
          <Col>
            <div className="MctLogoSmall">Logo</div>
          </Col>
        </Row>
        <Row align="center" style={{ maxWidth: "600px", margin: "auto" }}>
          <Col>
            <Avatar
              size={110}
              className="merchant-completed-icon"
              icon={
                <img
                  src="/icons/icon-completed.svg"
                  style={{ width: 60, height: 60 }}
                ></img>
              }
            />
          </Col>
          <Col span={24} style={{ textAlign: "center", marginTop: "2rem" }}>
            <Title level={2} style={{ marginBottom: 0 }}>
              <strong>Completed!</strong>
            </Title>
            {/* <p>Congratulation your salon basic set-up have done.</p> */}
            <p>Congratulation! Your {(signup_data.category_code == 'MC' || signup_data.category_code == 'OC') ? 'business' : signup_data.category_name} basic set-up have done.</p>
          </Col>
          <Col span={24} style={{ textAlign: "center", marginTop: "2rem" }}>
            <p>
              <strong>Your Booking link</strong>
            </p>
          </Col>
          <Col span={20} style={{ textAlign: "center", paddingRight: "1rem" }}>
            <div style={{ display: "flex", position: "relative" }}>
              <Input
                className="merchant-form-input"
                size="large"
                disabled
                placeholder={signup_data.booking_link}
                style={{
                  width: "100%",
                }}
              />
              <button
                className="btn-secondary"
                style={{
                  width: "20%",
                  position: "absolute",
                  right: 0,
                  bottom: "-1px",
                }}
                onClick={onCopy}
              >
                Copy
              </button>
            </div>
          </Col>
          <Col span={2}>
            {/* <button
              onClick={previewOnClick}
              className="btn-secondary"
              style={{
                position: "absolute",
                bottom: "-1px",
              }}
            >
              <img
                src={signup_data.qr_code ?? "/icons/icon-qr-code.svg"}
                style={{ width: 20, height: 20 }}
              ></img>
            </button> */}
            <button
              className="btn-secondary"
              style={{
                position: "absolute",
                bottom: "-1px",
              }}
            >
              <Image width={21} src={signup_data.qr_code ?? "/icons/icon-qr-code.svg"} />
            </button>
          </Col>
          <Col span={24} style={{ textAlign: "center", marginTop: "2rem" }}>
            <p>
              <strong>Advance setting</strong>
            </p>
            <div className="muted-color">
              Like company logo, stylist photo, services photo, prices & more…
            </div>
          </Col>
        </Row>
        <Row
          justify="center"
          align="center"
          style={{
            maxWidth: "600px",
            margin: "1rem auto 2rem auto",
            paddingBottom: "2rem",
          }}
        >
          <button 
            className="btn-secondary"
            onClick={(e) => onDashboard(e)}
          >
            Go Dashboard
          </button>
        </Row>
      </div>
      <QRPreviewModal
        visible={modalVisible}
        onCancel={onCancel}
        qrCode={signup_data.qr_code}
      />
    </>
  );
};

export default MerchantSignupCompleted;
