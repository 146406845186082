import { Button, Form, Modal, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import {
	createMerchantCategory,
	updateMerchantCategory,
	fetchMainMerchantCategory,
} from "../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
};

const MerchantCategoryFormModal = (props) => {
	const [form] = Form.useForm();
	const [createCategory, setCreateCategory] = useState(true);
  const [mainCategorySource, setMainCategorySource] = useState([]);
	const [switchStatus, setSwitchStatus] = useState(true);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const initialValues = {};
	const id = 0;
  const [defaultParentId, setDefaultParentId] = useState([]);


	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		validator: "Duplicate Data",
	};

	useEffect(() => {
		fetchMainMerchantCategoryData();
		if (Object.keys(props.formValue).length != 0) {
			setCreateCategory(0);
			setDefaultParentId(props.formValue.parent_id);
			form.setFieldsValue({
				parent_id: {
					value: props.formValue.parent_id,
					label: props.formValue.parent_name,
				},
				// parent_id: props.formValue.parent_id,
				code: props.formValue.code,
				name: props.formValue.name,
				seq_no: props.formValue.seq_no,
				status: props.formValue.status
			});
			if (props.formValue.status == "Active") {
				setSwitchStatus(true);
			} else {
				setSwitchStatus(false);
			}
		} else {
			setCreateCategory(1);
			form.resetFields();
		}
	}, [props.formValue]);

	const fetchMainMerchantCategoryData = async () => {
    const param = {};
    param["status"] = "A";
    param["parent_code"] = "MC";

    const response = await fetchMainMerchantCategory({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ id, name }) => ({
        value: id,
        desc: name,
      }));
      setMainCategorySource(transformed);
    }
  };

	const onFinish = async (values) => {
		if (switchStatus) {
			values["status"] = "A";
		} else {
			values["status"] = "I";
		}

		if (props.formValue.id) {
			values["id"] = props.formValue.id;
		}

		values.parent_id =
			values.parent_id?.length > 0
				? values.parent_id
				: defaultParentId;
		if (createCategory) {
			postNewCategoryData(values);
		} else {
			postUpdateCategoryData(values);
		}
		
	};

	const postNewCategoryData = async (param) => {
		const response = await createMerchantCategory({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 5).then(props.onCancel());
				props.fetchMerchantCategoryListData({});
			}
		}
	};

	const postUpdateCategoryData = async (param) => {
		const response = await updateMerchantCategory({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 5).then(props.onCancel());
				props.fetchMerchantCategoryListData({});
			}
		}
	};

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	return (
		<Modal
			title={
				createCategory
					? "Merchant Category Form"
					: "Merchant Category Edit Form"
			}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			forceRender
		>
			<Form
				{...layout}
				form={form}
				name="general_data_form"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				validateMessages={validateMessages}
				scrollToFirstError
				initialValues={initialValues}
				validateTrigger="onBlur"
			>
				<SelectField
					label="Parent Category"
					name="parent_id"
					placeholder="Select Parent Category"
					options={mainCategorySource}
					hasFeedback
				/>
				<TextField
					label="Category Code"
					name="code"
					placeholder="Category Code"
					rules={[
						{ required: true, whitespace: true, length: 3 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Name"
					name="name"
					placeholder="Name"
					rules={[
						{ required: true, whitespace: true, length: 3 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Sequence number"
					name="seq_no"
					placeholder="Sequence Number"
					rules={[
						{ required: true },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									// const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				{createCategory ? (
					<>
					</>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={switchStatus}
						switchChange={switchOnChange}
						initialValue
					/>
				)}
				<div style={{ textAlign: "right", paddingTop: "2rem" }}>
					<Button
						key="back"
						onClick={props.onCancel}
						style={{ marginRight: "10px" }}
					>
						Cancel
					</Button>
					<Button key="submit" type="primary" htmlType="submit">
						{createCategory ? "Submit" : "Update"}
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default MerchantCategoryFormModal;
