import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useContext } from "react";
import { Button, Tree } from "antd";
import { fetchUserRoleTreeData } from "../../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";
const { TreeNode } = Tree;

const DragTree = (props) => {
	let expandedKeyss = [];
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [selectedKey, setSelectedKey] = useState("");
	const [treeData, setTreeData] = useState();
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();

	useEffect(() => {
		if (props.reloadTree) {
			fetchTreeData();
		}
		props.setReloadTree(false);
	}, [props.reloadTree]);

	useEffect(() => {
		fetchTreeData();
	}, [props.encrypted_parent_id]);

	const fetchTreeData = async () => {
		const param = {};
		param["code"] = props.encrypted_parent_id;

		const response = await fetchUserRoleTreeData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setTreeData(response);
		}
	};

	const onExpand = (expandedKeys) => {
		console.log("onExpand", expandedKeys);
		expandedKeyss = expandedKeys;
		setExpandedKeys(expandedKeys);
	};

	const addNode = (key, data) =>
		data.map((item) => {
			if (item.id === key) {
				if (item.children) {
					item.children.push({
						name: "New Node",
						key: 0,
						parentKey: key,
						parent_id: key,
						parent_name: item.name,
						guard_name: item.guard_name,
						status: "A",
						isEditable: false,
					});
				} else {
					item.children = [];
					item.children.push({
						name: "New Node",
						key: 0,
						parentKey: key,
						parent_id: key,
						parent_name: item.name,
						guard_name: item.guard_name,
						status: "A",
						isEditable: false,
					});
				}
				return;
			}
			if (item.children) {
				addNode(key, item.children);
			}
		});

	const onAdd = (e) => {
		if (expandedKeys.indexOf(selectedKey) === -1) {
			expandedKeyss.push(selectedKey);
		}
		addNode(selectedKey, treeData);
		setTreeData(treeData);
		//setExpandedKeys(expandedKeyss);
		//setExpandedKeys(expandedKeys);
	};

	const deleteNode = (key, data) =>
		data.map((item, index) => {
			if (item.key === key) {
				data.splice(index, 1);
				return;
			} else {
				if (item.children) {
					deleteNode(key, item.children);
				}
			}
		});

	const onDelete = () => {
		console.log("delete");
		// deleteNode(selectedKey, data);
		// setTreeData(data);
		// props.form.resetFields();
	};

	const onSelect = (selectedKeys, info) => {
		console.log(info);
		setSelectedKey(selectedKeys[0]);
		props.setFormValue({
			//set form de value here
			id: selectedKeys[0],
			parent_id: info.selectedNodes[0].dataRef.parent_id
				? info.selectedNodes[0].dataRef.parent_id
				: info.selectedNodes[0].parent_id,
			parent_name: info.selectedNodes[0].dataRef.parent_name,
			name: info.selectedNodes[0].dataRef.name,
			seq_no: info.selectedNodes[0].dataRef.seq_no,
			status: info.selectedNodes[0].dataRef.status,
			guard_name: info.selectedNodes[0].dataRef.guard_name,
		});
	};

	const getParentTitle = (key, tree) => {
		let parentTitle;
		for (let i = 0; i < tree.length; i++) {
			const node = tree[i];
			if (node.children) {
				if (node.children.some((item) => item.key === key)) {
					parentTitle = node.title;
					console.log(parentTitle);
				} else if (getParentTitle(key, node.children)) {
					parentTitle = getParentTitle(key, node.children);
				}
			}
		}
		return parentTitle;
	};

	const editNode = (key, treeData) =>
		treeData.map((item) => {
			if (item.key === key) {
				item.isEditable = true;
			} else {
				item.isEditable = false;
			}
			//When a node is in the editing state, and the data is changed, when you click to edit other nodes, the node becomes uneditable, and the value needs to be returned to defaultvalue
			item.value = item.defaultValue;
			if (item.children) {
				editNode(key, item.children);
			}
		});

	const closeNode = (key, defaultValue, treeData) =>
		treeData.map((item) => {
			item.isEditable = false;
			if (item.key === key) {
				item.value = defaultValue;
			}
			if (item.children) {
				closeNode(key, defaultValue, item.children);
			}
		});

	const saveNode = (key, treeData) =>
		treeData.map((item) => {
			if (item.key === key) {
				item.defaultValue = item.value;
			}
			if (item.children) {
				saveNode(key, item.children);
			}
			item.isEditable = false;
		});

	const changeNode = (key, value, treeData) =>
		treeData.map((item) => {
			if (item.key === key) {
				item.value = value;
			}
			if (item.children) {
				changeNode(key, value, item.children);
			}
		});

	const renderTreeNodes = (treeData) =>
		treeData.map((item) => {
			item.title = <span>{item.name}</span>;

			if (item.children) {
				return (
					<TreeNode title={item.title} key={item.id} dataRef={item}>
						{renderTreeNodes(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode key={item.id} {...item} dataRef={item} />;
		});

	//drag and drop
	const DragEnter = (info) => {
		//console.log("dragenter", info);
		//expandedKeys need to be set when controlled
		//setExpandedKeys();
		//this.setState({
		//expandedKeys: info.expandedKeys,
		//});
	};

	const handleDrop = (info) => {
		// console.log("handledrop", info);
		// const dropKey = info.node.props.eventKey;
		// const dragKey = info.dragNode.props.eventKey;
		// const dropPos = info.node.props.pos.split("-");
		// const dropPosition =
		// 	info.dropPosition - Number(dropPos[dropPos.length - 1]);
		// const loop = (data, key, callback) => {
		// 	for (let i = 0; i < data.length; i++) {
		// 		if (data[i].id === key) {
		// 			return callback(data[i], i, data);
		// 		}
		// 		if (data[i].children) {
		// 			loop(data[i].children, key, callback);
		// 		}
		// 	}
		// };
		// const data = [...treeData];
		// let dragObj;
		// loop(data, dragKey, (item, index, arr) => {
		// 	arr.splice(index, 1);
		// 	dragObj = item;
		// });
		// if (!info.dropToGap) {
		// 	//Drop on the content
		// 	loop(data, dropKey, (item) => {
		// 		item.children = item.children || [];
		// 		//where to insert example is added to the end, it can be any position
		// 		item.children.push(dragObj);
		// 	});
		// } else if (
		// 	(info.node.props.children || []).length > 0 && //Has children
		// 	info.node.props.expanded && //Is expanded
		// 	dropPosition === 1 //On the bottom gap
		// ) {
		// 	loop(data, dropKey, (item) => {
		// 		item.children = item.children || [];
		// 		//where to insert example is added to the head, it can be any position
		// 		item.children.unshift(dragObj);
		// 	});
		// } else {
		// 	let ar;
		// 	let i;
		// 	loop(treeData, dropKey, (item, index, arr) => {
		// 		ar = arr;
		// 		i = index;
		// 	});
		// 	if (dropPosition === -1) {
		// 		ar.splice(i, 0, dragObj);
		// 	} else {
		// 		ar.splice(i + 1, 0, dragObj);
		// 	}
		// }
		// setTreeData(data);
	};

	return (
		<div>
			<div style={{ marginBottom: "5px" }}>
				<Button
					key="addnew"
					type="primary"
					htmlType="button"
					icon={<PlusCircleOutlined />}
					onClick={onAdd}
				>
					Add New Role
				</Button>
				<Button
					key="delete"
					type="danger"
					style={{ marginLeft: "10px" }}
					icon={<MinusCircleOutlined />}
					onClick={onDelete}
				>
					Delete Role
				</Button>
			</div>
			{treeData ? (
				<Tree
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					draggable
					onDrop={handleDrop}
					onDragEnter={DragEnter}
					onSelect={onSelect}
				>
					{renderTreeNodes(treeData)}
				</Tree>
			) : (
				"Loading Tree"
			)}
		</div>
	);
};

export default DragTree;
