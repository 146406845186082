import { Col, Row } from "antd";
import React from "react";


const AdvSearchComponent = ({ field, buttonField }) => {

    const result = field.map((value, key) => {
        return (
          <Col xs={24} md={6} key={key}>
            {value.input}
          </Col>
        );
    });
    
    const btnResult = buttonField.map((value, key) => {
        return (
            <Col
            style={{ marginBottom: "10px", marginRight: "10px" }}
            key={key}
            >
            {value.button}
            </Col>
        );
    });
        
    return (
        <>
            <Row gutter={24}>{result}</Row>
            <Row>{btnResult}</Row>
        </>
    );
}

export default AdvSearchComponent;
