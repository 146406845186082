import { Avatar, Col, Modal, Row } from "antd";
import React from "react";

export default function PriceSettingPreviewModal(props) {
  return (
    <Modal
      title={null}
      open={props.visible}
      centered
      onCancel={props.onCancel}
      footer={null}
      forceRender
      className="text-center"
      width={1000}
      bodyStyle={{ padding: "30px" }}
    >
      <div style={{ border: "1px solid var(--border-color)" }}>
        <div style={{ padding: "2rem" }}>
          <Row justify="center" style={{ marginBottom: "2rem" }}>
            <Col span={24}>
              <Avatar
                size={100}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  border: "1px solid var(--border-color)",
                }}
                icon={
                  <div className="MctLogo" style={{ fontSize: "28px" }}>
                    Logo
                  </div>
                }
              />
            </Col>
          </Row>
          {props.formField
            ? Object.values(props.formField).map((setting, index) => {
                if (props?.formData?.title?.[index]) {
                  return (
                    <Row
                      justify="center"
                      style={{ marginBottom: "1rem" }}
                      key={index}
                    >
                      <Col span={24}>
                        <div className="cochin-font-title">
                          <span>{props?.formData?.title?.[index]}</span>
                        </div>
                      </Col>
                    </Row>
                  );
                } else if (props?.formData?.service_type?.[index]) {
                  return (
                    <Row
                      justify="center"
                      style={{ marginBottom: "1rem" }}
                      key={index}
                    >
                      <Col span={24}>
                        <Row
                          justify="space-between"
                          style={{ alignItems: "center" }}
                        >
                          <Col>
                            <span>
                              {props?.formData?.service_type?.[index]}
                            </span>
                          </Col>
                          <Col flex="auto">
                            <div className="dotted-line" />
                          </Col>
                          <Col>
                            <span>{props?.formData?.price?.[index]}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                }
              })
            : null}
        </div>
      </div>
    </Modal>
  );
}
