import { HomeOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Form, Image, message, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import StylistLevel from "./StylistLevel";
import { Link } from "react-router-dom";
import InputField from "../Global/FormInputComponent/InputField";
import {
  fetchCompanySetting,
  fetchTerritoryData,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";

const MerchantCompanySetting = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [data, setData] = useState([]);

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Company Setting",
      link: "/merchant/company-setting",
    },
  ];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const fetchCompanySettingData = async () => {
    const response = await fetchCompanySetting({ api, dispatch });

    if (response) {
      const countries = await fetchCountryData();

      if (countries) {
        setCountryOptions(countries);
        countries.map((country) => {
          if (country.code == response.country_code) {
            setCountryId(country.id);
          }
        });
      }

      setData(response);

      form.setFieldsValue({
        shop_name: response.shop_name,
        display_shop_name: response.display_shop_name,
        mobile_prefix: response.mobile_prefix,
        mobile: response.mobile_no,
        mobile_no: response.mobile_contact,
        email: response.email,
        country: response.country_name,
        state_code: response.state_code,
        city_code: response.city_code,
        postcode: response.postcode,
        addr: response.addr,
        merchant_category_name: response.merchant_category_name,
        full_link: response.booking_domain + response.booking_url,
        link: response.booking_url,
        interval: response.hours
          ? response.hours[0].buffer_time + " " + response.hours[0].buffer_type
          : "",
        logo: response.logo,
        qr_code: response.qr_code ?? "/icons/icon-qr-code.svg",
      });
    }
  };

  const fetchCountryData = async () => {
    var param = {};
    param["territory_type"] = "country";
    param["status"] = "A";

    const response = await fetchTerritoryData({
      data: param,
      api,
      dispatch,
    });

    return response;
  };

  const fetchStateData = async (id) => {
    var param = {};
    param["territory_type"] = "state";
    param["parent_id"] = id;

    const states = await fetchTerritoryData({
      api,
      data: param,
      dispatch,
    });

    if (states) {
      setStateOptions(states);
      states.map((state) => {
        if (state.code == form.getFieldValue("state_code")) {
          setStateId(state.id);
          form.setFieldsValue({ state: state.name });
          setData({ ...data, state: state.name });
        }
      });
    }
  };

  const fetchCityData = async (id) => {
    var param = {};
    param["territory_type"] = "city";
    param["parent_id"] = id;

    const cities = await fetchTerritoryData({
      api,
      data: param,
      dispatch,
    });

    if (cities) {
      setCityOptions(cities);
      cities.map((city) => {
        if (city.code == form.getFieldValue("city_code")) {
          form.setFieldsValue({ city: city.name });
          setData({ ...data, city: city.name });
        }
      });
    }
  };

  useEffect(() => {
    fetchCompanySettingData();
  }, []);

  useEffect(() => {
    fetchStateData(countryId);
  }, [countryId]);

  useEffect(() => {
    fetchCityData(stateId);
  }, [stateId]);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Company Setting</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Form
            {...layout}
            name="company_form"
            initialValues={{}}
            form={form}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col
                xs={24}
                lg={4}
                style={{ textAlign: "center", marginBottom: "1rem" }}
              >
                <Avatar size={100} src={form.getFieldValue("logo")} />
              </Col>
              <Col xs={24} lg={20}>
                <InputField
                  name="shop_name"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
                <InputField
                  name="display_shop_name"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <InputField
                  name="mobile_no"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="email"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <InputField
                  name="country"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="state"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <InputField
                  name="city"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="postcode"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <InputField
                  name="addr"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <InputField
                  label="Business Type:"
                  name="merchant_category_name"
                  style={{ marginBottom: "10px" }}
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24} style={{ marginTop: "1rem" }}>
                <p>Your Booking link</p>
              </Col>
              <Col
                xs={18}
                md={20}
                style={{ textAlign: "center", paddingRight: "1rem" }}
              >
                <InputField
                  name="full_link"
                  style={{ marginBottom: "10px", position: "relative" }}
                  disabled
                  className="merchant-form-input ant-input-disabled"
                  inputStyle={{
                    width: "100%",
                  }}
                />
                <button
                  className="btn-secondary"
                  style={{
                    width: "20%",
                    position: "absolute",
                    right: 0,
                    top: "-1px",
                    minWidth: "80px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      form.getFieldValue("full_link")
                    );
                    message.success("Copied to Clipboard");
                  }}
                >
                  Copy
                </button>
              </Col>
              <Col xs={6} md={2}>
                <div className="merchant-qr-image">
                  <Image width={25} src={form.getFieldValue("qr_code")} />
                </div>
              </Col>
            </Row>
            <Row gutter={20} justify="space-between">
              <Col span={24} style={{ marginTop: "1rem" }}>
                <p>Operation Days</p>
              </Col>
            </Row>
            <Row gutter={20}>
              {days.map((day, ind) => {
                let selected = "merchant-date-card unselected";
                if (data.hours) {
                  if (data.hours[0]["operation_days"].indexOf(day) != -1) {
                    selected = "merchant-date-card selected";
                  }
                }

                return (
                  <Col key={ind}>
                    <Card.Grid className={selected}>
                      <strong>{day}</strong>
                    </Card.Grid>
                  </Col>
                );
              })}
            </Row>
            <Row gutter={20}>
              <Col span={24} style={{ marginTop: "1rem" }}>
                <p>Operating Hours</p>
              </Col>
            </Row>
            {data.hours
              ? data.hours.map((hrs, ind) => {
                  form.setFieldsValue({
                    [ind]: { ["hours_from"]: hrs.operation_hours_from },
                  });
                  form.setFieldsValue({
                    [ind]: { ["hours_to"]: hrs.operation_hours_to },
                  });
                  return (
                    <Row style={{ marginTop: ind > 0 ? "1rem" : "" }} key={ind}>
                      <Col xs={10} md={7}>
                        <InputField
                          name={[ind, "hours_from"]}
                          style={{ marginBottom: "0px", width: "100%" }}
                          // placeholder={hrs.operation_hours_from}
                          disabled
                          className="merchant-form-input ant-input-disabled"
                        />
                      </Col>
                      <Col
                        xs={2}
                        md={1}
                        style={{ alignSelf: "center", textAlign: "center" }}
                      >
                        To
                      </Col>
                      <Col xs={10} md={7}>
                        <InputField
                          name={[ind, "hours_to"]}
                          style={{ marginBottom: "0px", width: "100%" }}
                          // placeholder={hrs.operation_hours_to}
                          disabled
                          className="merchant-form-input ant-input-disabled"
                        />
                      </Col>
                    </Row>
                  );
                })
              : ""}

            <Row gutter={20}>
              <Col span={24} style={{ marginTop: "1rem" }}>
                <p>Booking Interval</p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={7}>
                <InputField
                  name="interval"
                  style={{ marginBottom: "10px" }}
                  placeholder="1300"
                  disabled
                  className="merchant-form-input ant-input-disabled"
                />
              </Col>
            </Row>
            <Row style={{ margin: "2rem 0" }}>
              <Col xs={15} lg={4}>
                <Link
                  to={"/merchant/company-setting-form"}
                  state={{
                    form: data,
                    country: countryOptions,
                    state: stateOptions,
                    city: cityOptions,
                  }}
                >
                  <button htmltype="button" className="btn-secondary w-100">
                    Edit
                  </button>
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3" }}
      >
        <StylistLevel mobile_no={data.mobile_contact} />
      </Col>
    </Row>
  );
};

export default MerchantCompanySetting;
