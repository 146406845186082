import React from 'react';
import { HomeOutlined } from "@ant-design/icons";
import BreadcrumbLayout from '../../../layouts/Admin/breadcrumb';

const breadcrumbItem = [
    {
        label: '',
        link: '/dashboard',
        icon: <HomeOutlined />
    },
]

const Dashboard = () =>{
    return (
        <>
            <BreadcrumbLayout items={breadcrumbItem} />
            <p>Dashboard </p>
        </>
    )
}

export default Dashboard;