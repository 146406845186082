import { Button, Form, Modal, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import TextField from "../Global/FormInputComponent/TextField";
import TextAreaField from "../Global/FormInputComponent/TextAreaField";
import UploadField from "../Global/FormInputComponent/UploadField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import AutoCompleteField from "../Global/FormInputComponent/AutoCompleteField";

import {
	fetcAllMerchantData,
	createProductData,
	updateProductData,
} from "../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const layout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 24 },
	layout: "vertical",
	autoComplete: "off",
};

const MerchantProductFormModal = (props) => {
	const [form] = Form.useForm();
	const [createService, setCreateService] = useState(true);
	const [searchSource, setSearchSource] = useState([]);
	const [switchStatus, setSwitchStatus] = useState(true);
	const [importData, setImportData] = useState({
		dataList: [],
	});
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const initialValues = {};

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		validator: "Duplicate Data",
	};

	useEffect(() => {
		console.log(importData);
		if (Object.keys(props.formValue).length !== 0) {
			setCreateService(0);
			form.setFieldsValue({
				code: props.formValue.code,
				name: props.formValue.name,
				company_name: props.formValue.company_name,
				desc: props.formValue.desc,
				duration: props.formValue.duration,
				seq_no: props.formValue.seq_no,
			});
			if (props.formValue.status === "Active") {
				setSwitchStatus(true);
			} else {
				setSwitchStatus(false);
			}

			if (props.formValue.avatar) {
				setImportData({ dataList: [{ url: props.formValue.avatar }] });
			}
		} else {
			setCreateService(1);
			form.resetFields();
			setImportData({
				dataList: [],
			});
		}
	}, [props.formValue]);

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	const searchShopOnChange = (value) => {
		searchShopData(value);
	};

	const onSelect = (value, option) => {
		form.setFieldsValue({
			country_id: option.country_id,
			company_id: option.key,
		});
	};

	const onFinish = async (values) => {
		const formData = new FormData();

		if (switchStatus) {
			formData.append("status", "A");
		} else {
			formData.append("status", "I");
		}

		importData.dataList.forEach((file) => {
			formData.append("avatar", file);
		});

		formData.append("name", values["name"]);
		formData.append("desc", values["desc"]);
		formData.append("duration", values["duration"]);
		formData.append("seq_no", values["seq_no"]);
		formData.append("code", values["code"]);

		if (props.formValue.id) {
			formData.append("_method", "PUT");
			formData.append("id", props.formValue.id);
			formData.append("code", props.formValue.code);
			formData.append("desc_id", props.formValue.desc_id);
			formData.append("image_id", props.formValue.image_id);
		} else {
			formData.append("country_id", form.getFieldValue("country_id"));
			formData.append("company_id", form.getFieldValue("company_id"));
		}

		if (createService) {
			postNewProductData(formData);
		} else {
			postUpdateProductData(formData);
		}
	};

	const postUpdateProductData = async (param) => {
		const response = await updateProductData({
			api,
			data: param,
			id: props.formValue.id,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				props.fetchProductListData(props.formValue);
				form.resetFields();
				message.success(response.message, 5).then(props.onCancel());
			}
		}
	};

	const postNewProductData = async (param) => {
		const response = await createProductData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				props.fetchProductListData(props.formValue);
				message.success(response.message, 5).then(props.onCancel());
			}
		}
	};

	const searchShopData = async (value) => {
		const param = {};
		param["name"] = value;
		const response = await fetcAllMerchantData({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			const transformed = response.map(
				({ id, country_id, shop_name }) => ({
					value: shop_name,
					label: shop_name,
					key: id,
					country_id: country_id,
				})
			);

			setSearchSource(transformed);
		}
	};

	return (
		<Modal
			title={
				createService
					? "Merchant Service Form"
					: "Merchant Service Edit Form"
			}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			getContainer={false}
		>
			<Form
				{...layout}
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				validateMessages={validateMessages}
				scrollToFirstError
				initialValues={initialValues}
				validateTrigger="onBlur"
			>
				{createService ? (
					<>
						<AutoCompleteField
							label="Shop Name"
							name="company_name"
							options={searchSource}
							placeholder="Enter Shop Name here"
							searchOnChange={searchShopOnChange}
							onSelect={(val, option) => onSelect(val, option)}
						/>
						<TextField
							label="Service Code"
							name="code"
							placeholder="Example: HC"
							rules={[{ required: true, whitespace: true }]}
							hasFeedback
						/>
					</>
				) : (
					<TextField
						label="Shop Name"
						name="company_name"
						placeholder="Example: ABC Salon"
						disabled={createService ? 0 : 1}
						rules={[{ required: true, whitespace: true }]}
						hasFeedback
					/>
				)}

				<TextField
					label="Service Name"
					name="name"
					placeholder="Example: Hair Cut"
					rules={[
						{ required: true, whitespace: true },
						{
							// validator: async (rule, value, callback) => {
							// if (value) {
							// const param = { id: id, locale: value };
							//   const validate = await checkLanguageDuplicate({
							//     api: api,
							//     data: param,
							//     dispatch,
							//   });
							//   const validation = validate.data;
							//   if (validation.error == 1) {
							//     throw new Error(validation.msg);
							//   }
							// }
							// },
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Service Time (Minutes)"
					name="duration"
					placeholder="Example: 60"
					rules={[{ required: true }]}
					hasFeedback
				/>
				<TextAreaField
					label="Service Description"
					name="desc"
					placeholder="Example: Leave your hair with our team of experienced stylists who will help you find the right hairstyle that suits you!"
					rules={[{ required: false, whitespace: true }]}
					rows="3"
					hasFeedback
				/>
				<TextField
					label="Seq. No."
					name="seq_no"
					placeholder="Example: 1"
					rules={[{ required: true }]}
					hasFeedback
				/>
				<UploadField
					setImportData={setImportData}
					importData={importData}
					label="Image"
					name="image"
				/>
				<SwitchField
					label="Status"
					name="status"
					switchStatus={switchStatus}
					switchChange={switchOnChange}
				/>
				<div style={{ textAlign: "right", paddingTop: "2rem" }}>
					<Button
						key="back"
						onClick={props.onCancel}
						style={{ marginRight: "10px" }}
					>
						Cancel
					</Button>
					<Button key="submit" type="primary" htmlType="submit">
						{createService ? "Submit" : "Update"}
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default MerchantProductFormModal;
