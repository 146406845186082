import React, { useContext } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../actions/Merchant/Axios/axios";
import { LogoutAction } from "../../actions/Merchant/actionCreators";

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    <Link to="/merchant/dashboard">Dashboard</Link>,
    "1",
    <img
      src="/icons/menu-ico-dashboard.svg"
      style={{ width: 15, height: 14 }}
    />
  ),
  getItem(
    <Link to="/merchant/company-setting">Company Setting</Link>,
    "2",
    <img src="/icons/menu-ico-company.svg" style={{ width: 15, height: 14 }} />
  ),
  getItem(
    <Link to="/merchant/stylist-setting">Stylist Setting</Link>,
    "3",
    <img src="/icons/menu-ico-stylist.svg" style={{ width: 15, height: 14 }} />
  ),
  getItem(
    <Link to="/merchant/service-setting">Services Setting</Link>,
    "4",
    <img src="/icons/menu-ico-services.svg" style={{ width: 15, height: 14 }} />
  ),
  getItem(
    <Link to="/merchant/price-setting">Price Setting</Link>,
    "5",
    <img src="/icons/menu-ico-prices.svg" style={{ width: 15, height: 14 }} />
  ),
  getItem(
    <Link to="/merchant/client-contact">Client Contact</Link>,
    "6",
    <img src="/icons/menu-ico-contact.svg" style={{ width: 15, height: 14 }} />
  ),
  getItem(
    <Link to="/merchant/report">Report</Link>,
    "7",
    <img src="/icons/menu-ico-reports.svg" style={{ width: 15, height: 14 }} />
  ),
];

const MenuLayout = (props) => {
  const handleExpand = () => {
    props.setCollapsed(!props.collapsed);
  };

  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const handleLogout = () => {
    dispatch(LogoutAction({ api }));
  };

  return (
    <Sider
      className="merchant-sider"
      breakpoint="lg"
      collapsedWidth={props.collapsedWidth}
      onBreakpoint={(broken) => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        // if (type == "responsive") {
        //   props.setCollapsedWidth(0);
        // }
      }}
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      width={window.innerWidth < 500 ? 80 : 260}
    >
      <div
        className="MctLogoSmall"
        style={{
          padding: "15px 30px",
          background: "#fff",
          borderBottom: "1px solid #D3DEC3",
          borderRight: "1px solid #D3DEC3",
          textAlign: props.collapsed ? "center" : "left",
          width: 260,
        }}
      >
        {props.collapsed ? "L" : "Logo"}
      </div>
      <Menu
        className="merchant-menu"
        style={{
          minHeight: "calc(100vh - 140px)",
          height: "calc(100% - 140px)",
        }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
      />
      <div className="merchant-footer">
        <div style={{ color: "red" }} onClick={handleLogout}>
          {props.collapsed || window.innerWidth < 500 ? (
            <img
              src="/icons/menu-ico-logout.svg"
              style={{ width: 18, height: 14, marginRight: "9px" }}
            />
          ) : (
            <>
              <img
                src="/icons/menu-ico-logout.svg"
                style={{ width: 18, height: 14, marginRight: "9px" }}
              />
              Logout
            </>
          )}
        </div>
      </div>
      <div
        className="merchant-footer"
        style={{ fontSize: "8px", color: "#D0D0D0", paddingLeft: "2.5rem" }}
      >
        {props.collapsed || window.innerWidth < 500
          ? "©"
          : "SMART BOOKING © 2022 ALL RIGHTS RESERVED."}
      </div>
      {window.innerWidth >= 500 ? (
        <div
          className="menu-expand-icon"
          onClick={handleExpand}
          style={{ transform: props.collapsed ? "translate(-70%, 0)" : "none" }}
        >
          <img
            src="/icons/menu-ico-expand.svg"
            style={{ width: 18, height: 18 }}
          ></img>
        </div>
      ) : (
        ""
      )}
    </Sider>
  );
};

export default MenuLayout;
