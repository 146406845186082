import React, { useEffect, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Typography,
  Form,
  Checkbox,
  Input,
  message,
} from "antd";
import logo from "../../../assets/logo/logoipsum-logo.svg";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction } from "../../../actions/Admin/actionCreators";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const navigate = useNavigate();

  const onFinish = (values) => {
    // console.log('Success:', values);
    // window.location.href = '/dashboard';
    if (values.password !== "" && values.email !== "") {
      dispatch(
        LoginAction({
          api,
          data: { email: values.email, password: values.password },
        })
      );
    }
  };

  const temp = useSelector((state) => ({
    isLoggedIn: state.AdminLoginReducer.isLoggedIn, //true or false
    userSession: state.AdminErrorReducer.token_expired,
  }));

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (temp.isLoggedIn) {
      navigate("/admin/dashboard");
    }

    if (temp.userSession) {
      message.destroy();
      message.error("session expired");
    }
  }, [temp.isLoggedIn, temp.userSession]);

  return (
    <div className="login-bg">
      <Row
        justify="center"
        align="middle"
        className="h-100"
        style={{
          backgroundColor: "rgba(255,255,255,0.3)",
          backdropFilter: "blur(5px)",
        }}
      >
        <Col md={12} xs={22}>
          <Card
            style={{
              boxShadow: "rgba(100, 100, 111, 0.5) 0px 7px 29px 0px",
            }}
          >
            <Row justify="center" align="middle">
              <Col md={12} xs={24} style={{ textAlign: "center" }}>
                <Image
                  src={logo}
                  width={200}
                  style={{ color: "#fff", marginBottom: "2rem" }}
                />
              </Col>
              <Col md={12} xs={24}>
                <Typography.Title
                  level={4}
                  style={{ textTransform: "uppercase" }}
                >
                  Admin Login
                </Typography.Title>
                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="PASSWORD"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{ marginBottom: 0 }}
                  >
                    <Button type="primary" htmlType="submit" className="w-100">
                      Login
                    </Button>
                  </Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <a>Forgot your password ? </a>
                  </div>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
