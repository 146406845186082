import { Col, DatePicker, Form, message, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createHoliday } from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import TextAreaField from "../../Global/FormInputComponent/TextAreaField";

const HolidayForm = (props) => {
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [descLength, setDescLength] = useState(0);
  const [descValue, setDescVal] = useState("");
  const onChangeDate = () => {};
  function triggerInput(enteredName, enteredValue) {
    const input = document.getElementsByClassName(enteredName);
    const lastValue = input[0].value;
    input[0].value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = input[0]._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input[0].dispatchEvent(event);
  }
  const onFinish = async (values) => {
    if (values["date_start"]) {
      values["date_start"] = moment(values["date_start"]).format("YYYY-MM-DD");
    }

    if (values["date_end"]) {
      values["date_end"] = moment(values["date_end"]).format("YYYY-MM-DD");
    }
    const response = await createHoliday({
      api,
      data: values,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      form.resetFields();
      setDescVal("");
      triggerInput("description", " ");
      props.getAllHoliday();
    }
  };
  const onFinishFailed = (data) => {
    if (data.errorFields[0].name[0] == "date_start") {
      message.error("Start Date is required field");
    }
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const descOnChange = (event) => {
    setDescLength(event.target.value.length);
    setDescVal(event.target.value);
  };

  const validateMessages = {
    required: "Required Field!",
  };

  useEffect(() => {
    form.setFieldValue("description", descValue);
  }, [descValue]);

  return (
    <Form
      {...layout}
      name="addHolidayForm"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={12}>START</Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Form.Item
            noStyle
            className="merchant-form-item"
            name="date_start"
            rules={[{ required: true }]}
            hasFeedback
          >
            <DatePicker
              style={{ border: "none" }}
              onChange={onChangeDate}
              bordered={false}
              noStyle
            />
          </Form.Item>
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={12}>END</Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Form.Item noStyle className="merchant-form-item" name="date_end">
            <DatePicker
              bordered={false}
              style={{ border: "none" }}
              onChange={onChangeDate}
              disabledDate={(current) => {
                // Can not select days before today and today
                return current && current < form.getFieldValue("date_start");
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={12}>DESCRIPTION</Col>
        <Col span={12} style={{ textAlign: "end" }}>
          {descLength} / 60
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={24}>
          <TextAreaField
            bordered={false}
            name="description"
            placeholder="Enter Description"
            maxLength={60}
            onChange={descOnChange}
            autoSize={{
              minRows: 3,
              maxRows: 3,
            }}
            inputStyle={{ padding: "10px 0", border: "none" }}
            rules={[{ required: true, whitespace: true }]}
            hasFeedback
            value={descValue}
          />
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row style={{ margin: "2rem 0" }}>
        <Col span={24}>
          <button htmltype="button" className="btn-secondary w-100">
            Submit
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default HolidayForm;
