import React from "react";
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterLayout = () => {
    return(
        <Footer style={{ textAlign: 'center' }}>Booking System ©2022 Created by ISCiTy Sdn. Bhd.</Footer>
    )
}

export default FooterLayout;