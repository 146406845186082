import React from "react";
import { Form, DatePicker } from "antd";

export default function DatePickerField(props) {
  return (
    <Form.Item
      name={props.name}
      label={props.label}
      dependencies={props.dependencies}
      rules={props.rules}
      hasFeedback={props.hasFeedback}
      extra={props.extra}
      style={props.style}
    >
      <DatePicker
        type={props.type}
        placeholder={props.placeholder}
        // autoComplete={props.autocomplete}
        addonBefore={props.addonBefore}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        size={"middle"}
      />
    </Form.Item>
  );
}

{/* <DatePickerField 
    label="Date"
    name="date"
    placeholder="Date"
    rules={[
        { required: true, whitespace: true, length:3 },
    ]}
/> */}
