import { combineReducers } from "redux";
import AdminLoginReducer from "./Admin/loginReducer";
import AdminErrorReducer from "./Admin/errorReducer";
import MerchantLoginReducer from "./Merchant/loginReducer";
import MerchantErrorReducer from "./Merchant/errorReducer";
import MemberLoginReducer from "./Member/loginReducer";
import MemberErrorReducer from "./Member/errorReducer";
import GeneralReducer from "./Global/generalReducer";

const reducer = combineReducers({
  AdminLoginReducer,
  AdminErrorReducer,
  GeneralReducer,
  MerchantLoginReducer,
  MerchantErrorReducer,
  MemberLoginReducer,
  MemberErrorReducer,
});

export default reducer;
