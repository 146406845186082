import { Form, Modal, Typography, message } from "antd";
import React, { useState, useEffect, useContext, useRef } from "react";
import OtpInput from "react-otp-input";
import "../../../assets/css/Merchant/otp.css";
import { ValidateOtpAction } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";

const { Title } = Typography;

const OtpModal = (props) => {
	const [otpValue, setOtpValue] = useState("");
	const [otpID, setOtpID] = useState("");
	const [mobileNo, setMobileNo] = useState("");
	const [password, setPassword] = useState("");
	const [timer, setTimer] = useState("");
	const [resendOtp, setResendOtp] = useState(false);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	let timerOn = true;

	const timerCountDown = (remaining) => {
		var m = Math.floor(remaining / 60);
		var s = remaining % 60;

		m = m < 10 ? "0" + m : m;
		s = s < 10 ? "0" + s : s;
		remaining -= 1;

		setTimer(`${m}:${s}`);

		if (remaining >= 0 && timerOn) {
			setTimeout(function () {
				timerCountDown(remaining);
			}, 1000);
			return;
		}

		if (!timerOn) {
			console.log("still running");
			return;
		}

		// Do timeout stuff here
		setResendOtp(true);
	};

	const postResendOtp = () => {
		const param = {};
		param["mobile_no"] = mobileNo;
	};

	useEffect(() => {
		if (props.formValue.expiry_date) {
			var now = moment().local().format("YYYY/MM/DD HH:mm:ss");
			var expiry = moment(props.formValue.expiry_date).format(
				"YYYY/MM/DD HH:mm:ss"
			);

			if (expiry > now) {
				var duration = moment
					.utc(
						moment(expiry, "DD/MM/YYYY HH:mm:ss").diff(
							moment(now, "DD/MM/YYYY HH:mm:ss")
						)
					)
					.format("mm:ss");

				var pieces = duration.split(":");
				var duration_in_seconds =
					Number(pieces[0]) * 60 + Number(pieces[1]);

				timerCountDown(duration_in_seconds);
			}
		}
		setMobileNo(props.formValue.mobile_no);
		setOtpID(props.formValue.otp_id);
	}, [props]);

	const onFinish = () => {
		var param = {};
		param["otp_code"] = otpValue;
		param["otp_id"] = otpID;

		validateOTP(param);
	};

	const onFinishFailed = () => {};

	const handleChange = (otp) => {
		setOtpValue(otp);
	};

	const validateOTP = async (param) => {
		const response = await ValidateOtpAction({
			data: param,
			api,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				message.success(response.message);
				props.onCancel();
				props.checkLogin();
			}
		}
	};

	return (
		<Modal
			title={null}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			forceRender
			className="text-center"
			width={427}
			bodyStyle={{ padding: "30px" }}
		>
			<Title level={4} style={{ marginBottom: "2rem" }}>
				<strong>Verification</strong>
			</Title>
			<p>
				You OTP (One Time Password) has been successfully sent to your
				number :
				<span href="#" style={{ color: "#8FA07E" }}>
					{" +"}
					{mobileNo}
				</span>
			</p>
			<Form
				name="basic"
				initialValues={{
					remember: true,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<OtpInput
					value={otpValue}
					onChange={handleChange}
					numInputs={6}
					separator={<span>&nbsp;&nbsp;</span>}
					inputStyle="otp-input-style"
					containerStyle="otp-container"
					shouldAutoFocus={true}
					isInputNum={true}
					isInputSecure={true}
				/>
				<p style={{ marginTop: "1.5rem", marginBottom: 0 }}>
					I have not receive any code!
				</p>
				<p style={{ marginBottom: "1.5rem" }} className="link-color">
					{resendOtp ? (
						<button onClick={postResendOtp()}>Resend Now</button>
					) : (
						"Please resend again " + timer
					)}
				</p>
				<button htmltype="submit" className="w-100 btn-primary">
					Submit
				</button>
			</Form>
		</Modal>
	);
};

export default OtpModal;
