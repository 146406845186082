import { Avatar, Card, Col, Form, Image, message, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createClient,
  fetchSysGeneralData,
  fetchTerritoryData,
  updateClient,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import InputField from "../../Global/FormInputComponent/InputField";
import SelectField from "../../Global/FormInputComponent/SelectField";

export default function ClientContactForm(props) {
  const { Title } = Typography;
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };

  const title_options = [];
  const prefix_options = [];

  const onFinish = async (values) => {
    if (Object.keys(data).length > 0) {
      values["id"] = data.id;
      const response = await updateClient({
        api,
        data: values,
        dispatch,
      });

      if (response) {
        message.success(response.message);
        form.resetFields();
        props.getClient();
        setData([]);
        props.setData([]);
      }
    } else {
      const response = await createClient({
        api,
        data: values,
        dispatch,
      });

      if (response) {
        message.success(response.message);
        form.resetFields();
        props.getClient();
      }
    }
  };

  const fetchCountryData = async () => {
    var param = {};
    param["territory_type"] = "country";
    param["status"] = "A";

    const response = await fetchTerritoryData({
      data: param,
      api,
      dispatch,
    });

    if (response) {
      response.map((country, ind) => {
        prefix_options.push({
          value: country.calling_no_prefix,
          desc: (
            <div>
              <Image width={25} src={country.image_path} />{" "}
              {country.calling_no_prefix}
            </div>
          ),
        });
      });
      setPrefixOptions(prefix_options);
    }
  };

  const fetchClientTitle = async () => {
    var param = {};
    param["type"] = "client_title";
    param["status"] = "A";

    const response = await fetchSysGeneralData({
      data: param,
      api,
      dispatch,
    });

    if (response) {
      response.map((title, ind) => {
        title_options.push({
          value: title.code,
          desc: <div>{title.name}</div>,
        });
      });
      setTitleOptions(title_options);
    }
  };
  useEffect(() => {
    fetchCountryData();
    fetchClientTitle();
  }, []);

  useEffect(() => {
    setData(props.data);
    form.setFieldsValue({
      name: props.data.name,
      title: props.data.title ?? "Mr",
      mobile_prefix: props.data.mobile_prefix ?? "60",
      mobile_no: props.data.mobile_no,
    });
  }, [props.data]);

  useEffect(() => {
    form.setFieldValue("mobile_prefix", "60");
  }, [prefixOptions]);

  useEffect(() => {
    form.setFieldValue("title", "Mr");
  }, [titleOptions]);

  return (
    <>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Add Contact</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <Form
          {...layout}
          name="client_form"
          initialValues={data}
          form={form}
          validateMessages={validateMessages}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Row justify="space-between" className="header-color">
            <Col span={8}>
              <SelectField
                options={titleOptions}
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "start" }}
                className="merchant-input-no-border"
                name="title"
                selectStyle={{ textAlign: "left" }}
              />
            </Col>
            <Col span={16} style={{ textAlign: "end" }}>
              <InputField
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "end" }}
                placeholder="Client Name"
                className="merchant-input-no-border"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              />
            </Col>
          </Row>
          <hr style={{ marginBottom: "1rem" }} />
          <Row justify="space-between" className="header-color">
            <Col span={8}>
              <SelectField
                options={prefixOptions}
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "start" }}
                className="merchant-input-no-border"
                name="mobile_prefix"
                selectStyle={{ textAlign: "left" }}
              />
            </Col>
            <Col span={16} style={{ textAlign: "end" }}>
              <InputField
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "end" }}
                placeholder="Mobile Number"
                className="merchant-input-no-border"
                name="mobile_no"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              />
            </Col>
          </Row>
          <hr style={{ marginBottom: "1rem" }} />
          <Row style={{ margin: "2rem 0" }}>
            <Col span={24}>
              <button htmltype="submit" className="btn-secondary w-100">
                <strong>
                  {Object.keys(data).length > 0 ? "Update" : "Save"}
                </strong>
              </button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "1rem" }}>
            <Card
              title="Visit History"
              style={{
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Avatar
                  size={16}
                  style={{
                    backgroundColor: "#00C70F",
                  }}
                />
                <span style={{ paddingLeft: "1rem" }}>0-2 month</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Avatar
                  size={16}
                  style={{
                    backgroundColor: "#FFC400",
                  }}
                />
                <span style={{ paddingLeft: "1rem" }}>3-6 month</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Avatar
                  size={16}
                  style={{
                    backgroundColor: "#DB8700",
                  }}
                />
                <span style={{ paddingLeft: "1rem" }}>6 month -12 month</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  size={16}
                  style={{
                    backgroundColor: "#FF1400",
                  }}
                />
                <span style={{ paddingLeft: "1rem" }}>More than 1 year</span>
              </div>
            </Card>
          </Row>
        </Form>
      </div>
    </>
  );
}
