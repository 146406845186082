import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  message,
  Radio,
  Row,
  TimePicker,
  Typography,
} from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import SelectField from "../../Global/FormInputComponent/SelectField";
import InputField from "../../Global/FormInputComponent/InputField";
import {
  createAppointment,
  fetchServices,
  fetchStaff,
  fetchSysGeneralData,
  fetchTerritoryData,
  getAvailableDates,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";

library.add(fas, faXmark);

export default function AppointmentForm(props) {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [time, setTime] = useState([]);

  const title_options = [];
  const prefix_options = [];
  const stylist_options = [];
  const service_options = [];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };

  const checkboxOption = [
    {
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Add to Contact list</span>
        </div>
      ),
      value: "add_contact",
    },
  ];

  const onChange = () => {};

  const onChangeDate = async (event) => {
    const date_selected = moment(event).format("YYYY-MM-DD");
    getDates(date_selected);
  };

  const getDates = async (date_selected) => {
    const response = await getAvailableDates({
      data: { booking_date: date_selected },
      api,
      dispatch,
    });

    if (response) {
      setTime(response);
    }
  };

  const fetchCountryData = async () => {
    var param = {};
    param["territory_type"] = "country";
    param["status"] = "A";

    const response = await fetchTerritoryData({
      data: param,
      api,
      dispatch,
    });

    if (response) {
      response.map((country, ind) => {
        prefix_options.push({
          value: country.calling_no_prefix,
          desc: (
            <div>
              <Image width={25} src={country.image_path} />{" "}
              {country.calling_no_prefix}
            </div>
          ),
        });
      });
      setPrefixOptions(prefix_options);
    }
  };

  const fetchClientTitle = async () => {
    var param = {};
    param["type"] = "client_title";
    param["status"] = "A";

    const response = await fetchSysGeneralData({
      data: param,
      api,
      dispatch,
    });

    if (response) {
      response.map((title, ind) => {
        title_options.push({
          value: title.code,
          desc: <div>{title.name}</div>,
        });
      });
      setTitleOptions(title_options);
    }
  };

  const getStaff = async () => {
    const response = await fetchStaff({ api, dispatch });

    if (response) {
      response.map((staff, ind) => {
        stylist_options.push({
          value: staff.id,
          desc: <div>{staff.name}</div>,
        });
      });
      setStaffOptions(stylist_options);
    }
  };

  const getServices = async () => {
    const response = await fetchServices({ api, dispatch });

    if (response) {
      response.map((services, ind) => {
        service_options.push({
          value: services.id,
          desc: <div>{services.name}</div>,
        });
      });
      setServiceOptions(service_options);
    }
  };

  const postAppointmentForm = async (data) => {
    const response = await createAppointment({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      form.resetFields();
      form.setFieldValue("mobile_prefix", "60");
      form.setFieldValue("title", "Mr");

      if (props?.handleFilterStaff) {
        let stf_ids = [];
        props?.staff?.map((stf, ind) => {
          props?.filteredStaff.map((f_stf, f_ind) => {
            if (stf.value == f_stf.value) {
              stf_ids.push(stf?.value);
            }
          });
        });
        props?.handleFilterStaff(stf_ids);
      }
      props?.action();
    }
  };

  const onFinish = (values) => {
    // console.log(values);
    const formData = new FormData();

    formData.append("title", values["title"]);
    formData.append("name", values["name"]);
    formData.append("mobile_prefix", values["mobile_prefix"]);
    formData.append("mobile_no", values["mobile_no"]);
    if (values["prd_master_ids"]) {
      Object.values(values["prd_master_ids"]).map((svs, key) => {
        formData.set(`prd_master_ids[${key}]`, svs);
      });
    }
    if (values["staff_id"]) {
      formData.append("staff_id", values["staff_id"]);
    }
    formData.append("booking_date", values["booking_date"]);

    if (values["add_contact"]) {
      if (values["add_contact"].length > 0) {
        formData.append("add_contact", 1);
      } else {
        formData.append("add_contact", 0);
      }
    }

    postAppointmentForm(formData);
  };

  useEffect(() => {
    form.setFieldValue("mobile_prefix", "60");
  }, [prefixOptions]);

  useEffect(() => {
    form.setFieldValue("title", "Mr");
  }, [titleOptions]);

  useEffect(() => {
    fetchCountryData();
    fetchClientTitle();
    // getStaff();
    getServices();
    getDates(moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (props?.staff) {
      setStaffOptions(props?.staff);
    } else {
      getStaff();
    }
  }, [props?.staff]);

  useEffect(() => {
    if (props?.selectedStaff) {
      staffOptions.map((stfopt, ind) => {
        if (stfopt?.value == props?.selectedStaff) {
          form.setFieldValue("staff_id", stfopt?.value);
        }
      });
    }
  }, [props?.selectedStaff]);

  useEffect(() => {
    if (props?.date) {
      form.setFieldsValue({ date: moment(props?.date) });
      onChangeDate(props?.date);
    }
  }, [props?.date]);

  useEffect(() => {
    if (props?.selectedClient) {
      form.setFieldValue("title", props?.selectedClient?.title);
      form.setFieldValue("name", props?.selectedClient?.name);
      form.setFieldValue("mobile_prefix", props?.selectedClient?.mobile_prefix);
      form.setFieldValue("mobile_no", props?.selectedClient?.mobile_no);
    }
  }, [props?.selectedClient]);

  return (
    <div
      className="appointment-form"
      style={{ display: props.visible ? "block" : "none" }}
    >
      <button
        className="btn-clear"
        onClick={props.action}
        style={{
          padding: "4px 10px",
          width: "36px",
          height: "36px",
          minWidth: "36px",
          margin: "1.5rem",
          marginBottom: "0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </div>
      </button>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Add Appointment</strong>
        </Title>
      </div>
      <div style={{ margin: "0 25px" }}>
        <Form
          {...layout}
          name="holiday"
          form={form}
          // initialValues={{ date: moment() }}
          validateMessages={validateMessages}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={6}>
              <SelectField
                options={titleOptions}
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "start" }}
                className="merchant-input-no-border bg-transparent"
                name="title"
                selectStyle={{ textAlign: "left" }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "end" }}>
              <InputField
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "end" }}
                placeholder="Client Name"
                className="merchant-input-no-border"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              />
            </Col>
          </Row>
          <hr style={{ marginBottom: "1rem" }} />
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={6}>
              <SelectField
                options={prefixOptions}
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "start" }}
                className="merchant-input-no-border  bg-transparent"
                name="mobile_prefix"
                selectStyle={{ textAlign: "left" }}
              />
            </Col>
            <Col span={18} style={{ textAlign: "end" }}>
              <InputField
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "end" }}
                placeholder="Mobile Number"
                className="merchant-input-no-border"
                name="mobile_no"
                rules={[
                  {
                    required: true,
                  },
                ]}
                hasFeedback
              />
            </Col>
          </Row>
          <hr />
          <Form.Item className="merchant-form-item" name="add_contact">
            <Checkbox.Group
              className="merchant-checkbox merchant-checkbox-group"
              options={checkboxOption}
              onChange={onChange}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/icons/btn-minus.svg"
              style={{ width: 27, height: 27 }}
            ></img>
            <span style={{ marginLeft: "1rem" }}>
              Hair Design (Senior, Male)
            </span>
          </div>
          <hr style={{ margin: "1rem 0" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/icons/btn-add.svg"
              style={{ width: 27, height: 27 }}
            ></img>
            <span style={{ marginLeft: "1rem" }}>Add Service Type</span>
          </div> */}
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={8}>
              <label>SERVICES</label>
            </Col>
            <Col span={16}>
              <SelectField
                mode="multiple"
                mainClassName="appointment-service"
                style={{ marginBottom: "0px", textAlign: "end" }}
                className="merchant-input-no-border"
                name="prd_master_ids"
                options={serviceOptions}
                selectStyle={{ fontSize: "16px" }}
              />
            </Col>
          </Row>
          <hr style={{ margin: "1rem 0" }} />
          <Row
            justify="space-between"
            className="header-color"
            style={{ alignItems: "center" }}
          >
            <Col span={8}>
              <label>STYLIST</label>
            </Col>
            <Col span={16}>
              <SelectField
                mainClassName=" "
                style={{ marginBottom: "0px", textAlign: "end" }}
                className="merchant-input-no-border"
                name="staff_id"
                options={staffOptions}
                selectStyle={{ fontSize: "16px" }}
              />
            </Col>
          </Row>
          <hr style={{ margin: "1rem 0" }} />
          <Row>
            <Col span={24}>
              <Form.Item
                className="merchant-form-item"
                rules={[{ required: true, message: "This field is required" }]}
                hasFeedback
                name="date"
              >
                <DatePicker
                  bordered={false}
                  style={{ padding: "6px 0px", width: "100%" }}
                  onChange={onChangeDate}
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <hr style={{ margin: "1rem 0" }} />
          <Row>
            <Col span={24}>
              <Form.Item
                label="Select Time"
                style={{ color: "#fff" }}
                name="booking_date"
              >
                <Radio.Group className="merchant-time-radio-btn">
                  {time
                    ? time.map((t, key) => {
                        return (
                          <Radio.Button
                            key={key}
                            value={t.date_value}
                            disabled={t.available ? false : true}
                          >
                            {t.date_display}
                          </Radio.Button>
                        );
                      })
                    : ""}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "2rem 0" }}>
            <Col span={24}>
              <button htmltype="button" className="btn-secondary w-100">
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
