import React from "react";
import { Layout, Menu, Image } from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fas,
  faQrcode,
  faGear,
  faUserGear,
  faChildren,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo/logoipsum-logo.svg";
import { Link } from "react-router-dom";

library.add(fas, faQrcode, faGear, faUserGear, faChildren);

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(
    <Link to="/admin/dashboard">Dashboard</Link>,
    "sub1",
    <FontAwesomeIcon style={{ width: "20px" }} icon="fa-solid fa-qrcode" />
  ),
  getItem(
    "Member",
    "sub5",
    <FontAwesomeIcon style={{ width: "20px" }} icon="fa-solid fa-children" />,
    [getItem(<Link to="/admin/member-list">Member List</Link>, "14")]
  ),
  getItem(
    "User Setting",
    "sub4",
    <FontAwesomeIcon style={{ width: "20px" }} icon="fa-solid fa-user-gear" />,
    [
      getItem(<Link to="/admin/admin-list">User Setup</Link>, "10"),
      getItem(<Link to="/admin/user-role-setup">User Roles Setup</Link>, "11"),
      getItem(<Link to="/admin/dashboard">User Roles vs Menu</Link>, "12"),
    ]
  ),
  getItem(
    "Merchant",
    "sub6",
    <FontAwesomeIcon style={{ width: "20px" }} icon="fa-solid fa-shop" />,
    [
      getItem(<Link to="/admin/merchant-list">Merchants</Link>, "18"),
      getItem(
        <Link to="/admin/merchant-service-list">Merchant Services</Link>,
        "19"
      ),
      getItem(
        <Link to="/admin/merchant-category-list">Merchant Category</Link>,
        "20"
      ),
      getItem(
        <Link to="/admin/merchant-staff-list">Merchant Staff</Link>,
        "21"
      ),
    ]
  ),

  getItem(
    "Settings",
    "sub2",
    <FontAwesomeIcon style={{ width: "20px" }} icon="fa-solid fa-gear" />,
    [
      getItem(<Link to="/admin/menu-setup">Menu Setup</Link>, "1"),
      getItem(<Link to="/admin/language-list">Language Setup</Link>, "2"),
      getItem(
        <Link to="/admin/general-data-list">General Data Setup</Link>,
        "3"
      ),
      getItem(<Link to="/admin/country-list">Country Setup</Link>, "4"),
    ]
  ),
];

const MenuLayout = (props) => {
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={props.collapsedWidth}
      onBreakpoint={(broken) => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        if (type == "responsive") {
          props.setCollapsedWidth(0);
        }
      }}
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      width={260}
    >
      <div className="logo">
        <Image src={logo} className="w-100" />
      </div>
      <Menu
        style={{ height: "calc(100% - 57px)" }}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["sub1"]}
        items={items}
      />
    </Sider>
  );
};

export default MenuLayout;
