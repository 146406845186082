import React from "react";
import { Form, Select } from "antd";

export default function SelectField(props) {
  const { Option } = Select;
  const opt = props?.options?.map((option, key) => {
    return (
      <Option
        value={option.value}
        key={key}
        style={{ textTransform: "capitalize" }}
      >
        {option.desc}
      </Option>
    );
  });

  return (
    <Form.Item
      className={
        props.mainClassName ? props.mainClassName : "merchant-form-item"
      }
      name={props.name}
      label={props.label}
      rules={props.rules}
      style={props.style}
      noStyle={props.noStyle ? true : false}
    >
      <Select
        className={props.className ? props.className : "merchant-form-select"}
        id={props.id}
        mode={props.mode}
        onDropdownVisibleChange={props.handleChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        onChange={props.onChange}
        style={props.selectStyle}
      >
        <Option value="">Select Option</Option>
        {opt}
      </Select>
    </Form.Item>
  );
}
