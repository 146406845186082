import { Col, Form, Row, Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getStepDetails } from "../../../actions/Member/actionCreators";
import { AxiosContext } from "../../../actions/Member/Axios/axios";

const BookingCompleted = () => {
  const { Step } = Steps;
  const { booking_url } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const tmpUserId = location.state ? location.state.id : "";
  const [userId, setUserId] = useState(tmpUserId);
  const [clientInfo, setClientInfo] = useState("");
  const [step, setStep] = useState([]);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = () => {};
  const onFinishFailed = () => {};

  const fetchStepDetails = async () => {
    var param = {};
    param["current_step"] = "COM";
    if (userId) {
      param["tmp_user_id"] = userId;
    }

    const response = await getStepDetails({
      data: param,
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setUserId(response.tmp_user_id);
      setClientInfo(response.current_step_data);
      setLogo(response.logo);
      setStep(response.booking_steps);
      setData(response);
    }
  };

  useEffect(() => {
    fetchStepDetails();
  }, []);

  const downloadICS = () => {
    let staff = "";
    if (data?.current_step_data?.staff_name) {
      staff = `with ${data?.current_step_data?.staff_name}`;
    }

    const event = {
      summary: `Appointment with ${data?.shop_name}`,
      location: data?.shop_name,
      description: `${data?.current_step_data?.service_name} ${staff}`,
      startDateTime: data?.current_step_data?.booking_date,
      endDateTime: data?.current_step_data?.booking_date,
    };

    const content = generateICS(event);
    const blob = new Blob([content], { type: "text/calendar;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "booking.ics";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateICS = (event) => {
    return `BEGIN:VCALENDAR
NAME:${event.location} Appointment
X-WR-CALNAME:${event.location} Appointment
VERSION:2.0
BEGIN:VTIMEZONE
TZID:Asia/Kuala Lumpur
BEGIN:STANDARD
DTSTART:${formatDateForICS(event.startDateTime)}
DTEND:${formatDateForICS(event.endDateTime)}
END:STANDARD
END:VTIMEZONE
BEGIN:VEVENT
UID:${generateUID()}@example.com
DTSTART:${formatDateForICS(event.startDateTime)}
DTEND:${formatDateForICS(event.endDateTime)}
DTSTAMP:${formatDateTime(new Date())}
STATUS:CONFIRMED
SUMMARY:${event.summary}
LOCATION:${event.location}
DESCRIPTION:${event.description}
END:VEVENT
END:VCALENDAR`;
  };

  const generateUID = () => {
    return (
      Math.random().toString(36).substring(2) +
      new Date().getTime().toString(36)
    );
  };

  function formatDateForICS(inputDateTime) {
    // Split the input date and time string into components
    const [datePart, timePart] = inputDateTime.split(" ");

    // Split the date into day, month, and year components
    const [day, month, year] = datePart.split("/");

    // Split the time into hours and minutes components
    let [hours, minutes] = timePart.slice(0, -2).split(":"); // Remove "AM" or "PM" and split hours and minutes
    hours = parseInt(hours, 10); // Parse hours as integer

    // Adjust hours for PM
    if (timePart.endsWith("PM")) {
      hours += 12;
    }

    // Pad day, month, hours, and minutes with leading zeros if necessary
    const formattedDay = day.padStart(2, "0");
    const formattedMonth = month.padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = minutes.padStart(2, "0");

    // Concatenate the components to form the iCalendar formatted date and time string
    const formattedDateTime = `${year}${formattedMonth}${formattedDay}T${formattedHours}${formattedMinutes}00`;

    return formattedDateTime;
  }

  const formatDateTime = (dateTime) => {
    if (dateTime instanceof Date && !isNaN(dateTime)) {
      return dateTime
        .toISOString()
        .replace(/[-:]/g, "")
        .replace(/\.\d{3}/, "")
        .replace(/Z$/, "");
    } else {
      console.error("Invalid date object:", dateTime);
      return "";
    }
  };

  return (
    <div className="booking-bg">
      <div className="booking-bg-dark">
        <div className="booking-step-content">
          {logo ? (
            <img src={logo} className="member-merchant-logo" />
          ) : (
            <h3>LOGO</h3>
          )}
          <Steps current={4} size="small" labelPlacement="vertical">
            {step
              ? step.map((stp, ky) => {
                  return <Step key={ky} title={stp.name} />;
                })
              : ""}
          </Steps>

          <h4>COMPLETED!</h4>
          <p style={{ color: "#C9C9C9", marginBottom: "2rem" }}>
            Your reservation is confirmed!
          </p>
          <div className="text-white" style={{ width: "calc(100% - 3rem)" }}>
            <Row justify="space-between" style={{ fontWeight: "bold" }}>
              <Col span={12}>{clientInfo.name}</Col>
              <Col span={12} style={{ textAlign: "end" }}>
                {clientInfo.mobile_prefix} {clientInfo.mobile_no}
              </Col>
            </Row>
            <hr style={{ margin: "1rem 0" }} className="w-100" />
            <Row justify="space-between">
              <Col span={12}>Date & Time</Col>
              <Col span={12} style={{ textAlign: "end" }}>
                {clientInfo.booking_date}
              </Col>
            </Row>
            <hr style={{ margin: "1rem 0" }} className="w-100" />
            <Row justify="space-between">
              <Col span={12}>Stylist</Col>
              <Col span={12} style={{ textAlign: "end" }}>
                {clientInfo.staff_name ?? "Any Staff"}
              </Col>
            </Row>
            <hr style={{ margin: "1rem 0" }} className="w-100" />
            <Row justify="space-between">
              <Col span={12}>Services</Col>
              <Col span={12} style={{ textAlign: "end" }}>
                {clientInfo.service_name}
              </Col>
            </Row>
            <hr style={{ margin: "1rem 0" }} className="w-100" />
          </div>
        </div>
        <div className="container">
          <Form
            {...layout}
            name="appointment_date"
            initialValues={{}}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="booking-step-content">
              <Row
                gutter={20}
                justify="space-between"
                className="w-100"
                style={{ margin: "2rem 0" }}
              >
                <Col md={24} style={{ textAlign: "center" }}>
                  <button
                    key="button"
                    htmltype="button"
                    className="btn-secondary"
                    style={{ minWidth: "180px", marginBottom: "18px" }}
                    onClick={() => downloadICS()}
                  >
                    Set Reminder
                  </button>
                </Col>
                <Col md={24} style={{ textAlign: "center" }}>
                  <button
                    key="button"
                    htmltype="button"
                    className="btn-primary"
                    style={{ minWidth: "180px" }}
                    onClick={() => {
                      navigate("/appointment/" + booking_url);
                    }}
                  >
                    Book Another Appointment
                  </button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BookingCompleted;
