import { Avatar, Col, Form, Input, Row, message } from "antd";
import Icon from "@ant-design/icons";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import "../../../assets/css/Merchant/login.css";
import {
	submitBookingStep,
	LogoutAction,
} from "../../../actions/Merchant/actionCreators";

const MerchantSignupStep2_1 = () => {
	const [form] = Form.useForm()
	const dispatch = useDispatch();
	const api = useContext(AxiosContext);
	const [service, setService] = useState(3);
	const navigate = useNavigate();
	let serviceEle = [];

	const NextSvg = () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32.875"
			height="16.716"
			viewBox="0 0 32.875 16.716"
		>
			<path
				id="btn-next"
				d="M32.583,7.651,25.225.293A1,1,0,1,0,23.81,1.707l5.652,5.651H1a1,1,0,0,0,0,2H29.462L23.81,15.009a1,1,0,0,0,1.415,1.414l7.358-7.358a1,1,0,0,0,0-1.414"
				transform="translate(0 0)"
				fill="#ffffff"
			/>
		</svg>
	);

	const NextIcon = (props) => <Icon component={NextSvg} {...props} />;
	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	useEffect(() => {
		const signup_data = JSON.parse(localStorage.getItem("signup_data") ?? '[]');

		if(signup_data.service_names){
			setService(signup_data.service_names.length);
			Object.entries(signup_data.service_names).map(([index, value]) =>
				form.setFieldValue("service_names[" + index + "]", value)
			);
		}
	}, []);

	useEffect(() => {
		appendService();
	}, [service]);

	const addService = () => {
		setService(service + 1);
	};

	const handleNavigate = (step) => {
		if (step == "empty_shop_info") {
			navigate("/merchant/signupStep1");
		} else if (step == "empty_booking_step") {
			navigate("/merchant/signupStep2");
		} else if (step == "empty_service") {
			navigate("/merchant/signupStep2_1");
		} else if (step == "empty_staff") {
			navigate("/merchant/signup-step2_2");
		} else if (step == "empty_operation_hour") {
			navigate("/merchant/signupStep2_3");
		}
	};

	const postServiceData = async (param) => {
		const response = await submitBookingStep({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				if (
					typeof response.results.signup_step !== "undefined" &&
					response.results.signup_step != null
				) {
					localStorage.setItem("signup_data", JSON.stringify(response.results.signup_data ?? []));
					localStorage.setItem("steps", response.results.signup_step);
					handleNavigate(response.results.signup_step);
				}
				message.success(response.message, 5);
			}
		}
	};

	const handleLogout = () => {
		dispatch(LogoutAction({ api }));
	};

	const handleBack = (event) => {
		event.preventDefault();
		localStorage.setItem("steps", "empty_booking_step");
		navigate("/merchant/signupStep2");
	};

	const onFinish = async (values) => {
		const formData = new FormData();

		formData.append("signup_step", "empty_service");

		Object.entries(values).map(([key, value]) => {
			if (typeof value !== "undefined" && value != null && value != '') {
				formData.append("service_names[]", value);
			}
		});

		postServiceData(formData);
	};

	const onFinishFailed = () => {};

	const appendService = () => {
		for (let index = 0; index < service; index++) {
			serviceEle.push(
				<Form.Item
					key={index}
					className="merchant-form-item"
					name={"service_names[" + index + "]"}
				>
					<Input
						className="merchant-form-input"
						size="large"
						placeholder="Enter service name"
					/>
				</Form.Item>
			);
		}
	};

	appendService();

	return (
		<div className="container">
			<Row className="py-4" justify="space-between">
				<Col>
					<div className="MctLogoSmall">Logo</div>
				</Col>
				<Col>
					<div className="LoginBtn" onClick={handleLogout}>
						Logout
					</div>
				</Col>
			</Row>
			<Row justify="start" style={{ maxWidth: "500px", margin: "auto" }}>
				<Col>
					<div className="text-header">
						<div style={{ display: "flex", marginBottom: "1rem" }}>
							<Avatar
								size={50}
								className="merchant-avatar"
								icon={
									<img
										src="/icons/step-stylist.svg"
										style={{ width: 24, height: 24 }}
									></img>
								}
							/>
							Quick Set-up Service
						</div>
					</div>
					<p style={{ marginBottom: "1.5rem", color: "#8FA07E" }}>
						Set your services type
					</p>
				</Col>
			</Row>
			<Row
				justify="center"
				style={{
					maxWidth: "500px",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "2rem",
				}}
			>
				<Col span={24}>
					<Form form={form}
						{...layout}
						name="basic"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className="header-color"
					>
						{serviceEle}
						<div
							style={{
								display: "flex",
								alignContent: "center",
								margin: "20px 0",
								cursor: "pointer",
							}}
							onClick={addService}
						>
							<img
								src="/icons/btn-add.svg"
								style={{ marginRight: "10px" }}
							></img>
							<span style={{ lineHeight: 2 }}>
								Add service type
							</span>
						</div>
						<div className="text-muted">
							*Advance setting for photo & price can set later
						</div>
						<Row
							justify="space-between"
							style={{ margin: "2rem 0" }}
						>
							<Col>
								<button
									className="btn-clear"
									onClick={(e) => handleBack(e)}
								>
									<img src="/icons/btn-previous.svg"></img>
								</button>
							</Col>
							<Col>
								<button
									key="submit"
									htmltype="submit"
									className="btn-secondary"
									style={{ minWidth: "110px" }}
								>
									<NextIcon />
								</button>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default MerchantSignupStep2_1;
