import { HomeOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbLayout from "../../../../layouts/Merchant/breadcrumb";
import StylistLevel from "../../Company/StylistLevel";
import TimeRange from "react-time-range";
import InputField from "../../Global/FormInputComponent/InputField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import UploadField from "../../Global/FormInputComponent/UploadField";
import {
  fetchBusinessType,
  updateCompanySetting,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";

const MerchantCompanySettingForm = (props) => {
  const location = useLocation();
  const [data, setData] = useState(location.state.form);
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [date, setDate] = useState(0);
  const [bussId, setBussId] = useState("");
  const [addrId, setAddrId] = useState("");
  const [startMom, setStartMom] = useState([]);
  const [endMom, setEndMom] = useState([]);
  const [importData, setImportData] = useState({
    dataList: [],
  });
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);
  const navigate = useNavigate();
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let prefix_options = [];
  let country_options = [];
  let state_options = [];
  let city_options = [];
  let start_moment = "";
  let end_moment = "";
  let start_moments = [];
  let end_moments = [];

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Company Setting",
      link: "/merchant/company-setting",
    },
  ];

  const interval_options = [
    // {
    //   value: "15",
    //   desc: "15 Min",
    // },
    {
      value: 30,
      desc: "30 Min",
    },
    {
      value: 60,
      desc: "60 Min",
    },
  ];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  // const allowDigitOnly = (e) => {
  //   console.log(e.target.validity.valid);
  //   console.log(val);
  //   if (e.target.validity.valid) {
  //     setVal(e.target.value);
  //     form.setFieldValue({ mobile: e.target.value });
  //   } else {
  //     setVal(val);
  //     form.setFieldValue({ mobile: val });
  //   }
  // };
  const addDate = () => {
    setDate(date + 1);
    setStartMom({
      ...startMom,
      [date + startMom.length]: moment(start_moment, "HH:mm").add(1, "hours"),
    });

    setEndMom({
      ...endMom,
      [date + endMom.length]: moment(end_moment, "HH:mm").add(2, "hours"),
    });
  };

  const dateOnChange = (event) => {
    console.log(event);
  };

  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("_method", "PUT");
    formData.append("shop_name", values["shop_name"]);
    formData.append("display_shop_name", values["display_shop_name"]);
    formData.append("merchant_category_id", values["merchant_category_id"]);
    formData.append("mobile_prefix", values["mobile_prefix"]);
    formData.append("mobile_no", values["mobile_no"]);
    formData.append("email", values["email"]);
    formData.append("booking_url", values["booking_url"]);
    formData.append("addr", values["addr"]);
    formData.append("postcode", values["postcode"]);
    formData.append("city_id", values["city_id"]);
    formData.append("state_id", values["state_id"]);
    formData.append("country_id", values["country_id"]);
    formData.append("buffer_time", values["buffer_time"]);

    Object.values(startMom).map((hr, index) => {
      formData.append(
        `operation_hours_from[${index}]`,
        moment(hr, moment.ISO_8601).format("HH:mm")
      );
    });

    Object.values(endMom).map((hr, index) => {
      formData.append(
        `operation_hours_to[${index}]`,
        moment(hr, moment.ISO_8601).format("HH:mm")
      );
    });

    Object.values(values.operation_days).map((opr_day, index) => {
      if (opr_day) {
        if (Array.isArray(opr_day)) {
          formData.append(`operation_days[${index}]`, opr_day[0]);
        } else {
          formData.append(`operation_days[${index}]`, opr_day);
        }
      }
    });

    if (importData.dataList.length > 0) {
      importData.dataList.forEach((file) => {
        if (!file.url.includes("https://media.smartcubesoft.com/")) {
          formData.append("logo", file);
        }
      });
    } else {
      formData.append("remove_logo", 1);
    }

    formData.append("addr_id", addrId);

    postCompanySetting(formData);
  };

  const postCompanySetting = async (data) => {
    const response = await updateCompanySetting({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      navigate("/merchant/company-setting");
    }
  };

  const onFinishFailed = () => {};
  const onChange = () => {};
  const fetchBusinessTypeData = async () => {
    var param = {};
    param["status"] = "A";

    const response = await fetchBusinessType({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      createDataTree(response);
    }
  };

  const createDataTree = (dataset) => {
    const hashTable = Object.create(null);
    dataset.forEach(
      (aData) => (hashTable[aData.name] = { ...aData, childNodes: [] })
    );
    const dataTree = [];
    dataset.forEach((aData) => {
      if (aData.parent_name)
        hashTable[aData.parent_name].childNodes.push(hashTable[aData.name]);
      else dataTree.push(hashTable[aData.name]);
      if (aData.code == data.merchant_category_code) {
        setBussId(aData.id);
      }
    });

    return dataTree;
  };

  const setStartRange = (time) => {
    start_moments.push(moment(time, "HH:mm"));
    setStartMom(start_moments);
  };

  const setEndRange = (time) => {
    end_moments.push(moment(time, "HH:mm"));
    setEndMom(end_moments);
  };

  useEffect(() => {
    form.setFieldValue("merchant_category_id", bussId);
  }, [bussId]);

  useEffect(() => {
    fetchBusinessTypeData();
    if (data.hours) {
      data.hours.map((hrs) => {
        setEndRange(hrs.operation_hours_to);
        setStartRange(hrs.operation_hours_from);
      });
    }
    location.state.country.map((country, ind) => {
      country_options.push({
        value: country.id,
        desc: country.name,
      });

      prefix_options.push({
        value: country.calling_no_prefix,
        desc: (
          <div>
            <Image width={25} src={country.image_path} />{" "}
            {country.calling_no_prefix}
          </div>
        ),
      });

      if (data.country_code == country.code) {
        form.setFieldValue("country_id", country.id);
      }
    });

    setCountryOptions(country_options);
    setPrefixOptions(prefix_options);

    location.state.state.map((state, ind) => {
      state_options.push({
        value: state.id,
        desc: state.name,
      });

      if (data.state_code == state.code) {
        form.setFieldValue("state_id", state.id);
      }
    });
    setStateOptions(state_options);
    location.state.city.map((city, ind) => {
      city_options.push({
        value: city.id,
        desc: city.name,
      });

      if (data.city_code == city.code) {
        form.setFieldValue("city_id", city.id);
      }
    });
    setCityOptions(city_options);
    form.setFieldValue("buffer_time", data.hours[0].buffer_time);

    setAddrId(data.addr_id);

    if (data.logo) {
      setImportData({ dataList: [{ url: data.logo }] });
    } else {
      setImportData({ dataList: [] });
    }
  }, [data]);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Company Setting</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Form
            {...layout}
            form={form}
            name="company_form"
            initialValues={data}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col
                xs={24}
                lg={4}
                style={{
                  textAlign: "center",
                  marginBottom: "1rem",
                  position: "relative",
                }}
              >
                <Avatar
                  size={105}
                  style={{
                    backgroundColor: "#ffffff",
                  }}
                  icon={<img src={data.logo}></img>}
                />
                <UploadField
                  className="company"
                  name="logo"
                  setImportData={setImportData}
                  importData={importData}
                />
              </Col>
              <Col xs={24} lg={20}>
                <InputField
                  name="shop_name"
                  rules={[{ whitespace: true }]}
                  hasFeedback
                />
                <InputField
                  name="display_shop_name"
                  rules={[{ whitespace: true }]}
                  hasFeedback
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <Input.Group compact className="merchant-form-input">
                  <SelectField
                    mainClassName="merchant-mobile-height"
                    name="mobile_prefix"
                    options={prefixOptions}
                    style={{
                      width: "35%",
                      marginBottom: "0px",
                    }}
                    selectStyle={{ fontSize: "16px" }}
                    className="merchant-mobile-select"
                  />
                  <InputField
                    mainClassName="merchant-mobile-height"
                    name="mobile_no"
                    rules={[
                      {
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                    type="text"
                    pattern="[0-9]*"
                    style={{
                      width: "65%",
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                    className="merchant-input-mobile"
                    placeholder="Mobile number"
                  />
                </Input.Group>
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="email"
                  type="email"
                  rules={[{ whitespace: true }]}
                  hasFeedback
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <SelectField name="country_id" options={countryOptions} />
              </Col>
              <Col xs={24} md={12}>
                <SelectField name="state_id" options={stateOptions} />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} md={12}>
                <SelectField name="city_id" options={cityOptions} />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="postcode"
                  rules={[{ whitespace: true }]}
                  hasFeedback
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <InputField
                  name="addr"
                  rules={[{ whitespace: true }]}
                  hasFeedback
                />
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label="Business Type"
                  name="merchant_category_id"
                  className="merchant-form-item"
                  style={{ marginBottom: "10px" }}
                  disabled
                >
                  <InputField
                    name="merchant_category_name"
                    style={{ marginBottom: "10px" }}
                    disabled
                    className="merchant-form-input ant-input-disabled"
                  />
                  {/* <Select
                    className="merchant-form-select"
                    placeholder="Choose Business Type"
                  >
                    {businessOptions.map((main, key) => {
                      return main.childNodes.map((childs, key) => {
                        return (
                          <OptGroup label={childs.name} key={key}>
                            {childs.childNodes.map((nodes, index) => {
                              return (
                                <Option key={nodes.code} value={nodes.id}>
                                  {nodes.name}
                                </Option>
                              );
                            })}
                          </OptGroup>
                        );
                      });
                    })}
                  </Select> */}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <div style={{ display: "flex", position: "relative" }}>
                  <InputField
                    label="Set Booking Link"
                    rules={[{ whitespace: true }]}
                    hasFeedback
                    style={{ width: "50%" }}
                    disabled
                    className="merchant-form-input ant-input-disabled"
                    placeholder="www.smartbooking.com/"
                  />
                  <InputField
                    name="booking_url"
                    label="&nbsp;"
                    rules={[{ whitespace: true }]}
                    hasFeedback
                    style={{
                      width: "70%",
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                    }}
                    placeholder="Create Name"
                    disabled={data.remaining > 0 ? false : true}
                  />
                </div>
                <div
                  className="muted-color"
                  style={{ marginTop: "-1rem", marginBottom: "2rem" }}
                >
                  *Note: Only can edit 1 time.
                </div>
              </Col>
            </Row>
            <div className="font-color" style={{ marginBottom: "10px" }}>
              Operation Days
            </div>
            <Row>
              <Col xs={24} md={16}>
                <Row justify="space-between" style={{ marginBottom: "1.5rem" }}>
                  {days.map((day, ind) => {
                    if (data.hours) {
                      if (data.hours[0]["operation_days"].indexOf(day) != -1) {
                        form.setFieldsValue({
                          ["operation_days"]: { [ind]: day },
                        });
                      }
                    }
                    return (
                      <Col key={ind}>
                        <Card.Grid
                          className="merchant-date-card"
                          style={{
                            padding: "12px",
                            minWidth: "50px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <strong>{day}</strong>
                          <Form.Item noStyle name={["operation_days", [ind]]}>
                            <Checkbox.Group
                              label=""
                              className="merchant-checkbox"
                              options={[{ label: "", value: day }]}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Card.Grid>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
            <div className="font-color" style={{ marginBottom: "10px" }}>
              Operation Hours
            </div>
            <Row>
              <Col xs={24} md={16}>
                {data.hours
                  ? data.hours.map((hrs, index) => {
                      if (index == data.hours.length - 1) {
                        start_moment = moment(hrs.operation_hours_to, "HH:mm");
                        end_moment = moment(hrs.operation_hours_to, "HH:mm");
                      }

                      return (
                        <TimeRange
                          key={index}
                          id={"timerange_" + index}
                          className="merchant-time-range"
                          startLabel=""
                          startMoment={startMom[index]}
                          endLabel="TO"
                          endMoment={endMom[index]}
                          use24Hours
                          onChange={(event) => {
                            setStartMom({
                              ...startMom,
                              [index]: event.startTime,
                            });
                            setEndMom({
                              ...endMom,
                              [index]: event.endTime,
                            });
                          }}
                        />
                      );
                    })
                  : ""}
                {(() => {
                  let rows = [];
                  for (let i = 0; i < date; i++) {
                    rows.push(
                      <TimeRange
                        key={i + data.hours.length}
                        className="merchant-time-range"
                        startLabel=""
                        startMoment={startMom[i + data.hours.length]}
                        endLabel="TO"
                        endMoment={endMom[i + data.hours.length]}
                        use24Hours
                        onChange={(event) => {
                          setStartMom({
                            ...startMom,
                            [i + data.hours.length]: event.startTime,
                          });
                          setEndMom({
                            ...endMom,
                            [i + data.hours.length]: event.endTime,
                          });
                        }}
                      />
                    );
                  }
                  return rows;
                })()}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={addDate}
                >
                  <img
                    src="/icons/btn-add.svg"
                    style={{ marginRight: "10px" }}
                  ></img>
                  <span className="muted-color" style={{ lineHeight: 2 }}>
                    Add operation hours
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={16}>
                <div className="font-color" style={{ marginBottom: "10px" }}>
                  Booking Interval
                </div>
                <Row>
                  <Col xs={24} md={12}>
                    <SelectField
                      name="buffer_time"
                      options={interval_options}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item className="merchant-form-item">
                      <div
                        className="muted-color"
                        style={{ marginLeft: "1rem" }}
                      >
                        Eg: 30 mins or 1 hr
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "2rem 0" }}>
              <Col xs={15} lg={6}>
                <button htmltype="submit" className="btn-secondary w-100">
                  Save & Update
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3" }}
      >
        <StylistLevel />
      </Col>
    </Row>
  );
};

export default MerchantCompanySettingForm;
