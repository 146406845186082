import { HomeOutlined } from "@ant-design/icons";
import { Col, Form, Row, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbLayout from "../../../../layouts/Merchant/breadcrumb";
import InputField from "../../Global/FormInputComponent/InputField";
import PriceSettingPreviewModal from "../../Modals/PriceSettingPreviewModal";
import { updatePrice } from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";

export default function PriceSettingForm(props) {
  const location = useLocation();
  const { Title } = Typography;
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formField, setFormField] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const onFinish = async (values) => {
    const formData = new FormData();

    formData.append("price_list_type_code", "html");
    formData.append("desc", JSON.stringify(values));

    const response = await updatePrice({
      api,
      data: formData,
      dispatch,
    });

    if (response) {
      if (response.code === 200) {
        message
          .success(response.message, 5)
          .then(navigate("/merchant/price-setting"));
      }
    }
  };
  const addField = (type) => {
    setFormField((prevState) => {
      return [
        ...prevState,
        {
          type: type,
        },
      ];
    });
  };

  const removeField = (index, type) => {
    var array = [...formField];
    let first = 0;
    let new_service_array = [];
    let new_price_array = [];
    let new_title_array = [];

    if (index !== -1) {
      var service_key = Object.keys(form.getFieldValue("service_type") ?? {});
      var service_array = Object.values(
        form.getFieldValue("service_type") ?? {}
      );

      first = 0;
      service_key.map((s_key, s_ky) => {
        if (s_key != index) {
          new_service_array[s_key] = "";
          if (first == 0) {
            if (s_key > index) {
              new_service_array[s_key - 1] = service_array[s_ky];
            } else {
              new_service_array[s_key] = service_array[s_ky];
            }
          } else {
            new_service_array[s_key - 1] = service_array[s_ky];
          }
        } else {
          first = 1;
        }
      });

      var price_key = Object.keys(form.getFieldValue("price") ?? {});
      var price_array = Object.values(form.getFieldValue("price") ?? {});

      first = 0;
      price_key.map((p_key, p_ky) => {
        if (p_key != index) {
          new_price_array[p_key] = "";
          if (first == 0) {
            if (p_key > index) {
              new_price_array[p_key - 1] = price_array[p_ky];
            } else {
              new_price_array[p_key] = price_array[p_ky];
            }
          } else {
            new_price_array[p_key - 1] = price_array[p_ky];
          }
        } else {
          first = 1;
        }
      });

      var title_key = Object.keys(form.getFieldValue("title") ?? {});
      var title_array = Object.values(form.getFieldValue("title") ?? {});

      first = 0;
      title_key.map((t_key, t_ky) => {
        if (t_key != index) {
          new_title_array[t_key] = "";
          if (first == 0) {
            if (t_key > index) {
              new_title_array[t_key - 1] = title_array[t_ky];
            } else {
              new_title_array[t_key] = title_array[t_ky];
            }
          } else {
            new_title_array[t_key - 1] = title_array[t_ky];
          }
        } else {
          first = 1;
        }
      });

      form.setFieldsValue({
        ["service_type"]: { ...new_service_array },
      });
      form.setFieldsValue({
        ["price"]: { ...new_price_array },
      });
      form.setFieldsValue({
        ["title"]: { ...new_title_array },
      });

      array.splice(index, 1);
      setFormField(array);
    }
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  const previewOnClick = () => {
    setModalVisible(true);
  };

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
    },
    {
      label: "Price Setting",
      link: "/merchant/price-setting",
    },
  ];

  useEffect(() => {
    setFormField([{ type: "title" }, { type: "service" }]);
  }, []);

  useEffect(() => {
    if (location?.state) {
      if (Object.values(location?.state?.json).length != 0) {
        let newFieldArr = [];
        [...location?.state?.desc].map((len, index) => {
          if (location?.state?.json?.title?.[index]) {
            newFieldArr.push({ type: "title" });
            form.setFieldValue(
              ["title", index],
              location?.state?.json?.title?.[index]
            );
          } else if (location?.state?.json?.service_type?.[index]) {
            newFieldArr.push({ type: "service" });
            form.setFieldValue(
              ["service_type", index],
              location?.state?.json?.service_type?.[index]
            );
            form.setFieldValue(
              ["price", index],
              location?.state?.json?.price?.[index]
            );
          }
        });
        setFormField(newFieldArr);
      }
    }
  }, [location?.state]);

  return (
    <>
      <div className="merchant-content-header">
        <Title level={4} style={{ marginBottom: "0" }}>
          <strong>Price Setting</strong>
        </Title>
        <BreadcrumbLayout items={breadcrumbItem} />
      </div>
      <div style={{ margin: "0 25px" }}>
        <p>Create your price list</p>
        <div className="price-header">
          <span>Upload your price list</span>
          <small
            className="muted-color"
            style={{ lineHeight: 2, paddingLeft: "5px" }}
          >
            (File format: jpg, pdf)
          </small>
          <Link to="/merchant/price-setting">
            <button
              htmltype="button"
              className="btn-secondary"
              style={{ padding: "5px 15px", margin: "0 1rem" }}
            >
              <strong>SEE UPLOADED</strong>
            </button>
          </Link>
          <span>Or</span>
          {location?.state ? (
            Object.values(location?.state?.json).length != 0 ? (
              <button
                htmltype="button"
                className="btn-secondary"
                style={{ padding: "5px 15px", margin: "0 1rem" }}
              >
                <strong>EDIT PRICES LIST</strong>
              </button>
            ) : (
              ""
            )
          ) : (
            <button
              htmltype="button"
              className="btn-secondary"
              style={{ padding: "5px 15px", margin: "0 1rem" }}
            >
              <strong>CREATE PRICES LIST</strong>
            </button>
          )}
        </div>
        <Form form={form} name="price_form" onFinish={onFinish}>
          <Row style={{ margin: "2rem 0" }} justify="center" align="center">
            <Col span={24} style={{ textAlign: "center" }}>
              <div
                style={{ border: "1px solid #D3DEC3", padding: "2rem" }}
                id="price_form_ele"
              >
                {formField.map((item, index) => {
                  if (item.type == "title") {
                    return (
                      <Row
                        justify="center"
                        style={{ marginBottom: "1rem" }}
                        key={index}
                      >
                        <Col xs={24} md={9}>
                          <div style={{ display: "flex" }}>
                            <img
                              src="/icons/btn-minus.svg"
                              style={{ paddingRight: "1rem" }}
                              onClick={() => {
                                removeField(index, item.type);
                              }}
                            ></img>
                            <InputField
                              name={["title", [index]]}
                              rules={[{ whitespace: true }]}
                              hasFeedback
                              placeholder="Enter Title Name"
                              style={{ marginBottom: 0, width: "100%" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  } else if (item.type == "service") {
                    return (
                      <Row
                        justify="center"
                        style={{ marginBottom: "1rem" }}
                        key={index}
                      >
                        <Col span={24}>
                          <Row
                            justify="space-between"
                            style={{
                              alignItems: "center",
                            }}
                          >
                            <Col>
                              <div style={{ display: "flex" }}>
                                <img
                                  src="/icons/btn-minus.svg"
                                  onClick={() => {
                                    removeField(index, item.type);
                                  }}
                                  style={{ paddingRight: "1rem" }}
                                ></img>
                                <InputField
                                  name={["service_type", [index]]}
                                  rules={[{ whitespace: true }]}
                                  hasFeedback
                                  placeholder="Enter Service Name"
                                  style={{ marginBottom: 0 }}
                                />
                              </div>
                            </Col>
                            <Col flex="auto">
                              <div className="dotted-line" />
                            </Col>
                            <Col>
                              <InputField
                                name={["price", [index]]}
                                rules={[{ whitespace: true }]}
                                hasFeedback
                                placeholder="Enter Price"
                                style={{ marginBottom: 0 }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }
                })}
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    marginTop: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addField("service");
                  }}
                >
                  <img
                    src="/icons/btn-add.svg"
                    style={{ marginRight: "10px" }}
                  ></img>
                  <span className="muted-color" style={{ lineHeight: 2 }}>
                    Add Service Type & Prices
                  </span>
                </div>
                <hr style={{ margin: "1rem 0" }} />
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    margin: "20px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addField("title");
                  }}
                >
                  <img
                    src="/icons/btn-add.svg"
                    style={{ marginRight: "10px" }}
                  ></img>
                  <span className="muted-color" style={{ lineHeight: 2 }}>
                    Add Title
                  </span>
                </div>
                <hr style={{ margin: "1rem 0" }} />
              </div>
              <Row gutter={20} style={{ margin: "2rem 0" }}>
                <Col xs={15} lg={3}>
                  <button
                    htmltype="submit"
                    className="btn-secondary w-100"
                    style={{ padding: "5px 15px" }}
                  >
                    <strong>Save</strong>
                  </button>
                </Col>
                <Col xs={15} lg={3}>
                  <button
                    htmltype="button"
                    className="btn-clear w-100"
                    style={{ padding: "5px 15px" }}
                    onClick={previewOnClick}
                  >
                    <strong>Preview</strong>
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
      <PriceSettingPreviewModal
        visible={modalVisible}
        onCancel={onCancel}
        formData={form.getFieldsValue()}
        formField={formField}
      />
    </>
  );
}
