import Icon from "@ant-design/icons";
import { Form, Image, Input, Row, Select, Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchTerritoryData,
  getStepDetails,
  postClientInfos,
} from "../../../actions/Member/actionCreators";
import { AxiosContext } from "../../../actions/Member/Axios/axios";

const BookingStep1 = () => {
  const { Step } = Steps;
  const { Option } = Select;
  const { booking_url } = useParams();
  const location = useLocation();
  const tmpUserId = location.state ? location.state.id : "";
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const [val, setVal] = useState("");
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [userId, setUserId] = useState(tmpUserId);
  const [clientInfo, setClientInfo] = useState("");
  const [logo, setLogo] = useState("");
  const [step, setStep] = useState([]);
  const [nxtStep, setNxtStep] = useState("");

  const NextSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.875"
      height="16.716"
      viewBox="0 0 32.875 16.716"
    >
      <path
        id="btn-next"
        d="M32.583,7.651,25.225.293A1,1,0,1,0,23.81,1.707l5.652,5.651H1a1,1,0,0,0,0,2H29.462L23.81,15.009a1,1,0,0,0,1.415,1.414l7.358-7.358a1,1,0,0,0,0-1.414"
        transform="translate(0 0)"
        fill="#ffffff"
      />
    </svg>
  );

  const NextIcon = (props) => <Icon component={NextSvg} {...props} />;

  const handlePrefixChange = () => {
    let mobile_prefix = form.getFieldValue("mobile_prefix");

    var filtered_prefix = prefixOptions.filter((item) => {
      return item.calling_no_prefix === mobile_prefix;
    });

    form.setFieldsValue({
      mobile_prefix: filtered_prefix[0]["id"],
    });
  };

  const fetchCountryData = async () => {
    var param = {};
    param["territory_type"] = "country";
    param["status"] = "A";

    const response = await fetchTerritoryData({
      data: param,
      api,
      dispatch,
    });

    if (response) {
      setPrefixOptions(response);
      form.setFieldsValue({
        mobile_prefix: "60",
      });
    }
  };

  const fetchStepDetails = async () => {
    var param = {};
    // param["booking_url"] = booking_url;
    param["current_step"] = "CLIENTINFO";
    if (userId) {
      param["tmp_user_id"] = userId;
    }

    const response = await getStepDetails({
      data: param,
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setUserId(response.tmp_user_id);
      setClientInfo(response.current_step_values);
      setLogo(response.logo);
      setNxtStep(response.next_step);
      setStep(response.booking_steps);
    }
  };

  const postClientInfo = async (data) => {
    data["tmp_user_id"] = userId;
    let param = [];
    param["booking_url"] = booking_url;
    param["step_code"] = "CLIENTINFO";

    const response = await postClientInfos({
      api,
      data: data,
      param: param,
      dispatch,
    });

    if (response) {
      let step = "";
      if (nxtStep == "SVN") {
        step = "/step1";
      } else if (nxtStep == "STY") {
        step = "/step2";
      } else if (nxtStep == "DTE") {
        step = "/step3";
      } else if (nxtStep == "COM") {
        step = "/completed";
      }

      navigate("/appointment/" + booking_url + step, {
        state: { id: userId },
      });
    }
  };

  const onFinish = (values) => {
    postClientInfo(values);
  };

  const onFinishFailed = () => {};

  useEffect(() => {
    fetchCountryData();
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (clientInfo.length > 0 || clientInfo.name) {
      form.setFieldsValue({
        mobile_no: clientInfo.mobile_no,
        mobile_prefix: clientInfo.mobile_prefix,
        name: clientInfo.name,
      });
    }
  }, [clientInfo]);

  return (
    <div className="booking-bg">
      <div className="booking-bg-dark">
        <div className="booking-step-content">
          {logo ? (
            <img src={logo} className="member-merchant-logo" />
          ) : (
            <h3>LOGO</h3>
          )}
          <Steps current={0} size="small" labelPlacement="vertical">
            {step
              ? step.map((stp, ky) => {
                  return <Step key={ky} title={stp.name} />;
                })
              : ""}
          </Steps>

          <h4>CLIENT INFO</h4>
          <p style={{ color: "#C9C9C9", marginBottom: "2rem" }}>
            Get started booking in seconds
          </p>

          <Form
            name="appointment_client"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              className="merchant-form-item"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                className="merchant-form-input"
                size="large"
                placeholder="Name"
              />
            </Form.Item>
            <Input.Group
              compact
              className="merchant-form-input"
              style={{
                height: "42px",
                paddingTop: "2px",
              }}
            >
              <Form.Item name="mobile_prefix">
                <Select
                  name="mobile_prefix"
                  className="merchant-mobile-select"
                  // onChange={handlePrefixChange}
                >
                  {prefixOptions.map(
                    ({ calling_no_prefix, code, image_path }, index) => (
                      <Option key={code} value={calling_no_prefix}>
                        <Image width={25} src={image_path} />{" "}
                        {calling_no_prefix}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="mobile_no"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                ]}
              >
                <Input
                  type="text"
                  pattern="[0-9]*"
                  style={{
                    padding: "0px",
                  }}
                  className="merchant-input-mobile"
                  placeholder="Mobile number"
                  value={val}
                  onChange={(e) =>
                    setVal((v) =>
                      e.target.validity.valid ? e.target.value : v
                    )
                  }
                />
              </Form.Item>
            </Input.Group>
            <Row justify="center" style={{ margin: "2rem 0" }}>
              <button htmltype="submit" className="btn-secondary w-100">
                <NextIcon />
              </button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BookingStep1;
