import * as action_types from "./actionTypes";
import * as action from "./action";

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const createShopInfo = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/shop`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const fetchBusinessType = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/categories`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const fetchTerritoryData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/territories`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const fetchSysGeneralData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/settings/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const fetchBookingStepData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/booking/steps`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createBookingStep = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/shop/booking_step`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const submitBookingStep = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/step`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const ValidateOtpAction = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/otp/${data.otp_id}/${data.otp_code}`,
    method: `PUT`,
    data,
  });

  return response.data;
};

export const ResendOtpAction = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/otp`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const SignupAction = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const fetchCompanySetting = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/company`,
    method: `GET`,
  });

  return response.data.results;
};

export const fetchHolidayList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/merchant_holiday?page=1&page_length=3&status=A`,
    method: "GET",
  });

  return response.data.page_items;
};

export const createHoliday = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/merchant_holiday`,
    method: "POST",
    data,
  });

  return response.data;
};

export const fetchAllHoliday = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/merchant_holiday/all?status=A`,
    method: "GET",
  });

  return response.data.results;
};

export const updateCompanySetting = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/company`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updatePassword = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/password`,
    method: "PUT",
    data,
  });

  return response.data;
};

export const updateCheckHoliday = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/merchant_holiday/check`,
    method: "PUT",
    data,
  });
  return response.data;
};

export const fetchStaffTitle = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/staff_title/all`,
    method: `GET`,
  });

  return response.data.results;
};

export const fetchServices = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/products/all`,
    method: `GET`,
    data,
  });

  return response.data.results;
};

export const createService = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/products`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateService = async ({ id, data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/products/${id}`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateServiceStatus = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/products/status/${data.id}`,
    method: "PUT",
    data,
  });
  return response.data;
};

export const fetchStaff = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/staffs/all`,
    method: `GET`,
  });

  return response.data.results;
};

export const createStaff = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/staffs`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateStaff = async ({ data, id, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/staffs/${id}`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateStaffStatus = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/staffs/status/${data.id}`,
    method: "PUT",
    data,
  });
  return response.data;
};

export const fetchPrice = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/price_list/all`,
    method: "GET",
  });

  return response.data.results;
};

export const updatePrice = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/price_list`,
    method: "POST",
    data,
  });
  return response.data;
};

export const fetchClient = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/client/all`,
    method: "GET",
  });

  return response.data.results;
};

export const createClient = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/client`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateClient = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/client/${data.id}`,
    method: "PUT",
    data,
  });
  return response.data;
};

export const getAppointment = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments/all`,
    method: "GET",
    params: data,
  });

  return response.data.results;
};

export const getAptDetails = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments/${data.id}`,
    method: "GET",
    params: data,
  });

  return response.data.results;
};

export const getAvailableDates = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments/dates?booking_date=${data.booking_date}`,
    method: "GET",
    params: data,
  });

  return response.data.results;
};

export const createAppointment = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments`,
    method: "POST",
    data,
  });

  return response.data;
};

export const editAppointment = async ({ data, api, id }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments/${id}`,
    method: "POST",
    data,
  });

  return response.data;
};

export const updateAppointmentStatus = async ({ data, api, id }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/appointments/status/${id}`,
    method: "POST",
    data,
  });

  return response.data;
};

export const fetchPreviousBookingStep = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/merchant/register/previous_step`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const LoginAction = ({ data, api }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}/merchant/login`,
      method: "POST",
      data,
    }).then((result) => {
      localStorage.setItem(
        "merchant_token",
        "Bearer " + result.data.results.token
      );
      localStorage.setItem("merchant_name", result.data.results.name);
      localStorage.setItem("steps", result.data.results.signup_step);
      localStorage.setItem(
        "category",
        result.data.results.signup_data.category_code
      );

      dispatch(
        action.userSessionAuthenticate(action_types.userSessionAuthenticate)
      );

      return dispatch(
        action.MerchantLoginResult(
          action_types.storeLoginToken,
          result.data.results.token,
          result.data.results.name
        )
      );
    });
  };
};

export const LogoutAction = ({ api }) => {
  return (dispatch) => {
    return dispatch(action.UpdateLoginResult(action_types.removeLoginToken));
  };
};
