import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Table, Tag, Button } from "antd";

export default function DatatableMerchantCategory(props) {
	const columns = [
		{
			title: "No",
			key: "id",
			render: (value, item, index) =>
				(props.pagination.current - 1) * props.pagination.pageSize +
				index +
				1,
		},
		{
			title: "Parent",
			dataIndex: "parent_name",
			key: "parent_name",
			sorter: (a, b) => {
				if (
					a &&
					a.parent_name &&
					a.parent_name.length &&
					b &&
					b.parent_name &&
					b.parent_name.length
				) {
					return a.parent_name.length - b.parent_name.length;
				} else if (a && a.parent_name && a.parent_name.length) {
					// That means be has null rechargeType, so a will come first.
					return -1;
				} else if (b && b.parent_name && b.parent_name.length) {
					// That means a has null rechargeType so b will come first.
					return 1;
				}
				// Both rechargeType has null value so there will be no order change.
				return 0;
			},
		},
		{
			title: "Merchant Code",
			dataIndex: "code",
			key: "code",
			sorter: (a, b) => a.code.localeCompare(b.code),
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Seq. Number",
			dataIndex: "seq_no",
			key: "seq_no",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (_, { status }) => (
				<>
					<Tag
						color={status == "Active" ? "green" : "red"}
						key={status}
					>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			sorter: (a, b) => a.created_at.localeCompare(b.created_at),
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Button
						icon={<EditOutlined />}
						onClick={() => {
							props.showMerchantCategoryFormModal(record);
						}}
					></Button>
					<Button
						icon={<DeleteOutlined />}
						onClick={() => {
							props.handleRemoveRecord(record);
						}}
					></Button>
				</Space>
			),
		},
	];
	return (
		<Table
			columns={columns}
			rowKey={(record) => record.id}
			dataSource={props.dataSource}
			pagination={props.pagination}
			loading={props.loading}
			onChange={props.handleTableChange}
		/>
	);
}
