import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import { Avatar, Col, Form, Modal, Row, Select, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  fetchStaff,
  getAppointment,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
import SelectField from "../Global/FormInputComponent/SelectField";
import moment from "moment";

const CalendarWeekModal = (props) => {
  const calendarRef = React.useRef(null);
  const [form] = Form.useForm();
  const [staff, setStaff] = useState([]);
  const [appoint, setAppoint] = useState([]);
  const [show, setShow] = useState(false);
  let staff_options = [];
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();

  const getStaff = async () => {
    const response = await fetchStaff({ api, dispatch });

    if (response) {
      response.map((stf, key) => {
        staff_options.push({
          merchant_staff_title_name: stf.merchant_staff_title_name,
          img_path: stf.img_path,
          desc: stf.name,
          value: stf.id,
        });
      });
      setStaff(staff_options);
    }
  };

  const handleFilterStaff = async (e) => {
    let array = [];
    staff.map((stf, ind) => {
      if (stf.value == e) {
        array[0] = staff[ind];
      }
    });

    const response = await assignAppointment(e, 0, array);
    return response;
  };

  const assignAppointment = async (stf_id, key, array) => {
    let data = {};
    data["staff_id"] = stf_id;
    let response = await getAppointment({ data, api, dispatch });
    let appointment_array = [];
    if (response) {
      response.map(async (res, res_key) => {
        appointment_array.push({
          id: res.id,
          title: res.name,
          start: moment(res.booking_date).toISOString(),
          end: moment(res.booking_date)
            .add(res.total_duration, "minutes")
            .toISOString(),
        });
      });
    }
    array[key] = { ...array[key], appointment: appointment_array };
    setAppoint(array);
  };

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    if (props.visible) {
      //open le modal
      setTimeout(function () {
        setShow(true);
      }, 400);
    } else {
      setShow(false);
    }
  }, [props.visible]);

  return (
    <Modal
      className="calendar-modal"
      title={null}
      open={props.visible}
      centered
      onCancel={props.onCancel}
      footer={null}
      forceRender
      width={1000}
      bodyStyle={{ padding: "0px", height: "80vh" }}
    >
      <Row style={{ background: "#4086f5", padding: "20px 25px 0px" }}>
        <Col span={6}>
          <Form
            name="calendar-modal-form"
            form={form}
            initialValues={{ filter: "" }}
          >
            <Form.Item
              className="merchant-form-item"
              name="stylist"
              style={{ marginBottom: "0px" }}
            >
              <SelectField
                name="filter"
                className="merchant-form-select merchant-calendar-select"
                options={staff}
                onChange={handleFilterStaff}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <div className="custom-calendar-week-modal">
        {show ? (
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            slotMinTime="09:00:00"
            slotMaxTime="21:00:00"
            allDaySlot={false}
            headerToolbar={{
              left: "prev title next",
              center: "",
              right: "myCustomWeekButton myCustomMonthButton",
            }}
            titleFormat={{
              year: "numeric",
              month: "long",
            }}
            height={600}
            ref={calendarRef}
            customButtons={{
              myCustomWeekButton: {
                text: "Week",
                click: function () {
                  // props.setTimeView("timeGridWeek");
                  props.setWeekModalVisible(true);
                  props.setMonthModalVisible(false);
                },
              },
              myCustomMonthButton: {
                text: "Month",
                click: function () {
                  // props.setTimeView("dayGridMonth");
                  props.setWeekModalVisible(false);
                  props.setMonthModalVisible(true);
                },
              },
            }}
            events={appoint.length > 0 ? appoint[0].appointment : []}
          />
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          display: "flex",
          padding: "10px 2rem",
          background: "#fff",
          alignItems: "center",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Avatar
          size={18}
          style={{ marginRight: "5px", backgroundColor: "#F0F0F0" }}
        />
        Off Day
        <Avatar
          size={18}
          style={{
            marginRight: "5px",
            marginLeft: "20px",
            backgroundColor: "#00FF00",
          }}
        />
        Available
        <Avatar
          size={18}
          style={{
            marginRight: "5px",
            marginLeft: "20px",
            backgroundColor: "#FDDE3C",
          }}
        />
        Less Slot
        <Avatar
          size={18}
          style={{
            marginRight: "5px",
            marginLeft: "20px",
            backgroundColor: "#FF6200",
          }}
        />
        Fully Booked
      </div>
    </Modal>
  );
};

export default CalendarWeekModal;
