import React from "react";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space, Table, Tag, Button } from 'antd';

export default function DatatableMerchantStaff(props) {
    const columns = [
		{
			title: "No",
			key: "id",
			render: (value, item, index) => index + 1,
		},
		{
			title: "Merchant Name",
			dataIndex: "merchant_name",
			key: "merchant_name",
			sorter: (a, b) => a.merchant_name.localeCompare(b.merchant_name),
		},
        {
			title: "Full Name",
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => a.email.localeCompare(b.email),
		},
        {
			title: "Date of Birth",
			dataIndex: "dob",
			key: "dob",
			sorter: (a, b) => a.dob.localeCompare(b.dob),
		},
        {
			title: "Mobile No.",
			dataIndex: "mobile_no",
			key: "mobile_no",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (_, { status }) => (
				<>
					<Tag
						color={status == "Active" ? "green" : "red"}
						key={status}
					>
						{status.toUpperCase()}
					</Tag>
				</>
			),
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			sorter: (a, b) => a.created_at.localeCompare(b.created_at),
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
		},
		{
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        icon={<EditOutlined />}
                        onClick={() => {
                            props.showMerchantStaffFormModal(record);
                        }}>
                    </Button>
                    <Button 
                        icon={<DeleteOutlined />}
                        onClick={() => {
													props.handleRemoveRecord(record);
                        }}>
                    </Button>
                </Space>
            ),
        },
	];
    return(
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={props.dataSource}
            pagination={props.pagination}
            loading={props.loading}
            onChange={props.handleTableChange}
        />
    );
}
