import { HomeOutlined } from "@ant-design/icons";
import { Col, Form, message, Popconfirm, Row, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchServices,
  updateServiceStatus,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import ServiceForm from "../Form/Service/ServiceForm";
import CardSetting from "../Global/SettingComponent/CardSetting";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faBan, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

library.add(fas, faBan, faCirclePlus);

const MerchantServiceSetting = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [service, setService] = useState([]);
  const color = ["#4086F4", "#A7B397", "#F440E6", "#5D9F05"];
  let key = 0;
  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Service Setting",
      link: "/merchant/service-setting",
    },
  ];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const cardActionOnClick = (event) => {
    let index = event.target.getAttribute("data-ind");

    setData(service[index]);
  };

  const handleStatus = async (e) => {
    e.stopPropagation();
    let data = {};
    data["status"] = e.currentTarget.getAttribute("data-status");
    data["id"] = e.currentTarget.getAttribute("data-id");

    const response = await updateServiceStatus({ api, data, dispatch });

    if (response) {
      message.success(response.message);
      getServices();
    }
  };

  const getServices = async () => {
    const response = await fetchServices({ api, dispatch });

    if (response) {
      setService(response);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Service Setting</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Row gutter={20} justify="end">
            <Col>
              <button
                htmltype="button"
                className="btn-secondary w-100"
                onClick={() => {
                  setData([]);
                }}
                style={{ marginBottom: "2rem" }}
              >
                Add new Service
              </button>
            </Col>
          </Row>
          <Row gutter={20}>
            {service.length > 0
              ? service.map((svs, index) => {
                  if (!color[key]) {
                    key = 0;
                  }

                  let set_color = color[key];

                  key++;
                  return (
                    <Col
                      xs={24}
                      md={12}
                      style={{ marginBottom: "2rem" }}
                      key={index}
                    >
                      <CardSetting
                        avatar={svs.avatar}
                        color={svs.status == "Active" ? set_color : ""}
                        background={svs.status == "Inactive" ? "#bfbfbf" : ""}
                        name={svs.name}
                        detail={svs.duration + " " + svs.duration_type}
                        desc={svs.desc}
                        action={
                          <div style={{ display: "flex" }}>
                            {svs.status == "Active" ? (
                              <Tooltip title="Inactive Service">
                                <Popconfirm
                                  placement="top"
                                  title="Are you sure you want to inactivate this service?"
                                  onConfirm={handleStatus}
                                  okButtonProps={{
                                    "data-id": svs.id,
                                    "data-status": "I",
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-ban"
                                    style={{
                                      color: "#90a17e",
                                      fontSize: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Activate Service">
                                <Popconfirm
                                  placement="top"
                                  title="Are you sure you want to activate this service?"
                                  onConfirm={handleStatus}
                                  okButtonProps={{
                                    "data-id": svs.id,
                                    "data-status": "A",
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-circle-plus"
                                    style={{
                                      color: "#90a17e",
                                      fontSize: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </Popconfirm>
                              </Tooltip>
                            )}
                            <img
                              src="/icons/icon-edit.svg"
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                              onClick={cardActionOnClick}
                              data-ind={index}
                            />
                          </div>
                        }
                        priceDesc={
                          svs.prices.length > 0 ? (
                            <div
                              style={{ display: "flex", marginTop: "1rem " }}
                            >
                              <span
                                style={{
                                  color: "#808080",
                                  paddingRight: ".5rem",
                                }}
                              >
                                Price:
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {svs.prices.map((price, key) => {
                                  let price_html = "";

                                  if (price.min_price >= price.max_price) {
                                    //only set min price (only have 1 price)
                                    price_html += `${price.currency_code} ${price.min_price} (${price.name})`;
                                  } else {
                                    price_html += `${price.currency_code} ${price.min_price} - ${price.currency_code} ${price.max_price} (${price.name}) `;
                                  }
                                  return (
                                    <div
                                      key={key}
                                      dangerouslySetInnerHTML={{
                                        __html: price_html,
                                      }}
                                    ></div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        }
                      />
                    </Col>
                  );
                })
              : ""}
          </Row>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3" }}
      >
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Add Service</strong>
          </Title>
        </div>
        <div style={{ margin: "0 25px" }}>
          <ServiceForm data={data} getServices={getServices} />
        </div>
      </Col>
    </Row>
  );
};

export default MerchantServiceSetting;
