import React from "react";
import { Form, AutoComplete } from "antd";

export default function AutoCompleteField(props) {
	return (
		<Form.Item
			name={props.name}
			label={props.label}
			rules={props.rules}
			hasFeedback={props.hasFeedback}
			extra={props.extra}
			style={props.style}
		>
			<AutoComplete
				options={props.options}
				placeholder={props.placeholder}
				onChange={props.searchOnChange}
				onSelect={props.onSelect}
			/>
		</Form.Item>
	);
}
