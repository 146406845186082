import React from "react";
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Button } from 'antd';

export default function DatatableLanguage(props) {
    const columns = [
        {
            title: 'No',
            key: 'id',
            render: (value, item, index) =>
				(props.pagination.current - 1) * props.pagination.pageSize +
				index +
				1,
        },
        {
            title: 'Locale',
            dataIndex: 'locale',
            key: 'locale',
            sorter: (a, b) => a.locale.localeCompare(b.locale),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Image',
            key: 'avatar',
            dataIndex: 'avatar',
            width: '5%',
            render: (_, { avatar }) => (
            <>
                {avatar ? <Image src={avatar} /> : ''}
            </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <>
                    <Tag color={status == 'Active' ? 'green' : 'red'} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => a.created_at.localeCompare(b.created_at),
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        icon={<EditOutlined />}
                        onClick={() => {
                            props.showLanguageFormModal(record);
                        }}>
                    </Button>
                    <Button 
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            props.handleRemoveRecord(record)
                        }}>
                    </Button>
                </Space>
            ),
        },
    ];

    
    return(
        <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={props.dataSource}
            pagination={props.pagination}
            loading={props.loading}
            onChange={props.handleTableChange}
        />
    );
}