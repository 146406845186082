import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const BreadcrumbLayout = (props) => {
    return (
        <Breadcrumb style={{marginBottom: '20px'}}>
            {props.items?.map( (item, key) => {
                return(
                    <Breadcrumb.Item key={key}>
                        {item.link ? 
                        <Link to={item.link} >
                            {item.icon}
                            {item.label ? <span> {item.label} </span> : ''}
                        </Link> : 
                        <>
                            {item.icon}
                            {item.label ? <span> {item.label} </span> : ''}
                        </>
                        }
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>
    )
}

export default BreadcrumbLayout;