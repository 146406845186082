import { Col, Form, Input, message, Modal, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import InputField from "../Global/FormInputComponent/InputField";

const { Title } = Typography;

const ForgotPasswordModal = (props) => {
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    updtPassword(values);
  };
  const updtPassword = async (data) => {
    const response = await updatePassword({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      props.onCancel();
    }
  };
  const onFinishFailed = () => {};
  const [form] = Form.useForm();

  return (
    <Modal
      title={null}
      open={props.visible}
      centered
      onCancel={props.onCancel}
      footer={null}
      forceRender
      className="fpw-modal text-center"
      width={427}
      bodyStyle={{ padding: "30px" }}
    >
      <Title level={4} style={{ marginBottom: "2rem" }}>
        <strong>Change Password</strong>
      </Title>
      <Form
        name="forgotPwForm"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row>
          <Col span={24}>
            <InputField
              name="current_password"
              rules={[{ whitespace: true }]}
              hasFeedback
              type="password"
              placeholder="Current Password"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InputField
              name="new_password"
              rules={[{ whitespace: true }]}
              hasFeedback
              type="password"
              placeholder="New Password"
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <InputField
              name="confirm_password"
              dependencies={["new_password"]}
              rules={[{ whitespace: true }]}
              hasFeedback
              type="password"
              placeholder="Retype Password"
            />
          </Col>
        </Row>
        <button htmltype="submit" className="w-100 btn-secondary">
          Submit
        </button>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordModal;
