import Icon from "@ant-design/icons";
import { Checkbox, Col, Form, Row, Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getStepDetails,
  postClientInfos,
} from "../../../actions/Member/actionCreators";
import { AxiosContext } from "../../../actions/Member/Axios/axios";
import CardSetting from "../../Merchant/Global/SettingComponent/CardSetting";

const BookingStep2 = () => {
  const { Step } = Steps;
  const navigate = useNavigate();
  const { booking_url } = useParams();
  const location = useLocation();
  const tmpUserId = location.state ? location.state.id : "";
  const [userId, setUserId] = useState(tmpUserId);
  const [clientInfo, setClientInfo] = useState("");
  const [service, setService] = useState([]);
  const [logo, setLogo] = useState("");
  const [step, setStep] = useState([]);
  const [prevStep, setPrevStep] = useState("");
  const [nxtStep, setNxtStep] = useState("");

  let key = 0;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const color = ["#4086F4", "#A7B397", "#F440E6", "#5D9F05", "#DDCC00"];
  const NextSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.875"
      height="16.716"
      viewBox="0 0 32.875 16.716"
    >
      <path
        id="btn-next"
        d="M32.583,7.651,25.225.293A1,1,0,1,0,23.81,1.707l5.652,5.651H1a1,1,0,0,0,0,2H29.462L23.81,15.009a1,1,0,0,0,1.415,1.414l7.358-7.358a1,1,0,0,0,0-1.414"
        transform="translate(0 0)"
        fill="#ffffff"
      />
    </svg>
  );

  const NextIcon = (props) => <Icon component={NextSvg} {...props} />;

  const fetchStepDetails = async () => {
    var param = {};
    // param["booking_url"] = booking_url;
    param["current_step"] = "SVN";
    if (userId) {
      param["tmp_user_id"] = userId;
    }

    const response = await getStepDetails({
      data: param,
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setUserId(response.tmp_user_id);
      setClientInfo(response.current_step_values);
      setLogo(response.logo);
      setService(response.current_step_data);
      setNxtStep(response.next_step);
      setPrevStep(response.previous_step);
      setStep(response.booking_steps);
    }
  };

  const postClientService = async (formData) => {
    formData.append("tmp_user_id", userId);
    let param = [];
    param["booking_url"] = booking_url;
    param["step_code"] = "SVN";

    const response = await postClientInfos({
      api,
      data: formData,
      param: param,
      dispatch,
    });

    if (response) {
      let step = "";
      if (nxtStep == "SVN") {
        step = "/step1";
      } else if (nxtStep == "STY") {
        step = "/step2";
      } else if (nxtStep == "DTE") {
        step = "/step3";
      } else if (nxtStep == "COM") {
        step = "/completed";
      }

      navigate("/appointment/" + booking_url + step, {
        state: { id: userId },
      });
    }
  };

  const onFinish = (values) => {
    const formData = new FormData();

    let num = 0;
    if (values["services"]) {
      Object.values(values["services"]).map((svs, key) => {
        if (svs) {
          if (Array.isArray(svs)) {
            formData.append(`prd_master_ids[${num}]`, svs[0]);
          } else {
            formData.append(`prd_master_ids[${num}]`, svs);
          }
          formData.append(`prd_master_codes[${num}]`, service[key]["code"]);
          num++;
        }
      });
    }

    postClientService(formData);
  };
  const onFinishFailed = () => {};

  useEffect(() => {
    if (clientInfo.length > 0 || clientInfo.prd_master_codes) {
      service.map((svs, key) => {
        if (clientInfo["prd_master_codes"].includes(svs["code"])) {
          form.setFieldsValue({
            ["services"]: { [key]: svs["id"] },
          });
        }
      });
    }
  }, [clientInfo]);

  useEffect(() => {
    fetchStepDetails();
  }, []);

  return (
    <div className="booking-bg">
      <div className="booking-bg-dark">
        <div className="booking-step-content">
          {logo ? (
            <img src={logo} className="member-merchant-logo" />
          ) : (
            <h3>LOGO</h3>
          )}
          <Steps current={1} size="small" labelPlacement="vertical">
            {step
              ? step.map((stp, ky) => {
                  return <Step key={ky} title={stp.name} />;
                })
              : ""}
          </Steps>

          <h4 style={{ textAlign: "center" }}>CHOOSE SERVICES</h4>
          <p style={{ color: "#C9C9C9", marginBottom: "2rem" }}>
            Find a service to make a plan for you
          </p>
        </div>
        <div className="container">
          <Form
            name="appointment_service"
            initialValues={{}}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={20}>
              {service.length > 0
                ? service.map((svs, index) => {
                    if (!color[key]) {
                      key = 0;
                    }

                    let set_color = color[key];
                    let name = "";
                    key++;
                    return (
                      <Col
                        key={index}
                        xs={24}
                        md={8}
                        style={{ marginBottom: "2rem" }}
                      >
                        <CardSetting
                          avatar={svs.avatar}
                          color={svs.status == "Active" ? set_color : ""}
                          background={svs.status == "Inactive" ? "#bfbfbf" : ""}
                          name={svs.name}
                          detail={svs.duration + " " + svs.duration_type}
                          desc={svs.desc}
                          action={
                            <Form.Item noStyle name={["services", [index]]}>
                              <Checkbox.Group
                                className="merchant-checkbox"
                                options={[
                                  {
                                    value: svs.id,
                                  },
                                ]}
                              />
                            </Form.Item>
                          }
                          priceDesc={
                            svs.prices.length > 0 ? (
                              <div
                                style={{ display: "flex", marginTop: "1rem " }}
                              >
                                <span
                                  style={{
                                    color: "#808080",
                                    paddingRight: ".5rem",
                                  }}
                                >
                                  Price:
                                </span>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {svs.prices.map((price, key) => {
                                    let price_html = "";

                                    if (price.min_price >= price.max_price) {
                                      //only set min price (only have 1 price)
                                      price_html += `${price.currency_code} ${price.min_price} (${price.name})`;
                                    } else {
                                      price_html += `${price.currency_code} ${price.min_price} - ${price.currency_code} ${price.max_price} (${price.name}) `;
                                    }
                                    return (
                                      <div
                                        key={key}
                                        dangerouslySetInnerHTML={{
                                          __html: price_html,
                                        }}
                                      ></div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Col>
                    );
                  })
                : ""}
            </Row>
            <div className="booking-step-content">
              <Row
                gutter={20}
                justify="space-between"
                className="w-100"
                style={{ margin: "2rem 0" }}
              >
                <Col md={12} style={{ textAlign: "right" }}>
                  <button
                    className="btn-clear w-50"
                    onClick={() => {
                      let step = "";
                      if (prevStep == "CLIENTINFO") {
                        step = "";
                      } else if (prevStep == "SVN") {
                        step = "/step1";
                      } else if (prevStep == "STY") {
                        step = "/step2";
                      } else if (prevStep == "DTE") {
                        step = "/step3";
                      } else if (prevStep == "COM") {
                        step = "/completed";
                      }
                      navigate("/appointment/" + booking_url + step, {
                        state: { id: userId },
                      });
                    }}
                  >
                    <img src="/icons/btn-previous.svg"></img>
                  </button>
                </Col>
                <Col md={12} style={{ textAlign: "left" }}>
                  <button
                    key="submit"
                    htmltype="submit"
                    className="btn-secondary w-50"
                    style={{ minWidth: "110px" }}
                  >
                    <NextIcon />
                  </button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BookingStep2;
