import React from "react";
import { Form, Select } from "antd";

export default function SelectField(props) {
	const { Option } = Select;
	const opt = props.options.map((option, key) => {
		return (
			<Option
				value={option.value}
				key={key}
				style={{ textTransform: "capitalize" }}
			>
				{option.desc}
			</Option>
		);
	});

	return (
		<Form.Item
			name={props.name}
			label={props.label}
			rules={props.rules}
			style={props.style}
		>
			<Select
				id={props.id}
				mode={props.mode}
				onDropdownVisibleChange={props.handleChange}
				placeholder={props.placeholder}
				disabled={props.disabled}
				onChange={props.onChange}
			>
				<Option value="">Select Option</Option>
				{opt}
			</Select>
		</Form.Item>
	);
}
