import {
	HomeOutlined,
	PlusOutlined,
	RedoOutlined,
	SearchOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form, Modal, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faGear } from "@fortawesome/free-solid-svg-icons";
import DatatableGeneralData from "../../Datatable/Dt-generalData";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import GeneralDataFormModal from "../../Modals/GeneralDataFormModal";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";
import {
	fetchGeneralDataList,
	deleteGeneralData,
	fetchSysGeneralData,
	fetchGeneralTypeData,
} from "../../../../actions/Admin/actionCreators";

library.add(fas, faGear);

const breadcrumbItem = [
	{
		label: "",
		link: "/admin/dashboard",
		icon: <HomeOutlined />,
	},
	{
		label: "General Data Setup",
		icon: <FontAwesomeIcon icon="fa-solid fa-gear" />,
	},
	{
		label: "General Data Listing",
		link: "/admin/general-data-list",
	},
];

const GeneralDataList = () => {
	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState();
	const [statusSource, setStatusSource] = useState([]);
	const [typeSource, setTypeSource] = useState([]);
	const [formValue, setFormValue] = useState({});
	const [loading, setLoading] = useState(false);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState({ 
		current: 1, 
		pageSize: 50, });
	const [sorting, setSorting] = useState({
		sortField: "id",
		sortOrder: "DESC",
	});
	const [generaldataModalVisible, setGeneralDataModalVisible] =
		useState(false);

	useEffect(() => {
		let values = form.getFieldsValue();
		fetchGeneralDataListData(values);
		fetchStatusData();
		fetchAllTypeData();
	}, [pagination.current]);

	const fetchStatusData = async () => {
		const param = {};
		param["type"] = "general_status";
		param["status"] = "A";

		const response = await fetchSysGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));

			setStatusSource(transformed);
		}
	};

	const fetchAllTypeData = async () => {
		const param = {};

		const response = await fetchGeneralTypeData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ type }) => ({
				value: type,
				desc: type,
			}));

			setTypeSource(transformed);
		}
	};

	const fetchGeneralDataListData = async (values) => {
		const param = {};
		param["page"] = pagination.current;
		param["per_page"] = pagination.pageSize;
		param["page_length"] = pagination.pageSize;
		param["type"] = values.search_type;
		param["status"] = values.search_status;
		param["name"] = values.search_name;
		param["code"] = values.search_code;

		setLoading(true);
		const response = await fetchGeneralDataList({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			setDataSource(response.page_items);
		}

		setPagination({
			current: pagination.current,
			pageSize: pagination.pageSize,
			total: response.total_items,
		});
	};

	const postDeleteGeneralData = async (id) => {
		const param = {};
		param["id"] = id;

		setLoading(true);
		const response = await deleteGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			if (response.code === 200) {
				message
					.success(response.message, 1)
					.then(fetchGeneralDataListData({}));
			}
		}
	};

	const showGeneralDataFormModal = (record) => {
		setGeneralDataModalVisible(true);
		if (record) {
			setFormValue(record);
		} else {
			setFormValue({});
		}
	};

	const onGeneralDataModalCancel = () => {
		setGeneralDataModalVisible(false);
	};

	const handleRemoveRecord = (record) => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to remove ${record.name}?`,
			okText: "Yes",
			cancelText: "No",

			onOk() {
				postDeleteGeneralData(record.id);
			},
		});
	};

	const handleTableChange = (newPagination, filters, sorter) => {
		let order = "ASC";

		if (sorter.order === "descend") {
			order = "DESC";
		}

		setSorting({
			sortField: sorter.column ? sorter.field : "id",
			sortOrder: sorter.order ? order : "DESC",
		});

		setPagination({
			current: newPagination.current,
			pageSize: newPagination.pageSize,
			total: newPagination.total,
		});
	};

	const onFinish = (values) => {
		fetchGeneralDataListData(values);
	};

	const searchField = [
		{
			input: (
				<SelectField
					placeholder="Option Types"
					name="search_type"
					options={typeSource}
				/>
			),
		},
		{
			input: <TextField name="search_code" placeholder="Code" />,
		},
		{
			input: <TextField name="search_name" placeholder="Name" />,
		},
		{
			input: (
				<SelectField
					placeholder="Status"
					name="search_status"
					options={statusSource}
				/>
			),
		},
	];

	const searchFieldButton = [
		{
			button: (
				<Button
					type="secondary"
					icon={<PlusOutlined />}
					style={{ width: "100%" }}
					onClick={() => {
						showGeneralDataFormModal();
					}}
				>
					Add New
				</Button>
			),
		},
		{
			button: (
				<Button
					type="primary"
					htmlType="submit"
					icon={<SearchOutlined />}
					style={{ width: "100%" }}
				>
					Search
				</Button>
			),
		},
		{
			button: (
				<Button
					type="danger"
					style={{ width: "100%" }}
					icon={<RedoOutlined />}
					onClick={() => {
						form.resetFields();
						fetchGeneralDataListData({});
					}}
				>
					Reset
				</Button>
			),
		},
	];

	return (
		<>
			<BreadcrumbLayout items={breadcrumbItem} />
			<Divider />
			<Form
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinish}
			>
				<AdvSearchComponent
					field={searchField}
					buttonField={searchFieldButton}
				/>
			</Form>
			<Divider dashed />
			<DatatableGeneralData
				dataSource={dataSource}
				pagination={pagination}
				loading={loading}
				handleTableChange={handleTableChange}
				handleRemoveRecord={handleRemoveRecord}
				showGeneralDataFormModal={showGeneralDataFormModal}
			/>
			<GeneralDataFormModal
				visible={generaldataModalVisible}
				onCancel={onGeneralDataModalCancel}
				handleRemoveRecord={handleRemoveRecord}
				formValue={formValue}
				fetchGeneralDataListData={fetchGeneralDataListData}
			/>
		</>
	);
};

export default GeneralDataList;
