import { HomeOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAllHoliday,
  updateCheckHoliday,
} from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import BreadcrumbLayout from "../../../layouts/Merchant/breadcrumb";
import HolidayForm from "../Form/Holiday/HolidayForm";

const SetHoliday = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [descLength, setDescLength] = useState(0);
  const [holiday, setHoliday] = useState([]);
  const [checkedVal, setCheckedVal] = useState({});

  const breadcrumbItem = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      link: "/merchant/dashboard",
    },
    {
      label: "Company Setting",
      link: "/merchant/company-setting",
    },
    {
      label: "Set Holiday",
    },
  ];

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onChange = () => {};

  const onFinish = async (values) => {
    const response = await updateCheckHoliday({ api, data: values, dispatch });
  };

  const onFinishFailed = () => {};

  const getAllHoliday = async () => {
    const response = await fetchAllHoliday({ api, dispatch });
    let holidays = [];
    let checked = [];

    if (response) {
      response.map((holiday, index) => {
        let label = (
          <div
            key={index}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>
              {moment(holiday.date_start, "YYYY-MM-DD").format("DD MMM")}
            </span>
            <span>{holiday.description}</span>
          </div>
        );
        let value = holiday.id;
        holidays.push({
          label: label,
          value: value,
        });

        if (holiday.checked == 1) {
          checked.push(value);
        }
      });

      setHoliday(holidays);
      form.setFieldsValue({
        check_ids: checked,
      });
    }
  };

  useEffect(() => {
    getAllHoliday();
  }, []);

  return (
    <Row>
      <Col xs={24} md={17}>
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Set Holiday</strong>
          </Title>
          <BreadcrumbLayout items={breadcrumbItem} />
        </div>
        <div style={{ margin: "0 25px" }}>
          <Form
            {...layout}
            name="holiday"
            form={form}
            initialValues={checkedVal}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item className="merchant-form-item" name="check_ids">
              <Checkbox.Group
                className="merchant-checkbox merchant-checkbox-group"
                options={holiday}
                onChange={onChange}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Row style={{ margin: "2rem 0" }}>
              <Col xs={15} lg={6}>
                <button htmltype="submit" className="btn-secondary w-100">
                  Save & Update
                </button>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
      <Col
        xs={24}
        md={7}
        className="bg-white"
        style={{ borderLeft: "1px solid #d3dec3" }}
      >
        <div className="merchant-content-header">
          <Title level={4} style={{ marginBottom: "0" }}>
            <strong>Add Holiday</strong>
          </Title>
        </div>
        <div style={{ margin: "0 25px" }}>
          <HolidayForm getAllHoliday={getAllHoliday} />
        </div>
      </Col>
    </Row>
  );
};

export default SetHoliday;
