import * as ActionTypes from "./actionTypes";

export function authCheck() {
  return {
    type: ActionTypes.storeLoginToken,
  };
}

export function MemberLoginResult(type, payload, username) {
  return {
    type: type,
    payload,
    username,
  };
}

export function UpdateLoginResult(type, payload) {
  return {
    type: type,
    payload,
  };
}

export function SessionExpired(type, payload) {
  return {
    type: type,
    payload,
  };
}

export function userSession(type, payload) {
  return {
    type: type,
    payload,
  };
}

export function userSessionAuthenticate(type) {
  return {
    type: type,
  };
}

export function isLoading(type, payload) {
  return {
    type: type,
    payload,
  };
}
