import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Modal, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import UploadField from "../Global/FormInputComponent/UploadField";
import { createNewTerritory,
         getLanguageList,
         updateTerritory,
         } from '../../../actions/Admin/actionCreators';
import { AxiosContext } from '../../../actions/Admin/Axios/axios';
import { useDispatch } from "react-redux";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


const CountryFormModal = (props) => {
    const [form] = Form.useForm();
    const [createCountry, setCreateCountry] = useState(true);
    const [switchStatus, setSwitchStatus] = useState(true);
	const [languageOption, setLanguageOption] = useState([]);
	const [upload, setUpload] = useState(false);

    const api = useContext(AxiosContext);
	const dispatch = useDispatch();
    // const [initValue, setInitValue] = useState({});
    const [importData, setImportData] = useState({
        dataList:[]
    })
    const initialValues = {};
    const id = 0;

    const switchOnChange = (checked) => {
        setSwitchStatus(checked);
    };

    const validateMessages = {
        required: "${label} is required!",
        types: {
          email: "${label} is not a valid email!",
        },
        validator: "Duplicate Data",
    };

    // useEffect(() => {
        
    // }, [importData]);
    
    useEffect(() => {
        fetchLanguageListData();
        if (Object.keys(props.formValue).length != 0) {
            setCreateCountry(0);
            console.log(props.formValue)
            form.setFieldsValue({
                name: props.formValue.name,
                code: props.formValue.code,
                currency_code: props.formValue.currency_code,
                currency_name: props.formValue.currency_name,
                calling_no_prefix: props.formValue.calling_no_prefix,
                prefer_language_code: props.formValue.prefer_language_code,
                seq_no: props.formValue.seq_no,
                status: props.formValue.status
            });
            if (props.formValue.status == "Active") {
                setSwitchStatus(true);
            } else {
                setSwitchStatus(false);
            }

            if (props.formValue.image_path) {
				setImportData({ dataList: [{ url: props.formValue.image_path }] });
			}

        }else{
            setCreateCountry(1);
            form.resetFields();
        }
    }, [props.formValue]);

    const fetchLanguageListData = async () => {
        const param = {};
        param["status"] = "A";
        param["parent_code"] = "MC";

        const response = await getLanguageList({
            api,
            data: param,
            dispatch,
        });

        if (response) {
            const transformed = response.map(({ id, name, code }) => ({
                value: code,
                desc: code,
            }));
            setLanguageOption(transformed);
        }
    };

    const postNewTerritoryData = async (param) => {
		const response = await createNewTerritory({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				message.success(response.message, 3);
				props.onCancel();
				props.fetchTerritoryListData({});
			}
		}
	};

    const postUpdateTerritoryData = async (param) => {
		const response = await updateTerritory({
			api,
			data: param,
            id: props.formValue.id,
			dispatch,
		});
        
		if (response) {
			if (response.code === 200) {
				message.success(response.message, 10);
				props.onCancel();
                props.fetchTerritoryListData({});
			}
		}
	};

    const onFinish = async (values) => {
		const formData = new FormData();
        formData.append("territory_type", "country");
        formData.append("code", values["code"]);
        formData.append("name", values["name"]);
        formData.append("currency_code", values["currency_code"]);
        formData.append("currency_name", values["currency_name"]);		
        formData.append("prefer_language_code", values["prefer_language_code"]);
        formData.append("calling_no_prefix", values["calling_no_prefix"]);
        formData.append("seq_no", values["seq_no"]);
        
        console.log(importData)
        if (importData.dataList[0].name) {
            importData.dataList.forEach((file) => {
                formData.append("image", file);
            });
        } 

		if (switchStatus) {
			formData.append("status", "A");
		} else {
			formData.append("status", "I");
		}
        


		if (props.formValue.id) {
			formData.append("id", props.formValue.id);
			formData.append("_method", "PUT");
		}

		if (createCountry) {
			postNewTerritoryData(formData);
		} else {
			postUpdateTerritoryData(formData);
		}
	};

    return(
        <Modal title={createCountry? 'Country Form' : 'Country Edit Form'} visible={props.visible} centered onCancel={props.onCancel} footer={null} forceRender>
            <Form
                {...layout}
                form={form}
                name="country_form"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                validateMessages={validateMessages}
                scrollToFirstError
                initialValues={initialValues}
                validateTrigger="onBlur"
            >
                <TextField
                label="Country Name"
                name="name"
                placeholder="Country Name"
                rules={[
                    { required: true, whitespace: true },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                label="Code"
                name="code"
                placeholder="Code"
                rules={[
                    { required: true, whitespace: true },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                label="Currency Code"
                name="currency_code"
                placeholder="Currency Code"
                rules={[
                    { required: true, whitespace: true },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                label="Currency Name"
                name="currency_name"
                placeholder="Currency Name"
                rules={[
                    { required: true, whitespace: true },
                    {
                    validator: async (rule, value, callback) => {
                        if (value) {
                        const param = { id: id, nick_name: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                    label="Prefix"
					name="calling_no_prefix"
					placeholder="Prefixes"
					rules={[{ required: true },
                        {
                            validator: async (rule, value, callback) => {
                                if (value) {
                                const param = { id: id, nick_name: value };
                                //   const validate = await checkAdminDuplicate({
                                //     api: api,
                                //     data: param,
                                //     dispatch,
                                //   });
                                //   const validation = validate.data;
                                //   if (validation.error == 1) {
                                //     throw new Error(validation.msg);
                                //   }
                                }
                            },
                            },
                        ]}
					hasFeedback
                />
                <SelectField
                label="Preferred Language"
                name="prefer_language_code"
                placeholder="Preferred Language"
                options={languageOption}
                rules={[
                    { type: "", required: true },
                    {
                    validator: async (rule, value) => {
                        if (value) {
                        const param = { id: id, user_group: value };
                        //   const validate = await checkAdminDuplicate({
                        //     api: api,
                        //     data: param,
                        //     dispatch,
                        //   });
                        //   const validation = validate.data;
                        //   if (validation.error == 1) {
                        //     throw new Error(validation.msg);
                        //   }
                        }
                    },
                    },
                ]}
                hasFeedback
                />
                <TextField
                    label="Seq. No."
					name="seq_no"
					placeholder="Example: 1"
					rules={[{ required: true }]}
					hasFeedback
                />
                <UploadField 
                    setImportData={setImportData}
                    importData={importData}
                    label="Flag"
                    name="avatar"
                />
                {createCountry ? (
					<>
					</>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={ switchStatus }
						switchChange={ switchOnChange }
						initialValue
					/>
				)}
                <div style={{textAlign: 'right', paddingTop: '2rem'}}>
                    <Button key="back" onClick={props.onCancel} style={{marginRight: '10px'}}>
                    Cancel
                    </Button>
                    <Button key="submit" type="primary" htmlType="submit">
                        {createCountry ? 'Submit' : 'Update' }
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default CountryFormModal;
