import React from "react";
import ReactDOM from "react-dom/client";
import "./App.less";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import AdminAxiosComponent from "./actions/Admin/Axios/axios";
import MerchantAxiosComponent from "./actions/Merchant/Axios/axios";
import BookingAxiosComponent from "./actions/Member/Axios/axios";
import * as adminAction from "./actions/Admin/action";
import * as merchantAction from "./actions/Merchant/action";
import * as bookingAction from "./actions/Member/action";
import store from "./app/Store/store";
import AdminMasterLayout from "./layouts/Admin/master";
import MerchantMasterLayout from "./layouts/Merchant/master";
import BookingMasterLayout from "./layouts/Member/master";

const pathname = window.location.pathname;
let master = <BookingMasterLayout />;
let AxiosComponent = BookingAxiosComponent;

if (pathname.startsWith("/merchant")) {
  master = <MerchantMasterLayout />;
  AxiosComponent = MerchantAxiosComponent;
}

if (pathname.startsWith("/admin")) {
  master = <AdminMasterLayout />;
  AxiosComponent = AdminAxiosComponent;
}

store.dispatch(adminAction.authCheck());
store.dispatch(merchantAction.authCheck());
store.dispatch(bookingAction.authCheck());

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AxiosComponent>
      <BrowserRouter>{master}</BrowserRouter>
    </AxiosComponent>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
