import * as action_types from "./actionTypes";
import * as action from "./action";

const API_BASE_URL = process.env.REACT_APP_SERVER_URL;

//Admin-User
export const fetchAdminUserList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/users`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const fetchAdminUserDetail = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/users/${data.id}`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createAdminUser = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/users`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateAdminUser = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/users/${data.id}`,
    method: `PUT`,
    data,
  });

  return response.data;
};

export const deleteAdminUser = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/users/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

//User Role
export const fetchUserRoleData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/roles/groups`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const fetchUserRoleTreeData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/roles/tree`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createRoleUser = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/roles/group`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateRoleUser = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/roles/group/${data.id}`,
    method: `PUT`,
    data,
  });

  return response.data;
};

//Merchant
export const fetcAllMerchantData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_details/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createMerchantData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const fetchMerchantDetail = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant/${data.id}`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const updateMerchantDetail = async ({ id, data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant/${id}`,
    method: `POST`,
    data,
  });

  return response.data;
};

//Merchant Category
export const fetchMerchantCategoryList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_category`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const createMerchantCategory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_category`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateMerchantCategory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_category/${data.id}`,
    method: `PUT`,
    data,
  });

  return response.data;
};

export const deleteMerchantCategory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_category/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

export const fetchMainMerchantCategory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_category/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

//Merchant Product
export const fetchProductList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/products`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const createProductData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/products`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateProductData = async ({ id, data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/products/${id}`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const deleteProduct = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/products/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

//Booking
export const fetchAllBookingMasterData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/booking_master/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

//General Data
export const fetchGeneralDataList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const createNewGeneralData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateGeneralData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings/${data.id}`,
    method: `PUT`,
    data,
  });

  return response.data;
};

export const deleteGeneralData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

export const fetchSysGeneralData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const fetchGeneralTypeData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/settings/allTypes`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

//Language
export const fetchLanguageList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/languages`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const getLanguageList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/languages/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createNewLanguage = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/languages`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateLanguage = async ({ id, data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/languages/${id}`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const deleteLanguage = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/languages/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

//Territories
export const createNewTerritory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/territories`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const updateTerritory = async ({ id, data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/territories/${id}`,
    method: `POST`,
    data,
  });

  return response.data;
};

export const deleteTerritory = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/territories/${data.id}`,
    method: `DELETE`,
  });

  return response.data;
};

export const fetchTerritoryList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/territories`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const fetchTerritoryData = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/territories/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const LoginAction = ({ data, api }) => {
  return (dispatch) => {
    api({
      url: `${API_BASE_URL}/admin/users/login`,
      method: "POST",
      data,
    }).then((result) => {
      if (result) {
        localStorage.setItem("token", "Bearer " + result.data.results.token);
        localStorage.setItem("name", result.data.results.name);

        dispatch(
          action.userSessionAuthenticate(action_types.userSessionAuthenticate)
        );
        return dispatch(
          action.AdminLoginResult(
            action_types.storeLoginToken,
            result.data.results.token,
            result.data.results.name
          )
        );
      }
    });
  };
};

export const LogoutAction = ({ api }) => {
  return (dispatch) => {
    return dispatch(action.UpdateLoginResult(action_types.removeLoginToken));
  };
};

export const fetchMerchantDetailsList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_details`,
    method: `GET`,
    params: data,
  });

  return response.data;
};

export const getMerchantDetailsList = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_details/all`,
    method: `GET`,
    params: data,
  });

  return response.data.results;
};

export const createNewMerchantDetails = async ({ data, api }) => {
  const response = await api({
    url: `${API_BASE_URL}/admin/merchant_details`,
    method: `POST`,
    data,
  });

  return response.data;
};
