import React, { useState } from "react";
import { Avatar, Layout } from "antd";
import AppointmentForm from "../../components/Merchant/Form/Appointment/AppointmentForm";

const { Header } = Layout;

const HeaderLayout = (props) => {
  const [visible, setVisible] = useState(false);
  const closeAppointment = () => {
    setVisible(false);
  };
  return (
    <>
      <Header className="merchant-header">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="MctLogoSmall"
            style={{
              padding: "15px 30px",
              background: "#fff",
              borderBottom: "1px solid #D3DEC3",
              borderRight: "1px solid #D3DEC3",
            }}
          >
            Logo
          </div>
          <div style={{ paddingLeft: "15px", display: "flex" }}>
            <Avatar size={34} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "1rem",
              }}
            >
              <strong style={{ marginBottom: "5px" }}>Alex Lim</strong>
              <small className="muted-color">Company Owner</small>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <button
            onClick={() => {
              setVisible(true);
            }}
            className="btn-clear"
            style={{
              padding: "4px 10px",
              textTransform: "capitalize",
              color: "var(--font-color)",
              marginRight: "1rem",
            }}
          >
            <img src="/icons/btn-add.svg" className="add-appointment-img"></img>
            <span>Add appointment</span>
          </button>
          <button
            className="btn-clear"
            style={{
              padding: "4px 10px",
              width: "36px",
              height: "36px",
              minWidth: "36px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src="/icons/icon-nofication.svg"></img>
            </div>
          </button>
        </div>
      </Header>
      <AppointmentForm visible={visible} action={closeAppointment} />
    </>
  );
};

export default HeaderLayout;
