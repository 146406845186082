import { Avatar, Card, Checkbox, Col, Form, Input, Row, Select, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import "../../../assets/css/Merchant/login.css";
import { Link, useNavigate } from "react-router-dom";
import TimeRange from "react-time-range";
import { LogoutAction, submitBookingStep, fetchPreviousBookingStep } from "../../../actions/Merchant/actionCreators";
import moment from "moment";

const { Option } = Select;

const MerchantSignupStep2_3 = () => {
	const dispatch = useDispatch();
	const api = useContext(AxiosContext);
	const navigate = useNavigate();
	// const [selectedDay, setSelectedDay] = useState(["Mon", "Wed"]);
	const [date, setDate] = useState(1);
	// const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss[Z]'));
	// const [endTime, setEndTime] = useState(moment().format('YYYY-MM-DDTHH:mm:ss[Z]'));
	const [startTime, setStartTime] = useState([]);
	const [endTime, setEndTime] = useState([]);
	let daysObj = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	let dateEle = [];

	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};

	const addDate = () => {
		setDate(date + 1);
	};

	// const timeOnChange = (event) => {
	// 	//
	// };

	const handleLogout = () => {
		dispatch(LogoutAction({ api }));
	};

	const handleNavigate = (step) => {
		if (step == "empty_shop_info") {
			navigate("/merchant/signupStep1");
		} else if (step == "empty_booking_step") {
			navigate("/merchant/signupStep2");
		} else if (step == "empty_service") {
			navigate("/merchant/signupStep2_1");
		} else if (step == "empty_staff") {
			navigate("/merchant/signup-step2_2");
		} else if (step == "empty_operation_hour") {
			navigate("/merchant/signupStep2_3");
		} else if (step == "completed") {
			navigate("/merchant/signup-completed");
		}
	};

	const postOperationHourData = async (param) => {
		const response = await submitBookingStep({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			if (response.code === 200) {
				if (
					typeof response.results.signup_step !== "undefined" &&
					response.results.signup_step != null
				) {
					localStorage.setItem("signup_data", JSON.stringify(response.results.signup_data ?? []));
					localStorage.setItem("steps", response.results.signup_step);
					handleNavigate(response.results.signup_step);
				}
				message.success(response.message, 5);
			}
		}
	};

	const onFinish = (values) => {
		const formData = new FormData();

		formData.append("signup_step", "empty_operation_hour");

		// Object.entries(values).map(([key, value]) => {
		// 	if (key == "operation_days") {
		// 		value.forEach((days, index) => {
		// 			formData.append("operation_days[]", days);
		// 		});
		// 	}
		// });

		Object.entries(values).map(([key, value]) => {
			if (typeof value !== "undefined" && value != null && value != '') {
				formData.append(key, value);
			}
		});
		// Object.values(values['operation_days']).map((days, index) => {
		// 	formData.append("operation_days[]", days);
		// });

		Object.values(startTime).map((hr, index) => {
			formData.append(
			  `operation_hours_from[${index}]`,
			  moment(hr, moment.ISO_8601).format("HH:mm")
			);
		});

		Object.values(endTime).map((hr, index) => {
			formData.append(
				`operation_hours_to[${index}]`,
				moment(hr, moment.ISO_8601).format("HH:mm")
			);
		});

		postOperationHourData(formData);
	};

	const onFinishFailed = () => {};

	const appendDate = () => {
		for (let index = 0; index < date; index++) {
			// let start_moment = "0800-01-01T01:13:14.000Z";
			// let end_moment = "1200-01-01T05:13:14.000Z";

			dateEle.push(
				<TimeRange
					key={index}
					className="merchant-time-range"
					startLabel=""
					// startMoment={startTime}
					// endLabel="TO"
					// endMoment={endTime}
					startMoment={startTime[index]}
					endLabel="TO"
					endMoment={endTime[index]}
					use24Hours
					// onChange={timeOnChange}
					onChange={(event) => {
						setStartTime({
						  	...startTime,
						  	[index]: event.startTime,
						});
						setEndTime({
						  	...endTime,
						  	[index]: event.endTime,
						});
					}}
					// value={endTime}
				/>
			);
		}
	};

	// const onChange = () => {};

	// const handleBack = () => {
	// 	localStorage.setItem("steps", "empty_booking_step");
	// };
	const handleBack = (event) => {
		event.preventDefault();
		var param = {};
		param["signup_step"] = localStorage.getItem("steps");

		getPreviousStep(param);
	}

	const getPreviousStep = async (param) => {
		const response = await fetchPreviousBookingStep({
			api,
			data: param,
			dispatch,
		});
		if (response) {
			localStorage.setItem("signup_data", JSON.stringify(response.signup_data ?? []));
			localStorage.setItem("steps", response.signup_step);
			handleNavigate(response.signup_step);
		}
	};

	appendDate();

	useEffect(() => {
		appendDate();
	}, [date]);

	return (
		<div className="container">
			<Row className="py-4" justify="space-between">
				<Col>
					<div className="MctLogoSmall">Logo</div>
				</Col>
				<Col>
					<div className="LoginBtn" onClick={handleLogout}>
						Logout
					</div>
				</Col>
			</Row>
			<Row justify="start" style={{ maxWidth: "500px", margin: "auto" }}>
				<Col>
					<div className="text-header">
						<div style={{ display: "flex" }}>
							<Avatar
								size={50}
								className="merchant-avatar"
								icon={
									<img
										src="/icons/step-date.svg"
										style={{ width: 24, height: 24 }}
									></img>
								}
							/>
							Quick set-up
						</div>
					</div>
					<div
						className="text-header"
						style={{ marginBottom: "1rem" }}
					>
						Business Operation Hours
					</div>
					<p style={{ marginBottom: "1.5rem", color: "#8FA07E" }}>
						Set Business Operation Hours
					</p>
				</Col>
			</Row>
			<Row
				justify="center"
				style={{
					maxWidth: "500px",
					marginLeft: "auto",
					marginRight: "auto",
					marginTop: "2rem",
				}}
			>
				<Col span={24}>
					<Form
						{...layout}
						name="basic"
						initialValues={{
							remember: true,
							"operation_days[0]": daysObj[0],
							"operation_days[1]": daysObj[1],
							"operation_days[2]": daysObj[2],
							"operation_days[3]": daysObj[3],
							"operation_days[4]": daysObj[4],
							"operation_days[5]": daysObj[5],
							"operation_days[6]": daysObj[6],
							buffer_time: 60,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className="header-color"
					>
						<div
							className="font-color"
							style={{ marginBottom: "10px" }}
						>
							Operation Days
						</div>
						<Row
							justify="space-between"
							style={{ marginBottom: "1.5rem" }}
						>
							{daysObj.map((day, index) => {
								return (
									<Col key={index}>
										<Card.Grid
											className="merchant-date-card"
											style={{
												padding: "12px",
												minWidth: "50px",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												textAlign: "center",
											}}
										>
											<strong>{day}</strong>
											<Form.Item
												name={"operation_days[" + index + "]"}
												label=""
												noStyle
											>
												<Checkbox.Group
													className="merchant-checkbox"
													options={[
														{
															value: day,
														},
													]}
													// onChange={onChange}
												/>
											</Form.Item>
										</Card.Grid>
									</Col>
								);
							})}
						</Row>
						<div
							className="font-color"
							style={{ marginBottom: "10px" }}
						>
							Operation Hours
						</div>
						<Form.Item
							className="merchant-form-item"
							name="time"
							noStyle
						>
							{dateEle}
						</Form.Item>

						<div
							style={{
								display: "flex",
								alignContent: "center",
								margin: "20px 0",
								cursor: "pointer",
							}}
							onClick={addDate}
						>
							<img
								src="/icons/btn-add.svg"
								style={{ marginRight: "10px" }}
							></img>
							<span style={{ lineHeight: 2 }}>
								Add operation hours
							</span>
						</div>
						<div
							className="font-color"
							style={{ marginBottom: "10px" }}
						>
							Booking Interval
						</div>
						<Row>
							<Col md={12}>
								<Form.Item
									className="merchant-form-item"
									name="buffer_time"
								>
									<Select className="merchant-form-select">
										<Option value="" disabled>
											Select Interval
										</Option>
										<Option value="15">15 Min</Option>
										<Option value="30">30 Min</Option>
										<Option value="60">60 Min</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col md={12}>
								<Form.Item className="merchant-form-item">
									<div style={{ marginLeft: "1rem" }}>
										Eg: 15 mins, 30 mins or 1 hr
									</div>
								</Form.Item>
							</Col>
						</Row>
						<div className="text-muted">
							*Advance setting for company logo can set later
						</div>
						<Row
							justify="space-between"
							style={{ margin: "2rem 0" }}
						>
							<Col>
								<Link to="/merchant/signup-step2_2">
									<button
										className="btn-clear"
										onClick={(e) => handleBack(e)}
									>
										<img src="/icons/btn-previous.svg"></img>
									</button>
								</Link>
							</Col>
							<Col>
								<button
									htmltype="submit"
									className="btn-secondary"
									style={{ minWidth: "110px" }}
								>
									Submit
								</button>
								{/* <Link to="/merchant/signup-completed">
									<button
										htmltype="submit"
										className="btn-secondary"
										style={{ minWidth: "110px" }}
									>
										Submit
									</button>
								</Link> */}
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default MerchantSignupStep2_3;
