import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../assets/css/Merchant/global.css";
import MainRoutes from "../../routes/Merchant/main";
import MerchantLogin from "../../components/Merchant/Login/Login";
import MerchantSignup from "../../components/Merchant/Signup/Signup";
import MenuLayout from "./aside-menu";
import HeaderLayout from "./header";
import MerchantSignupStep1 from "../../components/Merchant/Signup/SignupStep1";
import MerchantSignupStep2 from "../../components/Merchant/Signup/SignupStep2";
import MerchantSignupStep2_1 from "../../components/Merchant/Signup/SignupStep2_1";
import MerchantSignupStep2_2 from "../../components/Merchant/Signup/SignupStep2_2";
import MerchantSignupStep2_3 from "../../components/Merchant/Signup/SignupStep2_3";
import MerchantSignupCompleted from "../../components/Merchant/Signup/SignupCompleted";

const { Content } = Layout;

const MerchantMasterLayout = () => {
  // const pathname = window.location.pathname;

  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const navigate = useNavigate();

  const temp = useSelector((state) => ({
    isLoggedIn: state.MerchantLoginReducer.isLoggedIn,
    userSession: state.MerchantErrorReducer.token_expired,
  }));

  const steps = localStorage.getItem("steps");

  useEffect(() => {
    if (!temp.isLoggedIn) {
      if (window.location.pathname != "/merchant/signup") {
        navigate("/merchant");
      }
    } else {
      if (steps == "empty_shop_info") {
        navigate("/merchant/signupStep1");
      } else if (steps == "empty_booking_step") {
        navigate("/merchant/signupStep2");
      } else if (steps == "empty_service") {
        navigate("/merchant/signupStep2_1");
      } else if (steps == "empty_staff") {
        navigate("/merchant/signupStep2_2");
      } else if (steps == "empty_operation_hour") {
        navigate("/merchant/signupStep2_3");
      } else if (steps == "completed") {
        navigate("/merchant/signup-completed");
      }
    }
  }, [navigate, temp.isLoggedIn, temp.userSession, steps]);

  document.body.style.backgroundImage = "";
  document.body.style.background = "var(--bg-color)";

  return (
    <>
      {temp.isLoggedIn && !steps ? (
        <Layout>
          <HeaderLayout collapsed={collapsed} collapsedWidth={collapsedWidth} />
          <Layout className="merchant-layout">
            <MenuLayout
              collapsed={collapsed}
              collapsedWidth={collapsedWidth}
              setCollapsedWidth={setCollapsedWidth}
              setCollapsed={setCollapsed}
            />
            <Content
              className="site-layout-background"
              style={{
                marginTop: "61px",
                minHeight: "75vh",
              }}
            >
              <MainRoutes />
            </Content>
          </Layout>
        </Layout>
      ) : temp.isLoggedIn && steps ? (
        <Routes>
          <Route
            path="/merchant/signupStep1"
            exact={true}
            element={<MerchantSignupStep1 />}
            auth={true}
          />
          <Route
            path="/merchant/signupStep2"
            exact={true}
            element={<MerchantSignupStep2 />}
            auth={true}
          />
          <Route
            path="/merchant/signupStep2_1"
            exact={true}
            element={<MerchantSignupStep2_1 />}
            auth={true}
          />
          <Route
            path="/merchant/signupStep2_2"
            exact={true}
            element={<MerchantSignupStep2_2 />}
            auth={true}
          />
          <Route
            path="/merchant/signupStep2_3"
            exact={true}
            element={<MerchantSignupStep2_3 />}
            auth={true}
          />
          <Route
            path="/merchant/signup-completed"
            exact={true}
            element={<MerchantSignupCompleted />}
            auth={true}
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/merchant"
            exact={true}
            element={<MerchantLogin />}
            auth={false}
          />
          <Route
            path="/merchant/signup"
            exact={true}
            element={<MerchantSignup />}
            auth={false}
          />
        </Routes>
      )}
    </>
  );
};

export default MerchantMasterLayout;
