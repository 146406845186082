import React, { useContext } from "react";
import { Layout } from "antd";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { LogoutAction } from "../../actions/Admin/actionCreators";
import { useDispatch } from "react-redux";
import { AxiosContext } from "../../actions/Admin/Axios/axios";

const { Header } = Layout;

const HeaderLayout = (props) => {
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const handleLogout = () => {
    dispatch(LogoutAction({ api }));
  };
  return (
    <Header
      className="site-layout-background"
      style={{
        padding: "0 15px",
        position: "fixed",
        zIndex: "1",
        width: props.collapsed
          ? "calc(100% - " + props.collapsedWidth + "px)"
          : "calc(100% - 260px)",
        fontSize: "18px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {React.createElement(
        props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: () => props.setCollapsed(!props.collapsed),
        }
      )}
      <LogoutOutlined className="logout" onClick={handleLogout} />
    </Header>
  );
};

export default HeaderLayout;
