import React from "react";
import { Form, Switch } from "antd";

export default function SwitchField(props) {
	return (
		<Form.Item name={props.name} label={props.label} initialValue>
			<Switch
				checked={props.switchStatus}
				onChange={props.switchChange}
			/>
		</Form.Item>
	);
}
