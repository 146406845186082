import {
	HomeOutlined,
	PlusOutlined,
	RedoOutlined,
	SearchOutlined,
	ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form, Modal, message } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faGear } from "@fortawesome/free-solid-svg-icons";
import DatatableLanguage from "../../Datatable/Dt-language";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import LanguageFormModal from "../../Modals/LanguageFormModal";
import {
	fetchLanguageList,
	deleteLanguage,
	fetchSysGeneralData,
} from "../../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";

library.add(fas, faGear);

const breadcrumbItem = [
	{
		label: "",
		link: "/admin/dashboard",
		icon: <HomeOutlined />,
	},
	{
		label: "Language Setup",
		icon: <FontAwesomeIcon icon="fa-solid fa-gear" />,
	},
	{
		label: "Language listing",
		link: "/admin/language-list",
	},
];

const LanguageList = () => {
	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState(); //useState() ?
	const [statusSource, setStatusSource] = useState([]);
	const [formValue, setFormValue] = useState({});
	const [loading, setLoading] = useState(false);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState({ 
		current: 1, 
		pageSize: 50, 
	});
	const [sorting, setSorting] = useState({
		sortField: "id",
		sortOrder: "DESC",
	});
	const [languageModalVisible, setLanguageModalVisible] = useState(false);

	useEffect(() => {
		let values = form.getFieldsValue();
		fetchLanguageListData(values);
		fetchGeneralData();
	}, [pagination.current]);

	const fetchGeneralData = async () => {
		const param = {};
		param["type"] = "general_status";
		param["status"] = "A";

		const response = await fetchSysGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));

			setStatusSource(transformed);
		}
	};

	const fetchLanguageListData = async (values) => {
		const param = {};
		param["page"] = pagination.current;
		param["per_page"] = pagination.pageSize;
		param["page_length"] = pagination.pageSize;
		param["locale"] = values.search_locale;
		param["code"] = values.search_code;
		param["name"] = values.search_name;
		param["status"] = values.search_status;

		setLoading(true);

		const response = await fetchLanguageList({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			setDataSource(response.page_items);

			setPagination({
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: response.total_items,
			});
		}
	};

	const postDeleteLanguageData = async (id) => {
		const param = {};
		param["id"] = id;

		setLoading(true);
		const response = await deleteLanguage({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			if (response.code === 200) {
				message
					.success(response.message, 1)
					.then(fetchLanguageListData({}));
			}
		}
	};

	const showLanguageFormModal = (record) => {
		setLanguageModalVisible(true);
		if (record) {
			setFormValue(record);
		} else {
			setFormValue({});
		}
	};

	const onLanguageModalCancel = () => {
		setLanguageModalVisible(false);
	};

	const handleRemoveRecord = (record) => {
		Modal.confirm({
			title: "Confirm",
			icon: <ExclamationCircleOutlined />,
			content: `Are you sure you want to remove ${record.name}?`,
			okText: "Yes",
			cancelText: "No",

			onOk() {
				postDeleteLanguageData(record.id);
			},
		});
	};

	const handleTableChange = (newPagination, filters, sorter) => {
		let order = "ASC";

		if (sorter.order == "descend") {
			order = "DESC";
		}

		setSorting({
			sortField: sorter.column ? sorter.field : "id",
			sortOrder: sorter.order ? order : "DESC",
		});

		setPagination({
			current: newPagination.current,
			pageSize: newPagination.pageSize,
			total: newPagination.total,
		});
	};

	const onFinish = (values) => {
		fetchLanguageListData(values);
	};

	const searchField = [
		{
			input: <TextField name="search_code" placeholder="Code" />,
		},
		{
			input: <TextField name="search_name" placeholder="Name" />,
		},
		{
			input: (
				<SelectField
					placeholder="Status"
					name="search_status"
					options={statusSource}
				/>
			),
		},
	];

	const searchFieldButton = [
		{
			button: (
				<Button
					type="secondary"
					icon={<PlusOutlined />}
					style={{ width: "100%" }}
					onClick={() => {
						showLanguageFormModal();
					}}
				>
					Add New
				</Button>
			),
		},
		{
			button: (
				<Button
					type="primary"
					htmlType="submit"
					icon={<SearchOutlined />}
					style={{ width: "100%" }}
				>
					Search
				</Button>
			),
		},
		{
			button: (
				<Button
					type="danger"
					style={{ width: "100%" }}
					icon={<RedoOutlined />}
					onClick={() => {
						form.resetFields();
						fetchLanguageListData({});
					}}
				>
					Reset
				</Button>
			),
		},
	];

	return (
		<>
			<BreadcrumbLayout items={breadcrumbItem} />
			<Divider />
			<Form
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinish}
			>
				<AdvSearchComponent
					field={searchField}
					buttonField={searchFieldButton}
				/>
			</Form>
			<Divider dashed />
			<DatatableLanguage
				dataSource={dataSource}
				pagination={pagination}
				loading={loading}
				handleTableChange={handleTableChange}
				handleRemoveRecord={handleRemoveRecord}
				showLanguageFormModal={showLanguageFormModal}
			/>
			<LanguageFormModal
				visible={languageModalVisible}
				onCancel={onLanguageModalCancel}
				handleRemoveRecord={handleRemoveRecord}
				formValue={formValue}
				fetchLanguageListData={fetchLanguageListData}
			/>
		</>
	);
};

export default LanguageList;
