import {
	DownloadOutlined,
	HomeOutlined,
	RedoOutlined,
	SearchOutlined,
	UserAddOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import BreadcrumbLayout from "../../../../layouts/Admin/breadcrumb";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas, faUserGear } from "@fortawesome/free-solid-svg-icons";

import DatatableMerchant from "../../Datatable/Dt-merchant";
import AdvSearchComponent from "../../Global/SearchComponent/Dt-adv-search";
import { Link } from "react-router-dom";
import TextField from "../../Global/FormInputComponent/TextField";
import SelectField from "../../Global/FormInputComponent/SelectField";
import { AxiosContext } from "../../../../actions/Admin/Axios/axios";
import {
	fetchMerchantDetailsList,
	fetchSysGeneralData,
} from "../../../../actions/Admin/actionCreators";

library.add(fas, faUserGear);

const breadcrumbItem = [
	{
		label: "",
		link: "/admin/dashboard",
		icon: <HomeOutlined />,
	},
	{
		label: "Merchant",
		icon: <FontAwesomeIcon icon="fa-solid fa-shop" />,
	},
	{
		label: "Merchant Listing",
		link: "/admin/merchant-list",
	},
];

const MerchantList = () => {
	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState();
	const [statusSource, setStatusSource] = useState([]);
	const [formValue, setFormValue] = useState({});
	const [loading, setLoading] = useState(false);
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
	const [sorting, setSorting] = useState({
		sortField: "id",
		sortOrder: "DESC",
	});

	const fetchGeneralData = async () => {
		const param = {};
		param["type"] = "general_status";
		param["status"] = "A";

		const response = await fetchSysGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ code, name }) => ({
				value: code,
				desc: name,
			}));

			setStatusSource(transformed);
		}
	};

	const fetchMerchantDetailsListData = async (values) => {
		console.log(values);
		const param = {};
		param["page"] = pagination.current;
		param["page_length"] = pagination.pageSize;
		param["email"] = values.search_email;
		param["status"] = values.search_status;
		param["name"] = values.search_name;
		setLoading(true);
		const response = await fetchMerchantDetailsList({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			setLoading(false);
			setDataSource(response.page_items);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		let order = "ASC";

		if (sorter.order === "descend") {
			order = "DESC";
		}

		setSorting({
			sortField: sorter.column ? sorter.field : "id",
			sortOrder: sorter.order ? order : "DESC",
		});

		setPagination({
			current: pagination.current,
			pageSize: pagination.pageSize,
		});
	};

	const onFinish = (values) => {
		// getAdminListData(values);
	};

	useEffect(() => {
		let values = form.getFieldsValue();
		fetchGeneralData();
		fetchMerchantDetailsListData(values);
	}, [pagination]);

	const searchField = [
		{
			input: <TextField name="search_name" placeholder="Name" />,
		},
		{
			input: (
				<TextField name="search_mobile_no" placeholder="Mobile No." />
			),
		},
		{
			input: <TextField name="search_email" placeholder="Email" />,
		},
		{
			input: (
				<SelectField
					placeholder="Status"
					name="search_status"
					options={statusSource}
				/>
			),
		},
	];

	const searchFieldButton = [
		{
			button: (
				<Link to="/admin/merchant-create">
					<Button
						type="secondary"
						icon={<UserAddOutlined />}
						style={{ width: "100%" }}
					>
						Add New
					</Button>
				</Link>
			),
		},
		{
			button: (
				<Button
					type="primary"
					htmlType="submit"
					icon={<SearchOutlined />}
					style={{ width: "100%" }}
				>
					Search
				</Button>
			),
		},
		{
			button: (
				<Button
					type="danger"
					style={{ width: "100%" }}
					icon={<RedoOutlined />}
					onClick={() => {
						form.resetFields();
					}}
				>
					Reset
				</Button>
			),
		},
		{
			button: (
				<Button
					type="dashed"
					icon={<DownloadOutlined />}
					style={{ width: "100%" }}
				>
					Export
				</Button>
			),
		},
	];

	return (
		<>
			<BreadcrumbLayout items={breadcrumbItem} />
			<Divider />
			<Form
				form={form}
				name="advanced_search"
				className="ant-advanced-search-form"
				onFinish={onFinish}
			>
				<AdvSearchComponent
					field={searchField}
					buttonField={searchFieldButton}
				/>
			</Form>
			<Divider dashed />
			<DatatableMerchant
				dataSource={dataSource}
				pagination={pagination}
				loading={loading}
				handleTableChange={handleTableChange}
			/>
		</>
	);
};

export default MerchantList;
