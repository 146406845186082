import { Col, Modal, Row } from "antd";
import React from "react";

export default function QRPreviewModal(props) {
  console.log(props.qrCode);

  return (
    <Modal
        title={null}
        open={props.visible}
        centered
        onCancel={props.onCancel}
        footer={null}
        forceRender
        className="text-center"
        width={500}
        bodyStyle={{ padding: "5px" }}
    >
        <Row justify="center" style={{ marginBottom: "2rem" }}>
            <Col span={24} style={{ textAlign: "center", marginTop: "2rem" }}>
                <p>
                    <strong style={{ fontSize: "16px" }}>Your Booking QR Code</strong>
                </p>
            </Col>
            <Col span={24}>
                <img src={props.qrCode}></img>
            </Col>
        </Row>
    </Modal>
  );
}
