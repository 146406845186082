import { Button, Form, Modal, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import {
	createNewGeneralData,
	updateGeneralData,
	fetchGeneralTypeData,
} from "../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const GeneralDataFormModal = (props) => {
	const api = useContext(AxiosContext);
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [typeOptions, setTypeOptions] = useState([]);
	const [createGeneralData, setCreateGeneralData] = useState(true);
	const [switchStatus, setSwitchStatus] = useState(true);
	const initialValues = {};

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		validator: "Duplicate Data",
	};

	const onFinish = async (values) => {
		if (props.formValue.id) {
			values["id"] = props.formValue.id;
			if (switchStatus) {
				values["status"] = "A";
			} else {
				values["status"] = "I";
			}
		}

		if (props.formValue.id) {
			values["id"] = props.formValue.id;
		}

		if (createGeneralData) {
			postNewGeneralData(values);
		} else {
			postUpdateGeneralData(values);
		}
	};

	const fetchAllTypeData = async () => {
		const param = {};

		const response = await fetchGeneralTypeData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			const transformed = response.map(({ type }) => ({
				value: type,
				desc: type,
			}));

			setTypeOptions(transformed);
		}
	};

	const postNewGeneralData = async (param) => {
		const response = await createNewGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				message.success(response.message, 3);
				props.onCancel();
				props.fetchGeneralDataListData({});
			}
		}
	};

	const postUpdateGeneralData = async (param) => {
		const response = await updateGeneralData({
			api,
			data: param,
			dispatch,
		});

		if (response) {
			if (response.code === 200) {
				message.success(response.message, 10);
				props.onCancel();
				props.fetchGeneralDataListData({});
			}
		}
	};

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	useEffect(() => {
		fetchAllTypeData();
		if (Object.keys(props.formValue).length !== 0) {
			setCreateGeneralData(0);
			form.setFieldsValue({
				type: props.formValue.type,
				code: props.formValue.code,
				name: props.formValue.name,
				seq_no: props.formValue.seq_no,
			});
			if (props.formValue.status === "Active") {
				setSwitchStatus(true);
			} else {
				setSwitchStatus(false);
			}
		} else {
			setCreateGeneralData(1);
			form.resetFields();
		}
	}, [props.formValue]);

	return (
		<Modal
			title={
				createGeneralData
					? "General Data Form"
					: "General Data Edit Form"
			}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			forceRender
		>
			<Form
				{...layout}
				form={form}
				name="general_data_form"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				validateMessages={validateMessages}
				scrollToFirstError
				initialValues={initialValues}
				validateTrigger="onBlur"
			>
				<SelectField
					label="Option Type"
					name="type"
					placeholder="Option Type"
					options={typeOptions}
					rules={[
						{ type: "", required: true },
						{
							validator: async (rule, value) => {
								if (value) {
									//const param = { id: id, user_group: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Code"
					name="code"
					placeholder="Code"
					rules={[
						{ required: true, whitespace: true },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									//const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Option Name"
					name="name"
					placeholder="Option Name"
					rules={[
						{ required: true, whitespace: true },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									//const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Sequence Number"
					name="seq_no"
					placeholder="Sequence Number"
					rules={[
						{ required: true },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									//const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				{createGeneralData ? (
					<></>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={switchStatus}
						switchChange={switchOnChange}
						initialValue
					/>
				)}
				<div style={{ textAlign: "right", paddingTop: "2rem" }}>
					<Button
						key="back"
						onClick={props.onCancel}
						style={{ marginRight: "10px" }}
					>
						Cancel
					</Button>
					<Button key="submit" type="primary" htmlType="submit">
						{createGeneralData ? "Submit" : "Update"}
					</Button>
				</div>
			</Form>
		</Modal>
	);
};

export default GeneralDataFormModal;
