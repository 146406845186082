import React from "react";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Image, Space, Table, Tag, Button } from 'antd';

export default function DatatableCountry(props) {
    const columns = [
        {
            title: 'No',
            key: 'id',
            render: (value, item, index) =>
				(props.pagination.current - 1) * props.pagination.pageSize +
				index +
				1,
        },
        {
            title: 'Country Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Country Code',
            dataIndex: 'code',
            key: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
        },
        {
            title: 'Currency Code',
            dataIndex: 'currency_code',
            key: 'currency_code',
            sorter: (a, b) => a.currency_code.localeCompare(b.currency_code),
        },
        {
            title: 'Currency Name',
            dataIndex: 'currency_name',
            key: 'currency_name',
            sorter: (a, b) => a.currency_name.localeCompare(b.currency_name),
        },
        {
            title: 'Prefix Calling Code',
            dataIndex: 'calling_no_prefix',
            key: 'calling_no_prefix',
            sorter: (a, b) => a.calling_no_prefix.localeCompare(b.calling_no_prefix),
        },
        {
            title: 'Preferred Language',
            dataIndex: 'prefer_language_code',
            key: 'prefer_language_code',
            sorter: (a, b) => a.prefer_language_code.localeCompare(b.prefer_language_code),
        },
        {
            title: 'Flag',
            dataIndex: 'image_path',
            key: 'image_path',
            width: '5%',
            render: (_, { image_path }) => (
            <>
                {image_path ? <Image src={image_path} /> : ''}
            </>
            ),
        },
        {
            title: 'Sequence Number',
            dataIndex: 'seq_no',
            key: 'seq_no',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <>
                    <Tag color={status == 'Active' ? 'green' : 'red'} key={status}>
                        {status.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        icon={<EditOutlined />}
                        onClick={() => {
                            props.showCountryFormModal(record);
                        }}>
                    </Button>
                    <Button 
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            props.handleRemoveRecord(record)
                        }}>
                    </Button>
                </Space>
            ),
        },
    ];
    return(
        <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={props.dataSource}
            pagination={props.pagination}
            loading={props.loading}
            onChange={props.handleTableChange}
        />
    );
}
