import { Button, Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import CheckboxField from "../../Global/FormInputComponent/CheckboxField";
import SwitchField from "../../Global/FormInputComponent/SwitchField";
import TextField from "../../Global/FormInputComponent/TextField";
import DragTree from "./MenuTree";

const MenuSetup = () => {
  const [form] = Form.useForm();
  const [switchStatus, setSwitchStatus] = useState(true);
  const [formValue, setFormValue] = useState({});
  const initialValues = {};

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
    },
    validator: "Duplicate Data",
  };

  const switchOnChange = (checked) => {
    setSwitchStatus(checked);
  };

  const onFinish = async (values) => {};

  useEffect(() => {
    form.setFieldsValue({
      menu_name: formValue.menu_name,
      parent_menu: formValue.parent_menu,
    });
  }, [formValue]);

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card title="Menu List" className="h-100">
          {/* <MenuTree setFormValue={setFormValue} /> */}
          {/* <MenuTreeEditable setFormValue={setFormValue} /> */}
          <DragTree setFormValue={setFormValue} form={form} />
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Menu Form" className="h-100">
          <Form
            {...layout}
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
            onFinish={onFinish}
            validateMessages={validateMessages}
            scrollToFirstError
            initialValues={initialValues}
            validateTrigger="onBlur"
          >
            <TextField
              label="Parent Menu"
              name="parent_menu"
              placeholder="Parent Menu"
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
              readonly
            />
            <TextField
              label="Menu Name"
              name="menu_name"
              placeholder="Menu Name"
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
            />
            <TextField
              label="Seq. No."
              name="seq_no"
              placeholder="Seq. No."
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
              type="number"
            />
            <TextField
              label="Menu Icon"
              name="icon"
              placeholder="Menu Icon"
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
            />
            <TextField
              label="Menu Link"
              name="link"
              placeholder="Menu Link"
              rules={[{ required: true, whitespace: true }]}
              hasFeedback
              type="url"
            />
            <SwitchField
              label="Status"
              name="status"
              switchStatus={switchStatus}
              switchChange={switchOnChange}
            />
            <CheckboxField
              label="Open Method"
              name="new_tab"
              options={[
                {
                  value: "1",
                  desc: "Open at new tab",
                },
              ]}
            />
            <div style={{ textAlign: "right", paddingTop: "2rem" }}>
              <Button key="back" style={{ marginRight: "10px" }}>
                Cancel
              </Button>
              <Button key="submit" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default MenuSetup;
