import Icon from "@ant-design/icons";
import { Checkbox, Col, Form, message, Row, Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getStepDetails,
  postClientInfos,
} from "../../../actions/Member/actionCreators";
import { AxiosContext } from "../../../actions/Member/Axios/axios";
import CardSetting from "../../Merchant/Global/SettingComponent/CardSetting";

const BookingStep3 = () => {
  const { Step } = Steps;
  const navigate = useNavigate();
  const { booking_url } = useParams();
  const location = useLocation();
  const tmpUserId = location.state ? location.state.id : "";
  const [userId, setUserId] = useState(tmpUserId);
  const [clientInfo, setClientInfo] = useState("");
  const [staff, setStaff] = useState([]);
  const [logo, setLogo] = useState("");
  const [checked, setChecked] = useState("");
  const [step, setStep] = useState([]);
  const [prevStep, setPrevStep] = useState("");
  const [nxtStep, setNxtStep] = useState("");
  let key = 0;

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const color = ["#4086F4", "#A7B397", "#F440E6", "#5D9F05", "#DDCC00"];
  const NextSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.875"
      height="16.716"
      viewBox="0 0 32.875 16.716"
    >
      <path
        id="btn-next"
        d="M32.583,7.651,25.225.293A1,1,0,1,0,23.81,1.707l5.652,5.651H1a1,1,0,0,0,0,2H29.462L23.81,15.009a1,1,0,0,0,1.415,1.414l7.358-7.358a1,1,0,0,0,0-1.414"
        transform="translate(0 0)"
        fill="#ffffff"
      />
    </svg>
  );

  const NextIcon = (props) => <Icon component={NextSvg} {...props} />;

  const fetchStepDetails = async () => {
    var param = {};
    param["current_step"] = "STY";
    if (userId) {
      param["tmp_user_id"] = userId;
    }

    const response = await getStepDetails({
      data: param,
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setUserId(response.tmp_user_id);
      setClientInfo(response.current_step_values);
      setLogo(response.logo);
      setStaff(response.current_step_data);
      setNxtStep(response.next_step);
      setPrevStep(response.previous_step);
      setStep(response.booking_steps);
    }
  };

  const postClientStaff = async (formData) => {
    formData.append("tmp_user_id", userId);
    let param = [];
    param["booking_url"] = booking_url;
    param["step_code"] = "STY";

    const response = await postClientInfos({
      api,
      data: formData,
      param: param,
      dispatch,
    });

    if (response) {
      let step = "";
      if (nxtStep == "SVN") {
        step = "/step1";
      } else if (nxtStep == "STY") {
        step = "/step2";
      } else if (nxtStep == "DTE") {
        step = "/step3";
      } else if (nxtStep == "COM") {
        step = "/completed";
      }

      navigate("/appointment/" + booking_url + step, {
        state: { id: userId },
      });
    }
  };

  const onFinish = () => {
    const formData = new FormData();

    if (checked) {
      if (checked != "Any") {
        staff.map((stf, key) => {
          if (stf) {
            if (checked == stf["id"]) {
              formData.set("staff_id", stf["id"]);
              formData.set("staff_code", stf["code"]);
            }
          }
        });
      }
    }

    postClientStaff(formData);
    // navigate("/appointment/" + booking_url + "/step3");
  };
  const onFinishFailed = () => {};

  const checkboxOnChange = (event) => {
    console.log(event[0], staff.length + 1);
    if (checked) {
      if (!event[0]) {
        staff.map((stf, key) => {
          form.setFieldsValue({
            ["staffs"]: { [key]: "" },
          });
        });
        form.setFieldsValue({
          ["staffs"]: { [staff.length]: "" },
        });
        setChecked("");
      } else {
        // message.error("Only 1 Stylist allowed to select");
        if (event[0] == "Any") {
          form.setFieldsValue({
            ["staffs"]: { [staff.length]: "Any" },
          });
          staff.map((stf, key) => {
            form.setFieldsValue({
              ["staffs"]: { [key]: "" },
            });
          });
        } else {
          staff.map((stf, key) => {
            if (event[0] == stf["id"]) {
              form.setFieldsValue({
                ["staffs"]: { [key]: stf["id"] },
              });
            } else {
              form.setFieldsValue({
                ["staffs"]: { [key]: "" },
              });
            }
          });
          form.setFieldsValue({
            ["staffs"]: { [staff.length]: "" },
          });
        }
      }
    } else {
      setChecked(event[0]);
    }
  };

  useEffect(() => {
    if (clientInfo.length > 0 || clientInfo.tmp_user_id) {
      if (clientInfo.staff_id) {
        staff.map((stf, key) => {
          if (clientInfo["staff_id"].includes(stf["id"])) {
            form.setFieldsValue({
              ["staffs"]: { [key]: stf["id"] },
            });
          }
        });
      } else {
        form.setFieldsValue({
          ["staffs"]: { [staff.length]: "Any" },
        });
      }
    }
  }, [clientInfo]);

  useEffect(() => {
    fetchStepDetails();
  }, []);

  return (
    <div className="booking-bg">
      <div className="booking-bg-dark">
        <div className="booking-step-content">
          {logo ? (
            <img src={logo} className="member-merchant-logo" />
          ) : (
            <h3>LOGO</h3>
          )}
          <Steps current={2} size="small" labelPlacement="vertical">
            {step
              ? step.map((stp, ky) => {
                  return <Step key={ky} title={stp.name} />;
                })
              : ""}
          </Steps>

          <h4>CHOOSE STYLIST</h4>
          <p style={{ color: "#C9C9C9", marginBottom: "2rem" }}>
            Find a great stylist to do your hair
          </p>
        </div>
        <div className="container">
          <Form
            name="appointment_staff"
            initialValues={{}}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={20}>
              {staff.length > 0
                ? staff.map((stf, index) => {
                    if (!color[key]) {
                      key = 0;
                    }

                    let set_color = color[key];
                    let name = "";
                    key++;
                    return (
                      <Col
                        key={index}
                        xs={24}
                        md={8}
                        style={{ marginBottom: "2rem" }}
                      >
                        <CardSetting
                          avatar={stf.img_path}
                          color={stf.status == "Active" ? set_color : ""}
                          background={stf.status == "Inactive" ? "#bfbfbf" : ""}
                          name={stf.name}
                          detail={stf.merchant_staff_title_name}
                          action={
                            <Form.Item noStyle name={["staffs", [index]]}>
                              <Checkbox.Group
                                className="merchant-checkbox"
                                options={[
                                  {
                                    value: stf.id,
                                  },
                                ]}
                                onChange={checkboxOnChange}
                              />
                            </Form.Item>
                          }
                        />
                      </Col>
                    );
                  })
                : ""}
              <Col xs={24} md={8} style={{ marginBottom: "2rem" }}>
                <CardSetting
                  avatar=""
                  color="#DDCC00"
                  background=""
                  name="Any Stylist"
                  detail=""
                  action={
                    <Form.Item noStyle name={["staffs", [staff.length]]}>
                      <Checkbox.Group
                        className="merchant-checkbox"
                        options={[
                          {
                            value: "Any",
                          },
                        ]}
                        onChange={checkboxOnChange}
                      />
                    </Form.Item>
                  }
                />
              </Col>
            </Row>
            <div className="booking-step-content">
              <Row
                gutter={20}
                justify="space-between"
                className="w-100"
                style={{ margin: "2rem 0" }}
              >
                <Col md={12} style={{ textAlign: "right" }}>
                  <button
                    className="btn-clear w-50"
                    onClick={() => {
                      let step = "";
                      if (prevStep == "CLIENTINFO") {
                        step = "";
                      } else if (prevStep == "SVN") {
                        step = "/step1";
                      } else if (prevStep == "STY") {
                        step = "/step2";
                      } else if (prevStep == "DTE") {
                        step = "/step3";
                      } else if (prevStep == "COM") {
                        step = "/completed";
                      }
                      navigate("/appointment/" + booking_url + step, {
                        state: { id: userId },
                      });
                    }}
                  >
                    <img src="/icons/btn-previous.svg"></img>
                  </button>
                </Col>
                <Col md={12} style={{ textAlign: "left" }}>
                  <button
                    key="submit"
                    htmltype="submit"
                    className="btn-secondary w-50"
                    style={{ minWidth: "110px" }}
                  >
                    <NextIcon />
                  </button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BookingStep3;
