import React, { useEffect, useState } from "react";
import "antd/dist/antd.less";
import MainRoutes from "../../routes/Admin/main";
import Login from "../../components/Admin/Login/Login";
import { Layout } from "antd";
import MenuLayout from "./aside-menu";
import FooterLayout from "./footer";
import HeaderLayout from "./header";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { Content } = Layout;

const AdminMasterLayout = () => {
	// const pathname = window.location.pathname;

	const [collapsed, setCollapsed] = useState(false);
	const [collapsedWidth, setCollapsedWidth] = useState(80);
	const navigate = useNavigate();

	const temp = useSelector((state) => ({
		isLoggedIn: state.AdminLoginReducer.isLoggedIn,
		userSession: state.AdminErrorReducer.token_expired,
	}));

	useEffect(() => {
		if (!temp.isLoggedIn) {
			navigate("/admin");
		}
	}, [navigate, temp.isLoggedIn, temp.userSession]);

	return (
		<>
			{temp.isLoggedIn ? (
				<Layout>
					<MenuLayout
						collapsed={collapsed}
						collapsedWidth={collapsedWidth}
						setCollapsedWidth={setCollapsedWidth}
					/>
					<Layout className="site-layout">
						<HeaderLayout
							collapsed={collapsed}
							setCollapsed={setCollapsed}
							collapsedWidth={collapsedWidth}
						/>
						<Content
							className="site-layout-background"
							style={{
								margin: "64px 16px",
								padding: "24px 0px",
								minHeight: "75vh",
							}}
						>
							<MainRoutes />
						</Content>
						<FooterLayout />
					</Layout>
				</Layout>
			) : (
				<Routes>
					<Route
						path="/admin"
						exact={true}
						element={<Login />}
						auth={false}
					/>
				</Routes>
			)}
		</>
	);
};

export default AdminMasterLayout;
