import React from "react";
import { Form, InputNumber } from "antd";

export default function InputNumberField(props) {
  return (
    <Form.Item
      className={
        props.mainClassName ? props.mainClassName : "merchant-form-item"
      }
      name={props.name}
      label={props.label}
      dependencies={props.dependencies}
      rules={props.rules}
      hasFeedback={props.hasFeedback}
      extra={props.extra}
      style={props.style}
      noStyle={props.noStyle ? true : false}
    >
      <InputNumber
        pattern={props.pattern}
        className={props.className ? props.className : "merchant-form-input"}
        size="large"
        type={props.type}
        placeholder={props.placeholder}
        disabled={props.disabled}
        style={props.inputStyle}
        formatter={props.formatter}
        parser={props.parser}
        step={props.step}
        stringMode={props.stringMode ? true : false}
        onChange={props.onChange}
        min={props.min}
        max={props.max}
        controls={false}
      />
    </Form.Item>
  );
}
