import { Avatar, Col, Form, message, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createStaff,
  fetchServices,
  fetchStaffTitle,
  updateStaff,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import InputField from "../../Global/FormInputComponent/InputField";
import InputNumberField from "../../Global/FormInputComponent/InputNumber";
import SelectField from "../../Global/FormInputComponent/SelectField";
import TextAreaField from "../../Global/FormInputComponent/TextAreaField";
import UploadField from "../../Global/FormInputComponent/UploadField";

export default function StylistForm(props) {
  const [descLength, setDescLength] = useState(0);
  const [descValue, setDescVal] = useState("");
  const [data, setData] = useState({});
  const [avatar, setAvatar] = useState(data.img_path);
  const [stfTitle, setStfTitle] = useState([]);
  const [service, setService] = useState([]);
  const [svsPrice, setSvsPrice] = useState([]);
  const [stfPrice, setStfPrice] = useState([]);
  const [removeID, setRemoveID] = useState([]);
  const [disabledAdd, setDisabledAdd] = useState(true);
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [importData, setImportData] = useState({
    dataList: [],
  });
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };
  const onFinish = (values) => {
    const formData = new FormData();

    if (importData.dataList.length > 0) {
      importData.dataList.forEach((file) => {
        if (!file.url.includes("https://media.smartcubesoft.com/")) {
          formData.append("img_path", file);
        }
      });
    } else {
      if (Object.values(props.data).length > 0) {
        formData.append("remove_img_path", 1);
      }
    }

    formData.append(
      "merchant_staff_title_id",
      values["merchant_staff_title_id"]
    );
    formData.append("name", values["name"]);
    formData.append("desc", descValue);

    if (stfPrice.length == 0) {
      message.error("Please add at least one price");
    } else {
      if (Object.values(props.data).length > 0) {
        let prd_master_price_id = Object.values(values["prd_master_price_id"]);
        let unit_prices = Object.values(values["unit_price"]);
        let staff_price_id = Object.values(values["staff_price_id"] ?? []);
        let unit_prices_array = unit_prices;

        unit_prices.map((unit_price, u_key) => {
          if (!unit_price) {
            removeID.push(staff_price_id[u_key]);
            unit_prices_array.splice(u_key, 1);
            prd_master_price_id.splice(u_key, 1);
            staff_price_id.splice(u_key, 1);
          }
        });
        unit_prices_array.map((unit_price, u_key) => {
          formData.append(`unit_price[${u_key}]`, unit_price);
          formData.append(
            `prd_master_price_id[${u_key}]`,
            prd_master_price_id[u_key]
          );
        });

        staff_price_id.map((stf_price, s_key) => {
          formData.append(`staff_price_id[${s_key}]`, stf_price);
        });

        removeID.map((rm_stf_price, s_key) => {
          formData.append(`remove_staff_price_id[${s_key}]`, rm_stf_price);
        });

        formData.append("_method", "PUT");
        // if (removeID.length > 0 && removeID[0] != "") {
        //   values["remove_price_ids"] = removeID;
        // }
        updateStylistSetting(formData);
      } else {
        Object.values(values["prd_master_price_id"]).map(
          (prd_master_price_id, p_key) => {
            formData.append(
              `prd_master_price_id[${p_key}]`,
              prd_master_price_id
            );
          }
        );
        Object.values(values["unit_price"]).map((unit_price, u_key) => {
          formData.append(`unit_price[${u_key}]`, unit_price);
        });

        postStylistForm(formData);
      }
    }
  };

  const postStylistForm = async (data) => {
    const response = await createStaff({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      message.success(response.message).then(props.getStaff());
      setStfPrice([]);
      setAvatar("");
      setImportData({ dataList: [] });
      form.resetFields();
    }
  };

  const updateStylistSetting = async (data) => {
    const response = await updateStaff({
      api,
      data: data,
      id: props.data.id,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      props.getStaff();
      setRemoveID([]);
      setData({});
      setStfPrice([]);
      setAvatar("");
      setImportData({ dataList: [] });
      form.resetFields();
    }
  };
  const onFinishFailed = () => {};
  const descOnChange = (event) => {
    if (event.target.value.length <= 120) {
      setDescLength(event.target.value.length);
      setDescVal(event.target.value);
    }
  };
  function triggerInput(enteredName, enteredValue) {
    const input = document.getElementsByClassName(enteredName);
    const lastValue = input[0].value;
    input[0].value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = input[0]._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input[0].dispatchEvent(event);
  }

  function array_column(array, column) {
    const stf_prc_id = [];

    array.forEach((e) => {
      stf_prc_id.push(e[column]);
    });

    return stf_prc_id;
  }

  const priceOnChange = () => {};

  const getStaffTitle = async () => {
    const response = await fetchStaffTitle({ api, dispatch });

    if (response) {
      let stf_title = [];
      response.map((title) => {
        stf_title.push({
          value: title.id,
          desc: title.name,
        });
      });
      setStfTitle(stf_title);
    }
  };

  const getServices = async () => {
    const response = await fetchServices({ api, dispatch });

    if (response) {
      let services = [];
      response.map((service) => {
        services.push({
          value: service.id,
          desc: service.name,
          prices: service.prices,
        });
      });
      setService(services);
    }
  };

  const svsOnChange = async (value) => {
    service.map((svs, index) => {
      if (svs.value == value) {
        setSvsPrice(service[index].prices);
      }
    });
    setDisabledAdd(false);
  };

  const addStaffPrice = (event) => {
    let error = 1;

    const array = [];

    svsPrice.map((svs, index) => {
      if (form.getFieldsValue()["price"][index]) {
        error = 0;
        array.push({
          prd_master_price_id: svs.id,
          price_name: svs.name,
          min_price: svs.min_price,
          max_price: svs.max_price == "0.00" ? svs.min_price : svs.max_price,
          service: form.getFieldValue("staff_service"),
          unit_price: form.getFieldsValue()["price"][index],
        });
      }
    });

    if (error == 1) {
      message.error("Please insert price before add");
      return;
    }

    if (stfPrice.length > 0) {
      stfPrice.map((stp, index) => {
        if (stp.service == form.getFieldValue("staff_service")) {
          error = 2;
          return;
        }
      });
    }

    if (error == 2) {
      message.error("Duplicate Service");
      return;
    }

    let stfArray = [...stfPrice];
    stfArray.push({
      service_id: form.getFieldValue("staff_service"),
      price: array,
    });
    setStfPrice(stfArray);
    form.setFieldValue("staff_service", "");
    setSvsPrice([]);
  };

  const removeStaffPrice = (event) => {
    let index = event.target.getAttribute("data-ind");
    let id = event.target.getAttribute("data-id");

    var array = [...stfPrice];
    if (index !== -1) {
      array.splice(index, 1);
      setStfPrice(array);
    }

    var removeArray = [...removeID];
    if (id != ",") {
      const new_id = id.split(",");
      new_id.map((n_id) => {
        removeArray.push(n_id);
      });
      setRemoveID(removeArray);
    }
  };

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    setData(props.data);
    if (Object.values(props.data).length > 0) {
      if (props.data.prices.length > 0) {
        let array = [];
        const grouped_price = groupBy(props.data.prices, "product_name");

        Object.values(grouped_price).map((group) => {
          array.push({
            service_id: group[0].product_name,
            price: group,
          });
        });

        setStfPrice(array);
      }
    } else {
      setStfPrice([]);
    }
  }, [props.data]);

  useEffect(() => {
    var selected_option = [];
    if (data.merchant_staff_title_name) {
      selected_option = stfTitle.filter(
        (val) => val.desc === data.merchant_staff_title_name
      );
    }

    form.setFieldsValue({
      name: data.name,
      merchant_staff_title_id:
        selected_option.length > 0 ? selected_option[0].value : "",
      desc: data.desc,
    });

    setDescVal(data.desc ?? "");
    triggerInput("description", data.desc ?? "");
    if (data.img_path) {
      setImportData({ dataList: [{ url: data.img_path }] });
    } else {
      setImportData({ dataList: [] });
    }
  }, [data]);

  useEffect(() => {
    getStaffTitle();
    getServices();
  }, []);

  return (
    <Form
      {...layout}
      form={form}
      name="stylist_form"
      initialValues={props.data}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateMessages={validateMessages}
    >
      <Row>
        <Col xs={12} md={8} style={{ height: "110px" }}>
          <Avatar
            size={105}
            style={{
              backgroundColor: "#ffffff",
            }}
            icon={<img src={avatar}></img>}
          />
          <UploadField
            name="avatar"
            setImportData={setImportData}
            importData={importData}
          />
        </Col>
      </Row>
      <Row justify="space-between" className="header-color">
        <Col span={8} style={{ padding: "6.5px 0" }}>
          <label>STYLIST NAME</label>
        </Col>
        <Col span={16}>
          <InputField
            mainClassName=" "
            style={{ marginBottom: "0px", textAlign: "end" }}
            placeholder="Enter Name"
            className="merchant-input-no-border"
            name="name"
            rules={[{ required: true, whitespace: true }]}
            hasFeedback
          />
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row justify="space-between" className="header-color">
        <Col span={8} style={{ padding: "6.5px 0" }}>
          <label>SERVICE TITLE</label>
        </Col>
        <Col span={16}>
          <SelectField
            mainClassName=" "
            style={{ marginBottom: "0px", textAlign: "end" }}
            className="merchant-input-no-border"
            name="merchant_staff_title_id"
            options={stfTitle}
            selectStyle={{ fontSize: "16px" }}
            rules={[{ required: true }]}
            hasFeedback
          />
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row justify="space-between" className="header-color">
        <Col style={{ padding: "6.5px 0" }} span={12}>
          SERVICE DESCRIPTION
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          {descLength} / 120
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={24}>
          <TextAreaField
            name="desc"
            inputStyle={{ padding: "10px 0", border: "none" }}
            placeholder="Enter Description"
            maxLength={120}
            onChange={descOnChange}
            autoSize={{
              minRows: 3,
              maxRows: 3,
            }}
            value={descValue}
          />
        </Col>
      </Row>
      <hr style={{ marginTop: "1rem", marginBottom: "2rem" }} />
      <Row style={{ marginBottom: "1rem" }}>
        <Col span={24}>HAIR DESIGN PRICE</Col>
      </Row>
      {stfPrice.length > 0
        ? stfPrice.map((stfprc, index) => {
            return (
              <div
                key={index}
                style={{
                  border: "1px solid var(--border-color)",
                  padding: "10px",
                  marginBottom: "1rem",
                }}
              >
                <Row
                  justify="space-between"
                  className="header-color"
                  style={{ alignItems: "center" }}
                >
                  <Col span={8}>
                    <label>SERVICES</label>
                  </Col>
                  <Col span={16}>
                    <SelectField
                      mainClassName=" "
                      style={{ marginBottom: "0px", textAlign: "end" }}
                      className="merchant-input-no-border"
                      name={["service_id", [index]]}
                      options={service}
                      selectStyle={{ fontSize: "16px" }}
                      disabled
                    />
                  </Col>
                  {form.setFieldsValue({
                    ["service_id"]: { [index]: stfprc.service_id },
                  })}
                </Row>
                {stfprc.price.map((prc, key) => {
                  return (
                    <Row
                      key={key}
                      justify="space-between"
                      className="header-color"
                      style={{ alignItems: "center" }}
                    >
                      <Col span={8}>
                        <label>{prc.price_name}</label>
                      </Col>
                      <Col span={16}>
                        <InputNumberField
                          name={["unit_price", [2 * index + key]]}
                          style={{ marginBottom: "0px", textAlign: "end" }}
                          className="merchant-input-no-border"
                          formatter={(value) =>
                            `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
                          step="0.01"
                          stringMode
                          min={prc.min_price}
                          max={
                            prc.max_price == "0.00"
                              ? prc.min_price
                              : prc.max_price
                          }
                        />
                        <InputField
                          mainClassName=" "
                          style={{ marginBottom: "0px" }}
                          inputStyle={{ textAlign: "start" }}
                          className="merchant-input-no-border"
                          name={["prd_master_price_id", [2 * index + key]]}
                          hidden={true}
                        />
                      </Col>
                      {form.setFieldsValue({
                        ["prd_master_price_id"]: {
                          [2 * index + key]: prc.prd_master_price_id,
                        },
                        ["unit_price"]: { [2 * index + key]: prc.unit_price },
                      })}
                      {prc.id ? (
                        <>
                          <InputField
                            mainClassName=" "
                            style={{ marginBottom: "0px" }}
                            inputStyle={{ textAlign: "start" }}
                            className="merchant-input-no-border"
                            name={["staff_price_id", [2 * index + key]]}
                            hidden={true}
                          />
                          {form.setFieldsValue({
                            ["staff_price_id"]: {
                              [2 * index + key]: prc.id,
                            },
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  );
                })}
                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <Row gutter={20} justify="start">
                  <Col span={8}>
                    <a
                      className="btn-primary w-100"
                      style={{ padding: "4px 10px", minWidth: "70px" }}
                      onClick={removeStaffPrice}
                      data-ind={index}
                      data-id={array_column(stfprc.price, "id")}
                    >
                      Remove
                    </a>
                  </Col>
                </Row>
              </div>
            );
          })
        : ""}
      <div style={{ border: "1px solid var(--border-color)", padding: "10px" }}>
        <Row
          justify="space-between"
          className="header-color"
          style={{ alignItems: "center" }}
        >
          <Col span={8}>
            <label>SERVICES</label>
          </Col>
          <Col span={16}>
            <SelectField
              mainClassName=" "
              style={{ marginBottom: "0px", textAlign: "end" }}
              className="merchant-input-no-border"
              name="staff_service"
              options={service}
              selectStyle={{ fontSize: "16px" }}
              onChange={svsOnChange}
            />
          </Col>
        </Row>
        {svsPrice.length > 0
          ? svsPrice.map((svsprc, index) => {
              return (
                <Row
                  key={index}
                  justify="space-between"
                  className="header-color"
                  style={{ alignItems: "center" }}
                >
                  <Col span={8}>
                    <label>{svsprc.name}</label>
                  </Col>
                  <Col span={16}>
                    <InputNumberField
                      name={["price", [index]]}
                      style={{ marginBottom: "0px", textAlign: "end" }}
                      className="merchant-input-no-border"
                      formatter={(value) =>
                        `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
                      step="0.01"
                      stringMode
                      min={svsprc.min_price}
                      max={
                        svsprc.max_price == "0.00"
                          ? svsprc.min_price
                          : svsprc.max_price
                      }
                    />
                  </Col>
                </Row>
              );
            })
          : ""}
        <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
        <Row gutter={20} justify="end">
          <Col>
            <a
              className="btn-clear w-100"
              style={{ padding: "4px 10px", minWidth: "70px" }}
              onClick={addStaffPrice}
              disabled={disabledAdd}
            >
              Add
            </a>
          </Col>
        </Row>
      </div>
      <Row style={{ margin: "2rem 0" }}>
        <Col span={24}>
          <button htmltype="submit" className="btn-secondary w-100">
            {Object.keys(props.data).length > 0 ? "Update" : "Save"}
          </button>
        </Col>
      </Row>
    </Form>
  );
}
