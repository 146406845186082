import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import React, { useEffect, useState, useContext } from "react";
import SelectField from "../Global/FormInputComponent/SelectField";
import SwitchField from "../Global/FormInputComponent/SwitchField";
import TextField from "../Global/FormInputComponent/TextField";
import UploadField from "../Global/FormInputComponent/UploadField";
import DatePickerField from "../Global/FormInputComponent/DatePickerField";
import { postCreateForm,
				getMerchantDetailsList, 
			} from "../../../actions/Admin/actionCreators";
import { AxiosContext } from "../../../actions/Admin/Axios/axios";
import { useDispatch } from "react-redux";
import moment from "moment";

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const MerchantStaffFormModal = (props) => {
	const api = useContext(AxiosContext);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [createMerchantStaff, setCreateMerchantStaff] = useState(true);
  const [merchantDetailsSource, setMerchantDetailsSource] = useState([]);
	// const [initValue, setInitValue] = useState({});
	const [switchStatus, setSwitchStatus] = useState(true);
	const [importData, setImportData] = useState({
		dataList: [],
	});
	const initialValues = {};
	const id = 0;

	const validateMessages = {
		required: "${label} is required!",
		types: {
			email: "${label} is not a valid email!",
		},
		validator: "Duplicate Data",
	};

	useEffect(() => {
		fetchMerchantDetails();
	}, [props.formValue0]);

	const fetchMerchantDetails = async () => {
    const param = {};
    param["status"] = "A";

    const response = await getMerchantDetailsList({
      api,
      data: param,
      dispatch,
    });

    if (response) {
      const transformed = response.map(({ code, name, shop_name }) => ({
        value: shop_name,
        desc: shop_name,
      }));

      setMerchantDetailsSource(transformed);
    }
  };

	// const onSubmit = handleSubmit(async (data) => {
	//     const formData = new FormData();

	//     formData.append('locale', data.locale);
	//     formData.append('code', data.code);
	//     formData.append('name', data.name);
	//     formData.append('image', data.image);
	//     formData.append('status', data.status);

	//     const response = await postCreateForm({
	//         api: api,
	//         data: formData,
	//         method: "POST",
	//         url: "languages",
	//     });

	//     if (response) {
	// 		if (response.status === 200) {
	// 			// if (response.data.msgType == "success") {
	// 			// 	navigate("/admin/parents-list");
	// 			// }
	// 		}
	// 	}
	// })

	const onFinish = async (values) => {
		if (id) {
			values["id"] = id;
			values["status"] = switchStatus;
		}
	};

	const switchOnChange = (checked) => {
		setSwitchStatus(checked);
	};

	useEffect(() => {
		if (Object.keys(props.formValue).length != 0) {
			setCreateMerchantStaff(0);
			form.setFieldsValue({
				parent_name: props.formValue.merchant_name,
				name: props.formValue.name,
				email: props.formValue.email,
				mobile_no: props.formValue.mobile_no,
				dob: moment(props.formValue.dob),

				// seq_no: props.formValue.seq_no,
			});
			if (props.formValue.status == "Active") {
				setSwitchStatus(true);
			} else {
				setSwitchStatus(false);
			}
		} else {
			setCreateMerchantStaff(1);
			form.resetFields();
		}
	}, [props.formValue]);

	return (
		<Modal
			title={
				createMerchantStaff
					? "Merchant Staff Form"
					: "Merchant Staff Edit Form"
			}
			visible={props.visible}
			centered
			onCancel={props.onCancel}
			footer={null}
			forceRender
		>
			<Form
				{...layout}
				form={form}
				name="general_data_form"
				className="ant-advanced-search-form"
				onFinish={onFinish}
				validateMessages={validateMessages}
				scrollToFirstError
				initialValues={initialValues}
				validateTrigger="onBlur"
			>
				<SelectField
					label="Merchant Name"
					name="merchant_name"
					placeholder="Merchant Name"
					options={merchantDetailsSource}
					hasFeedback
				/>
				<TextField
					label="Parent Name"
					name="parent_name"
					placeholder="Parent Name"
					rules={[
						{ required: true, whitespace: true },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Full Name"
					name="name"
					placeholder="Full Name"
					rules={[
						{ required: true, whitespace: true, length: 3 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<TextField
					label="Email"
					name="email"
					placeholder="Email"
					style={{ width: '100%' }}
					rules={[
						{ required: true, whitespace: true, length: 3 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<DatePickerField
					label="Date of Birth"
					name="dob"
					placeholder="yyyy-mm-dd"
					rules={[{ 
						required: true, 
					}]}
				/>
				<TextField
					label="Mobile No."
					name="mobile_no"
					placeholder="Mobile No."
					rules={[
						{ required: true, whitespace: true, length: 3 },
						{
							validator: async (rule, value, callback) => {
								if (value) {
									const param = { id: id, nick_name: value };
									//   const validate = await checkAdminDuplicate({
									//     api: api,
									//     data: param,
									//     dispatch,
									//   });
									//   const validation = validate.data;
									//   if (validation.error == 1) {
									//     throw new Error(validation.msg);
									//   }
								}
							},
						},
					]}
					hasFeedback
				/>
				<UploadField
					setImportData={setImportData}
					importData={importData}
					label="Flag"
				/>
				{createMerchantStaff ? (
					<>
					</>
				) : (
					<SwitchField
						label="Status"
						name="status"
						switchStatus={switchStatus}
						switchChange={switchOnChange}
						initialValue
					/>
				)}
				<div style={{ textAlign: "right", paddingTop: "2rem" }}>
					<Button
						key="back"
						onClick={props.onCancel}
						style={{ marginRight: "10px" }}
					>
						Cancel
					</Button>
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						// onClick={onSubmit}
					>
						{createMerchantStaff ? "Submit" : "Update"}
					</Button>
					`
				</div>
			</Form>
		</Modal>
	);
};

export default MerchantStaffFormModal;
