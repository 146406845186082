import { FlagOutlined, LockOutlined, MobileOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Input, Row, Select, message } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { LoginAction } from "../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../actions/Merchant/Axios/axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/Merchant/login.css";

const { Option } = Select;

const MerchantLogin = () => {
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const [checkValue, setCheckValue] = useState(true);

  const onFinish = (values) => {
    values.mobile_no = values.mobile_prefix + values.mobile_no;
    dispatch(
      LoginAction({
        api,
        data: {
          mobile_no: values.mobile_no,
          password: values.password,
        },
      })
    );
  };

  const temp = useSelector((state) => ({
    isLoggedIn: state.MerchantLoginReducer.isLoggedIn, //true or false
    userSession: state.MerchantErrorReducer.token_expired,
  }));

  const steps = localStorage.getItem("steps");

  useEffect(() => {
    if (temp.isLoggedIn) {
      //&& steps == 0
      navigate("/merchant/dashboard");
    }

    if (temp.userSession) {
      message.destroy();
      message.error("session expired");
    }

    // if (temp.steps == 1) {
    //   navigate("/merchant/signupStep1");
    // }
  }, [temp.isLoggedIn, temp.userSession, temp.steps]);

  const onFinishFailed = () => {};

  const changeCheckbox = () => {
    setCheckValue(!checkValue);
  };

  document.body.style.backgroundImage = "url(/login-bg2.jpeg)";

  return (
    <div className="container">
      <Row className="py-4">
        <Col>
          <div className="MctLogo">Logo</div>
        </Col>
      </Row>
      <Row align="center" justify="center" id="signin-form">
        <Col>
          <div className="LoginCard">
            <div className="LoginHeader">Welcome Back</div>
            <p style={{ marginBottom: "2rem", color: "#E3E3E3" }}>
              Sign in to continue
            </p>
            <Form
              name="login"
              initialValues={{
                remember: true,
                mobile_prefix: "60",
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item className="merchant-form-item">
                <Input.Group compact className="merchant-input-group">
                  <Input
                    className="merchant-input"
                    style={{ width: "10%" }}
                    prefix={
                      <MobileOutlined className="muted-color merchant-input-prefix" />
                    }
                  />
                  <Form.Item name="mobile_prefix" noStyle>
                    <Select
                      style={{ width: "40%" }}
                      className="merchant-mobile-select"
                    >
                      <Option value="60">
                        <FlagOutlined /> <span> + 60</span>
                      </Option>
                      <Option value="65">
                        <FlagOutlined /> <span> + 65</span>
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="mobile_no" noStyle>
                    <Input
                      type="text"
                      pattern="[0-9]*"
                      style={{
                        width: "50%",
                      }}
                      className="merchant-input-mobile"
                      placeholder="Mobile number"
                      value={val}
                      onChange={(e) =>
                        setVal((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <Form.Item
                className="merchant-form-item"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  className="merchant-input-password"
                  placeholder="******"
                  autoComplete="new-password"
                  prefix={
                    <LockOutlined className="muted-color merchant-input-prefix" />
                  }
                />
              </Form.Item>
              <Row style={{ marginBottom: "3rem" }}>
                <Col span={12}>
                  <Checkbox
                    checked={checkValue}
                    name="remember"
                    className="merchant-checkbox"
                    onChange={changeCheckbox}
                  >
                    Remember me
                  </Checkbox>
                </Col>
                <Col
                  span={12}
                  style={{
                    textAlign: "end",
                    color: "#E3E3E3",
                  }}
                >
                  Forgot password?
                </Col>
              </Row>
              <Form.Item>
                <button htmltype="submit" className="w-100 btn-primary">
                  Login
                </button>
              </Form.Item>
              <div className="text-center" style={{ marginTop: "2.5rem" }}>
                <div>
                  Don’t have an account?{" "}
                  <Link to="/merchant/signup" style={{ color: "#D3DEC3" }}>
                    Sign Up
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MerchantLogin;
