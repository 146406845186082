import Icon from "@ant-design/icons";
import { Checkbox, Col, DatePicker, Form, Radio, Row, Steps } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAvailableDates,
  getStepDetails,
  postClientInfos,
} from "../../../actions/Member/actionCreators";
import { AxiosContext } from "../../../actions/Member/Axios/axios";

const BookingStep4 = () => {
  const { Step } = Steps;
  const navigate = useNavigate();
  const { booking_url } = useParams();
  const location = useLocation();
  const tmpUserId = location.state ? location.state.id : "";
  const [userId, setUserId] = useState(tmpUserId);
  const [clientInfo, setClientInfo] = useState("");
  const [time, setTime] = useState([]);
  const [logo, setLogo] = useState("");
  const [step, setStep] = useState([]);
  const [prevStep, setPrevStep] = useState("");
  const [nxtStep, setNxtStep] = useState("");
  const [operationDays, setOperationDays] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const api = useContext(AxiosContext);
  const NextSvg = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.875"
      height="16.716"
      viewBox="0 0 32.875 16.716"
    >
      <path
        id="btn-next"
        d="M32.583,7.651,25.225.293A1,1,0,1,0,23.81,1.707l5.652,5.651H1a1,1,0,0,0,0,2H29.462L23.81,15.009a1,1,0,0,0,1.415,1.414l7.358-7.358a1,1,0,0,0,0-1.414"
        transform="translate(0 0)"
        fill="#ffffff"
      />
    </svg>
  );

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const NextIcon = (props) => <Icon component={NextSvg} {...props} />;

  const fetchStepDetails = async () => {
    var param = {};
    param["current_step"] = "DTE";
    if (userId) {
      param["tmp_user_id"] = userId;
    }

    const response = await getStepDetails({
      data: param,
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setUserId(response.tmp_user_id);
      setClientInfo(response.current_step_values);
      setLogo(response.logo);
      setNxtStep(response.next_step);
      setPrevStep(response.previous_step);
      setStep(response.booking_steps);

      if(response.current_step_data){
        let operation_arrays = [];
        response.current_step_data.map((hours, key) => {
          if(hours.operation_days){
            hours.operation_days.map((operation_day, key) => {
              if(!operation_arrays.includes(operation_day)){
                operation_arrays.push(operation_day);
              }
            });
          }
        });
        setOperationDays(operation_arrays);
      }

    }
  };

  const postClientDate = async (data) => {
    data["tmp_user_id"] = userId;
    let param = [];
    param["booking_url"] = booking_url;
    param["step_code"] = "DTE";

    const response = await postClientInfos({
      api,
      data: data,
      param: param,
      dispatch,
    });

    if (response) {
      let step = "";
      if (nxtStep == "SVN") {
        step = "/step1";
      } else if (nxtStep == "STY") {
        step = "/step2";
      } else if (nxtStep == "DTE") {
        step = "/step3";
      } else if (nxtStep == "COM") {
        step = "/completed";
      }

      navigate("/appointment/" + booking_url + step, {
        state: { id: userId },
      });
    }
  };

  const onFinish = (values) => {
    postClientDate(values);
  };

  const dateOnChange = async (event) => {
    const date_selected = moment(event).format("YYYY-MM-DD");
    getDates(date_selected);
  };

  const getDates = async (date_selected) => {
    const response = await getAvailableDates({
      data: { booking_date: date_selected },
      booking_url: booking_url,
      api,
      dispatch,
    });

    if (response) {
      setTime(response);
    }
  };

  const onFinishFailed = () => {};

  useEffect(() => {
    fetchStepDetails();
  }, []);

  useEffect(() => {
    if (clientInfo.length > 0 || clientInfo.booking_date) {
      form.setFieldsValue({
        date: moment(clientInfo.booking_date),
        booking_date: clientInfo.booking_date,
      });
      getDates(moment(clientInfo.booking_date).format("YYYY-MM-DD"));
    }
  }, [clientInfo]);

  return (
    <div className="booking-bg">
      <div className="booking-bg-dark">
        <div className="booking-step-content">
          {logo ? (
            <img src={logo} className="member-merchant-logo" />
          ) : (
            <h3>LOGO</h3>
          )}
          <Steps current={3} size="small" labelPlacement="vertical">
            {step
              ? step.map((stp, ky) => {
                  return <Step key={ky} title={stp.name} />;
                })
              : ""}
          </Steps>

          <h4>DATE & TIME</h4>
          <p style={{ color: "#C9C9C9", marginBottom: "2rem" }}>
            Pick a date and time
          </p>
        </div>
        <div className="container">
          <Form
            {...layout}
            name="appointment_date"
            initialValues={{}}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="booking-step-content">
              <Row>
                <Form.Item
                  className="merchant-form-item"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  hasFeedback
                  name="date"
                >
                  <DatePicker
                    style={{ border: "none" }}
                    bordered={false}
                    noStyle
                    onChange={dateOnChange}
                    disabledDate={(current) => {
                      // return moment().add(-1, "days") >= current;
                      return (moment().add(-1, "days") >= current || !operationDays.includes(current.format('ddd')));
                    }}
                  />
                </Form.Item>
              </Row>
              <Row
                justify="center"
                align="center"
                style={{ textAlign: "center" }}
              >
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Select Time"
                    style={{ color: "#fff" }}
                    name="booking_date"
                  >
                    <Radio.Group className="merchant-time-radio-btn">
                      {time
                        ? time.map((t, key) => {
                            return (
                              <Radio.Button
                                key={key}
                                value={t.date_value}
                                disabled={t.available ? false : true}
                              >
                                {t.date_display}
                              </Radio.Button>
                            );
                          })
                        : ""}
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={20}
                justify="center"
                className="w-100"
                style={{ margin: "2rem 0" }}
              >
                <Col md={12} style={{ textAlign: "right" }}>
                  <button
                    className="btn-clear w-50"
                    onClick={() => {
                      let step = "";
                      if (prevStep == "CLIENTINFO") {
                        step = "";
                      } else if (prevStep == "SVN") {
                        step = "/step1";
                      } else if (prevStep == "STY") {
                        step = "/step2";
                      } else if (prevStep == "DTE") {
                        step = "/step3";
                      } else if (prevStep == "COM") {
                        step = "/completed";
                      }
                      navigate("/appointment/" + booking_url + step, {
                        state: { id: userId },
                      });
                    }}
                  >
                    <img src="/icons/btn-previous.svg"></img>
                  </button>
                </Col>
                <Col md={12} style={{ textAlign: "left" }}>
                  <button
                    key="submit"
                    htmltype="submit"
                    className="btn-secondary w-50"
                    style={{ minWidth: "110px" }}
                  >
                    <NextIcon />
                  </button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default BookingStep4;
