import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Button, Tree } from "antd";

const { TreeNode } = Tree;

const DragTree = (props) => {
  let data = [
    {
      value: "Main Menu (1)",
      key: "0-0",
      children: [
        {
          value: "Member",
          defaultValue: "Member",
          key: "0-1",
          parentKey: "0-0",
          isEditable: false,
          children: [
            {
              value: "Member List",
              defaultValue: "Member List",
              key: "0-1-1",
              parentKey: "1-1",
              isEditable: false,
            },
          ],
        },
        {
          value: "Admin",
          defaultValue: "Admin",
          key: "0-2",
          parentKey: "0-0",
          isEditable: false,
          children: [
            {
              value: "Admin List",
              defaultValue: "Admin List",
              key: "0-2-1",
              parentKey: "2-1",
              isEditable: false,
            },
            {
              value: "Admin Setup",
              defaultValue: "Admin Setup",
              key: "0-2-2",
              parentKey: "2-2",
              isEditable: false,
            },
          ],
        },
        {
          value: "Merchant",
          defaultValue: "Merchant",
          key: "0-3",
          parentKey: "0-0",
          isEditable: false,
          children: [
            {
              value: "Merchant List",
              defaultValue: "Merchant List",
              key: "0-3-1",
              parentKey: "3-1",
              isEditable: false,
            },
            {
              value: "Merchant Category",
              defaultValue: "Merchant Category",
              key: "0-3-2",
              parentKey: "3-2",
              isEditable: false,
            },
          ],
        },
      ],
    },
  ];
  let expandedKeyss = [];
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [treeData, setTreeData] = useState(data);

  const onExpand = (expandedKeys) => {
    console.log("onExpand", expandedKeys);
    expandedKeyss = expandedKeys;
    setExpandedKeys(expandedKeys);
  };

  const addNode = (key, data) =>
    data.map((item) => {
      if (item.key === key) {
        let newNodeKey = key + Math.random(100);
        if (item.children) {
          item.children.push({
            value: "New Node",
            defaultValue: "New Node",
            key: newNodeKey, //This key should be unique. Tip: The key should be unique
            parentKey: key,
            isEditable: false,
          });
        } else {
          item.children = [];
          item.children.push({
            value: "New Node",
            defaultValue: "New Node",
            key: newNodeKey,
            parentKey: key,
            isEditable: false,
          });
        }
        return;
      }
      if (item.children) {
        addNode(key, item.children);
      }
    });

  const onAdd = (e) => {
    console.log(selectedKey);
    console.log("add");
    if (expandedKeys.indexOf(selectedKey) === -1) {
      expandedKeyss.push(selectedKey);
    }
    addNode(selectedKey, data);
    setExpandedKeys(expandedKeyss);
    setTreeData(data);
  };

  const deleteNode = (key, data) =>
    data.map((item, index) => {
      if (item.key === key) {
        data.splice(index, 1);
        return;
      } else {
        if (item.children) {
          deleteNode(key, item.children);
        }
      }
    });

  const onDelete = () => {
    console.log("delete");
    deleteNode(selectedKey, data);
    setTreeData(data);
    props.form.resetFields();
  };

  const onSelect = (selectedKeys, info) => {
    console.log(info);
    setSelectedKey(selectedKeys[0]);
    props.setFormValue({
      //set form de value here
      parent_menu: getParentTitle(selectedKeys[0], treeData)
        ? getParentTitle(selectedKeys[0], treeData).props.children
        : "",
      menu_name: info.selectedNodes[0].defaultValue
        ? info.selectedNodes[0].defaultValue
        : info.selectedNodes[0].dataRef.defaultValue,
    });
  };

  const getParentTitle = (key, tree) => {
    let parentTitle;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentTitle = node.title;
          console.log(parentTitle);
        } else if (getParentTitle(key, node.children)) {
          parentTitle = getParentTitle(key, node.children);
        }
      }
    }
    return parentTitle;
  };

  const editNode = (key, data) =>
    data.map((item) => {
      if (item.key === key) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
      }
      //When a node is in the editing state, and the data is changed, when you click to edit other nodes, the node becomes uneditable, and the value needs to be returned to defaultvalue
      item.value = item.defaultValue;
      if (item.children) {
        editNode(key, item.children);
      }
    });

  const closeNode = (key, defaultValue, data) =>
    data.map((item) => {
      item.isEditable = false;
      if (item.key === key) {
        item.value = defaultValue;
      }
      if (item.children) {
        closeNode(key, defaultValue, item.children);
      }
    });

  const saveNode = (key, data) =>
    data.map((item) => {
      if (item.key === key) {
        item.defaultValue = item.value;
      }
      if (item.children) {
        saveNode(key, item.children);
      }
      item.isEditable = false;
    });

  const changeNode = (key, value, data) =>
    data.map((item) => {
      if (item.key === key) {
        item.value = value;
      }
      if (item.children) {
        changeNode(key, value, item.children);
      }
    });

  const renderTreeNodes = (data) =>
    data.map((item) => {
      item.title = <span>{item.value}</span>;
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });

  //drag and drop
  const DragEnter = (info) => {
    console.log(info);
    //expandedKeys need to be set when controlled
    //this.setState({
    //expandedKeys: info.expandedKeys,
    //});
  };

  const handleDrop = (info) => {
    console.log(info);
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...treeData];

    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      //Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        //where to insert example is added to the end, it can be any position
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && //Has children
      info.node.props.expanded && //Is expanded
      dropPosition === 1 //On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        //where to insert example is added to the head, it can be any position
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setTreeData(data);
  };

  return (
    <div>
      <div style={{ marginBottom: "5px" }}>
        <Button
          key="addnew"
          type="primary"
          htmlType="button"
          icon={<PlusCircleOutlined />}
          onClick={onAdd}
        >
          Add New Menu
        </Button>
        <Button
          key="delete"
          type="danger"
          style={{ marginLeft: "10px" }}
          icon={<MinusCircleOutlined />}
          onClick={onDelete}
        >
          Delete Menu
        </Button>
      </div>
      <Tree
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        draggable
        onDrop={handleDrop}
        onDragEnter={DragEnter}
        onSelect={onSelect}
      >
        {renderTreeNodes(treeData)}
      </Tree>
    </div>
  );
};

export default DragTree;
