import * as action_types from "../../../actions/Admin/actionTypes";

const initialLoginState = {
  isLoggedIn: false,
  name: "",
};

export default function AdminLoginReducer(state = initialLoginState, action) {
  switch (action.type) {
    case action_types.storeLoginToken:
      return LoginDetail(action.name, action.token, state);
    case action_types.removeLoginToken:
      return removeLoginDetail(state);
    case action_types.sessionExpired:
      return sessionExpired(state);
    case action_types.userSession:
      return userSession(state, action.payload);
    default:
      return state;
  }
}

function LoginDetail(name, token, state) {
  let isLoggedIn = false;
  const loggedInUser = localStorage.getItem("token");

  if (token || loggedInUser) {
    isLoggedIn = true;
    name = localStorage.getItem("name");
  }

  state = {
    isLoggedIn: isLoggedIn,
    name: name,
  };

  return state;
}

const removeLoginDetail = (state) => {
  let isLoggedIn = false;

  state = {
    isLoggedIn: isLoggedIn,
    name: "",
  };

  localStorage.clear();

  return state;
};

const sessionExpired = (state) => {
  return {
    isLoggedIn: false,
    name: "",
  };
};

const userSession = (state, payload) => {
  if (payload) {
    if (payload.data.status === 401) {
      localStorage.clear();
      return {
        isLoggedIn: false,
        name: "",
      };
    } else {
      return state;
    }
  }
};

function clearLocalStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("name");
}
