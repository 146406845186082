import React from "react";
import { Form, Input } from "antd";

export default function TextAreaField(props) {
  const { TextArea } = Input;
  return (
    <Form.Item
      className={
        props.mainClassName ? props.mainClassName : "merchant-form-item"
      }
      name={props.name}
      label={props.label}
      dependencies={props.dependencies}
      rules={props.rules}
      hasFeedback={props.hasFeedback}
      extra={props.extra}
      style={props.style}
    >
      <>
        <TextArea
          pattern={props.pattern}
          className={props.className ? props.className : "description"}
          size="large"
          placeholder={props.placeholder}
          disabled={props.disabled}
          style={props.inputStyle}
          onChange={props.onChange}
          autoSize={props.autoSize}
          value={props.value ?? ""}
          bordered={props.bordered}
        />
        {props.extra_desc}
      </>
    </Form.Item>
  );
}
