import { Avatar, Col, Form, message, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import InputField from "../../Global/FormInputComponent/InputField";
import InputNumberField from "../../Global/FormInputComponent/InputNumber";
import TextAreaField from "../../Global/FormInputComponent/TextAreaField";
import UploadField from "../../Global/FormInputComponent/UploadField";
import { TimePicker } from "antd";
import moment from "moment";
import {
  createService,
  updateService,
} from "../../../../actions/Merchant/actionCreators";
import { AxiosContext } from "../../../../actions/Merchant/Axios/axios";
import { useDispatch } from "react-redux";
const format = "HH:mm";

export default function ServiceForm(props) {
  const [descLength, setDescLength] = useState(0);
  const [descValue, setDescValdescValue] = useState("");
  const [data, setData] = useState({});
  const [price, setPrice] = useState([]);
  const [removeID, setRemoveID] = useState([]);
  const [form] = Form.useForm();
  const api = useContext(AxiosContext);
  const dispatch = useDispatch();
  const [importData, setImportData] = useState({
    dataList: [],
  });

  const validateMessages = {
    required: "Required Field!",
    whitespace: "Field cannot be empty!",
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values) => {
    const formData = new FormData();

    if (price.length == 0) {
      message.error("Please add at least one price");
    } else {
      formData.append("name", values["name"]);
      formData.append("desc", descValue);

      if (values["service_time"]) {
        formData.append(
          "duration",
          moment.duration(values["service_time"].format("HH:mm")).asMinutes()
        );
      }

      if (importData.dataList.length > 0) {
        importData.dataList.forEach((file) => {
          if (!file.url.includes("https://media.smartcubesoft.com/")) {
            formData.append("avatar", file);
          }
        });
      } else {
        if (Object.values(data).length > 0) {
          formData.append("remove_avatar", 1);
        }
      }

      price.map((prc, key) => {
        formData.append(`price_names[${key}]`, prc.name);
        formData.append(`price_min_prices[${key}]`, prc.min_price);
        formData.append(`price_max_prices[${key}]`, prc.max_price);

        if (prc.id) {
          formData.append(`price_ids[${key}]`, prc.id);
        }
      });

      if (Object.keys(data).length > 0) {
        formData.append("id", data.id);
        if (data.desc_id != "") {
          formData.append("desc_id", data.desc_id);
        }
        if (data.image_id != "") {
          formData.append("image_id", data.image_id);
        }
        if (removeID.length > 0 && removeID[0] != "") {
          removeID.map((remove_price_id, r_key) => {
            formData.append(`remove_price_ids[${r_key}]`, remove_price_id);
          });
        }

        formData.append("_method", "PUT");
        updateServiceSetting(formData);
      } else {
        postServiceSetting(formData);
      }
    }
  };

  const postServiceSetting = async (data) => {
    const response = await createService({
      api,
      data: data,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      props.getServices();
      setRemoveID([]);
      setData({});
      setPrice([]);
      setImportData({ dataList: [] });
      form.resetFields();
    }
  };

  const updateServiceSetting = async (data) => {
    const response = await updateService({
      api,
      data: data,
      id: props.data.id,
      dispatch,
    });

    if (response) {
      message.success(response.message);
      props.getServices();
      setRemoveID([]);
      setData({});
      setPrice([]);
      setImportData({ dataList: [] });
      form.resetFields();
    }
  };

  const onFinishFailed = () => {};
  const descOnChange = (event) => {
    if (event.target.value.length <= 120) {
      setDescLength(event.target.value.length);
      setDescValdescValue(event.target.value);
    }
  };
  function triggerInput(enteredName, enteredValue) {
    const input = document.getElementsByClassName(enteredName);
    const lastValue = input[0].value;
    input[0].value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = input[0]._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    input[0].dispatchEvent(event);
  }

  const addPrice = () => {
    console.log(price);
    var array = [];
    for (let index = 0; index < price.length; index++) {
      array.push({
        id: form.getFieldValue("price_ids")[index],
        name: form.getFieldValue("price_names")[index],
        min_price: form.getFieldValue("price_min_prices")[index],
        max_price: form.getFieldValue("price_max_prices")[index],
      });
    }

    var title_input = form.getFieldValue("name_price");
    var min_input = form.getFieldValue("price_min") ?? 0.0;
    var max_input = form.getFieldValue("price_max") ?? 0.0;

    if (title_input) {
      if (min_input) {
        array.push({
          name: title_input,
          min_price: min_input,
          max_price: max_input,
        });
        setPrice(array);
        form.setFieldsValue({
          name_price: "",
          price_min: "",
          price_max: "",
        });
      } else {
        message.error("Please fill in min price before add");
      }
    } else {
      message.error("Please fill in price name before add");
    }
  };

  const removePrice = (event) => {
    let index = event.target.getAttribute("data-ind");
    let remove_id = event.target.getAttribute("data-id");

    if (remove_id != " ") {
      var removeArray = [...removeID];
      removeArray.push(remove_id);
      setRemoveID(removeArray);
    }

    var array = [...price];
    if (index !== -1) {
      array.splice(index, 1);
      setPrice(array);
    }
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    let time = data.duration ?? 0;
    var hours = Math.floor(time / 60);
    var minutes = time % 60;

    form.setFieldsValue({
      name: data.name,
      service_time: moment(hours + ":" + minutes, format),
      desc: data.desc,
    });
    setDescValdescValue(data.desc ?? "");
    triggerInput("description", data.desc ?? "");
    setPrice(data.prices ?? []);

    if (data.avatar) {
      setImportData({ dataList: [{ url: data.avatar }] });
    } else {
      setImportData({ dataList: [] });
    }
  }, [data]);

  return (
    <Form
      {...layout}
      form={form}
      name="service_form"
      initialValues={data}
      validateMessages={validateMessages}
      scrollToFirstError
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row>
        <Col xs={12} md={8} style={{ height: "110px" }}>
          <Avatar
            size={105}
            style={{
              backgroundColor: "#ffffff",
            }}
            icon={<img src={data.avatar}></img>}
          />
          <UploadField
            name="avatar"
            setImportData={setImportData}
            importData={importData}
          />
        </Col>
      </Row>
      <Row justify="space-between" className="header-color">
        <Col span={9} style={{ padding: "6.5px 0" }}>
          <label>SERVICE NAME</label>
        </Col>
        <Col span={15}>
          <InputField
            mainClassName=" "
            style={{ marginBottom: "0px", textAlign: "end" }}
            placeholder="Enter Name"
            className="merchant-input-no-border"
            name="name"
            rules={[{ required: true, whitespace: true }]}
            hasFeedback
          />
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={15}>SERVICE DESCRIPTION</Col>
        <Col span={9} style={{ textAlign: "end" }}>
          {descLength} / 120
        </Col>
      </Row>
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={24}>
          <TextAreaField
            name="desc"
            inputStyle={{ padding: "10px 0", border: "none" }}
            placeholder="Enter Description"
            maxLength={120}
            onChange={descOnChange}
            autoSize={{
              minRows: 3,
              maxRows: 3,
            }}
            value={descValue}
          />
        </Col>
      </Row>
      <hr style={{ marginTop: "1rem", marginBottom: "2rem" }} />
      <Row justify="space-between" className="header-color">
        <Col span={8} style={{ padding: "6.5px 0" }}>
          <label>SERVICE TIME</label>
        </Col>
        <Col span={16}>
          <Form.Item
            style={{ marginBottom: "0px", textAlign: "end" }}
            name="service_time"
            rules={[{ required: true }]}
            hasFeedback
          >
            <TimePicker
              className="merchant-input-no-border"
              format={format}
              style={{ textAlign: "end" }}
              suffixIcon=""
              minuteStep={5}
            />
          </Form.Item>
        </Col>
      </Row>
      <hr style={{ marginTop: "1rem", marginBottom: "2rem" }} />
      <Row style={{ marginBottom: "1rem", padding: "6.5px 0" }}>
        <Col span={24}> PRICE</Col>
      </Row>
      {price
        ? price.map((prc, index) => {
            return (
              <div key={index}>
                <Row
                  justify="end"
                  className="header-color"
                  style={{ alignItems: "center" }}
                >
                  <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/icons/btn-minus.svg"
                        style={{ width: 27, height: 27 }}
                        onClick={removePrice}
                        data-ind={index}
                        data-id={prc.id ?? ""}
                      ></img>
                      <InputField
                        mainClassName=" "
                        style={{ marginBottom: "0px" }}
                        inputStyle={{ textAlign: "start" }}
                        className="merchant-input-no-border"
                        name={["price_names", [index]]}
                      />
                    </div>
                  </Col>
                  <Col span={11}>
                    <InputNumberField
                      name={["price_min_prices", [index]]}
                      style={{ marginBottom: "0px", textAlign: "end" }}
                      className="merchant-input-no-border"
                      formatter={(value) =>
                        `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
                      step="0.01"
                      stringMode
                    />
                  </Col>
                  <Col span={2}> - </Col>
                  <Col span={11}>
                    <InputNumberField
                      name={["price_max_prices", [index]]}
                      style={{ marginBottom: "0px", textAlign: "end" }}
                      className="merchant-input-no-border"
                      formatter={(value) =>
                        `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
                      step="0.01"
                      stringMode
                    />
                  </Col>
                  <InputField
                    mainClassName=" "
                    style={{ marginBottom: "0px" }}
                    inputStyle={{ textAlign: "start" }}
                    className="merchant-input-no-border"
                    name={["price_ids", [index]]}
                    hidden={true}
                  />
                  {form.setFieldsValue({
                    ["price_ids"]: { [index]: prc.id },
                    ["price_names"]: { [index]: prc.name },
                    ["price_min_prices"]: { [index]: prc.min_price },
                    ["price_max_prices"]: {
                      [index]: prc.max_price != "" ? prc.max_price : 0,
                    },
                  })}
                </Row>
                <hr style={{ margin: "1rem 0" }} />
              </div>
            );
          })
        : ""}
      <Row
        justify="space-between"
        className="header-color"
        style={{ alignItems: "center" }}
      >
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="/icons/btn-add.svg"
              style={{ width: 27, height: 27 }}
              onClick={addPrice}
            ></img>
            <InputField
              mainClassName=" "
              style={{ marginBottom: "0px" }}
              inputStyle={{ textAlign: "start" }}
              placeholder="Enter Name"
              className="merchant-input-no-border"
              name="name_price"
            />
          </div>
        </Col>
        <Col span={11}>
          <InputNumberField
            name="price_min"
            style={{ marginBottom: "0px" }}
            className="merchant-input-no-border text-start"
            formatter={(value) =>
              `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
            step="0.01"
            stringMode
            inputStyle={{ textAlign: "start" }}
          />
        </Col>
        <Col span={2}> - </Col>
        <Col span={11}>
          <InputNumberField
            name="price_max"
            style={{ marginBottom: "0px" }}
            className="merchant-input-no-border text-start"
            formatter={(value) =>
              `MYR ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\MYR\s?|(,*)/g, "")}
            step="0.01"
            stringMode
          />
        </Col>
      </Row>
      <hr style={{ margin: "1rem 0" }} />
      <Row style={{ margin: "2rem 0" }}>
        <Col span={24}>
          <button htmltype="button" className="btn-secondary w-100">
            {Object.keys(data).length > 0 ? "Update" : "Save"}
          </button>
        </Col>
      </Row>
    </Form>
  );
}
